import { makeAutoObservable } from 'mobx';

// only methods with @action can affect state
class RootStore {
  constructor() {
    makeAutoObservable(this);
  }

  sidebarStatus = JSON.parse(localStorage.getItem('sidebarStatus')) || {
    collapsed: false,
  };

  setSidebar(status) {
    localStorage.setItem('sidebarStatus', JSON.stringify(status));
    this.sidebarStatus = { ...this.sidebarStatus, ...status };
  }
}
export default RootStore;
