import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent, ModalFooter, Button } from '@spoiler-alert/ui-library';
import ExportProfileForm from './export-profile-form';

const EditExportProfileModal = ({ setShowEditModal, showEditModal, exportProfile, updateExportProfile, possibleSheetWriters }) => {
  const [editingExportProfile, setEditingExportProfile] = useState(() => ({ ...exportProfile }));

  useEffect(() => {
    setEditingExportProfile({
      ...exportProfile,
    });
  }, [exportProfile]);

  const hideModal = () => setShowEditModal(false);

  const saveExportProfile = async () => {
    editingExportProfile.multilineHeaders = editingExportProfile.multilineHeaders.map((mlh) => {
      const headerStyles = { ...mlh.headerStyles, __typename: undefined };
      const columnStyles = { ...mlh.columnStyles, __typename: undefined };
      return { ...mlh, headerStyles, columnStyles, __typename: undefined };
    });
    setEditingExportProfile({ ...editingExportProfile });
    await updateExportProfile({
      variables: {
        exportProfile: {
          ...editingExportProfile,
          __typename: undefined,
        },
      },
    });
    hideModal();
  };

  return (
    <Modal onHide={hideModal} open={showEditModal} closeOnEsc closeOnOutsideClick>
      <ModalContent>
        <h2>Settings</h2>
        <ExportProfileForm
          exportProfile={editingExportProfile}
          possibleSheetWriters={possibleSheetWriters}
          setEditingExportProfile={setEditingExportProfile}
        />
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={hideModal} link>
          Cancel
        </Button>
        <Button type="submit" disabled={false} primary loading={false} loadingText="Saving..." onClick={saveExportProfile}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EditExportProfileModal.propTypes = {
  setShowEditModal: PropTypes.func,
  showEditModal: PropTypes.bool,
  exportProfile: PropTypes.object,
  updateExportProfile: PropTypes.func,
  possibleSheetWriters: PropTypes.array,
};

export default EditExportProfileModal;
