import React from 'react';
import injectSheet from 'react-jss';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Theme as theme, ColorBadge } from '@spoiler-alert/ui-library';
const styles = {
    breadcrumbWrapper: {
        padding: 16,
        fontSize: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:hover': {
            overflow: 'visible',
            marginRight: '-16%',
            zIndex: 1,
            background: 'white',
        },
    },
    breadcrumbSpan: {
        '&:after': {
            content: '" / "',
        },
        '&:last-child': {
            '&>a': {
                color: theme.textColorPrimary,
            },
            '&:after': {
                content: '""',
            },
        },
    },
};
function Breadcrumbs({ classes, breadcrumbsStore }) {
    return (React.createElement("div", { className: classes.breadcrumbWrapper }, breadcrumbsStore.breadcrumbs.map((bc) => (React.createElement("span", { key: bc.url, className: classes.breadcrumbSpan },
        React.createElement(Link, { to: bc.url }, bc.title),
        bc.badge && React.createElement(ColorBadge, { theme: Object.assign(Object.assign({}, bc.badge.colors), { marginLeft: 12 }) }, bc.badge.text))))));
}
const ConnectedComponent = inject((store) => store)(observer(Breadcrumbs));
const StyledComponent = injectSheet(styles)(ConnectedComponent);
export default StyledComponent;
