import AppSettings from '../app-settings';

class LoginService {
  constructor() {
    this.apiUrl = `${AppSettings.GRAPHQL_SERVER_URL}auth`;
    this.contentType = 'application/json';
  }

  makeCall(url, method, body, headers) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        body,
        method,
        headers,
      }).then((res) => {
        if (!res.ok) res.text().then((err) => reject(err));
        else
          res.json().then((data) => {
            localStorage.setItem('token', data);
            resolve(data);
          });
      });
    });
  }

  get headers() {
    return {
      'Content-Type': this.contentType,
    };
  }

  login(username, password) {
    const body = JSON.stringify({ username, password });
    return this.makeCall(`${this.apiUrl}/login`, 'POST', body, this.headers);
  }
}

const loginService = new LoginService();
export default loginService;
