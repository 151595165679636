import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Radio, TextBox, TrashIcon, Select, SelectOption, SelectOptionGroup, Button, Theme, Tooltip } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { cleanModel, SAVE_DELAY } from '../etl-helpers';
import IMPORT_TYPES from './enums/etl-import-types';
import IMPORT_TYPE_OPTIONS from './enums/etl-import-type-options';
import ETLImportErrors from './etl-import-errors';
import FieldTypeIndicator from './field-type-indicator';

const styles = {
  textBoxWrap: {
    '&>div': {
      height: 32,
    },
  },
  fieldSelect: {},
  selectColumn: {
    '&>span': {
      display: 'flex',
      alignItems: 'center',
      '&>$fieldSelect': {
        flexGrow: 1,
      },
      '&>div>div': {
        maxWidth: '100%',
        '&>div>span': {
          paddingLeft: '0 !important',
        },
      },
    },
  },
  trashColumn: {
    width: 75,
  },
  trashButton: {
    margin: [-7, 0, -10, 0],
  },
  justSaved: {
    boxShadow: `inset 4px 0 0 ${Theme.green30}`,
  },
  rowError: {
    background: Theme.pink10,
  },
  flex: {
    display: 'flex',
  },
};

const defaultTypeOptions = [
  {
    text: 'Static',
    value: 'STATIC',
  },
  {
    text: 'Lookup',
    value: 'ITEM_LOOKUP',
  },
];

const bypassTTText = 'Lookup on items is not allowed because no item file has been configured.';

const ETLDefault = ({
  classes,
  etlDefault,
  deleteDefault,
  updateDefault,
  externalNames,
  possibleInternalFields,
  itemFields,
  justSaved,
  importType,
  setSaveInProgress,
  bypassItemValidation,
}) => {
  const [editingDefault, setEditingDefault] = useState(() => cleanModel(etlDefault));
  const [defaultType, setDefaultType] = useState(editingDefault.defaultValues[0]?.defaultType || IMPORT_TYPE_OPTIONS.STATIC);
  const [lookup, setLookup] = useState(defaultType === IMPORT_TYPE_OPTIONS.ITEM_LOOKUP ? editingDefault.defaultValues[0].defaultValue : null);
  const [staticValue, setStaticValue] = useState(
    editingDefault.defaultValues?.find((dv) => dv.defaultType === IMPORT_TYPE_OPTIONS.STATIC)?.defaultValue || ''
  );
  const timerRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    setEditingDefault(cleanModel(etlDefault));
  }, [etlDefault]);

  const restartTimer = (recordToSave) => {
    clearTimeout(timerRef.current);
    setSaveInProgress(true);
    timerRef.current = setTimeout(() => {
      updateDefault(recordToSave);
    }, SAVE_DELAY);
  };

  const setTargetFieldValue = ([{ value }]) => {
    const [name, nameOrigin] = value.split('+');
    const newDefault = {
      ...editingDefault,
      externalName: nameOrigin === 'externalName' ? name : null,
      saName: nameOrigin === 'saName' ? name : null,
    };

    setEditingDefault(newDefault);
    restartTimer(newDefault);
  };

  const updateDefaultTypeAndValues = (newValues) => {
    const newDefault = { ...editingDefault };
    const lookupObject = {
      defaultType: 'ITEM_LOOKUP',
      defaultValue: newValues.lookup,
    };
    const staticObject = {
      defaultType: 'STATIC',
      defaultValue: newValues.staticValue,
    };
    const isItemLookup = newValues.defaultType === IMPORT_TYPE_OPTIONS.ITEM_LOOKUP;
    const hasStaticDefault = newValues.staticValue;

    newDefault.defaultValues = (() => {
      if (isItemLookup && hasStaticDefault) return [lookupObject, staticObject];
      if (isItemLookup) return [lookupObject];
      return [staticObject];
    })();

    return newDefault;
  };

  const fieldOptions = [
    <SelectOptionGroup key="internal" name="Internal Names" search>
      {possibleInternalFields.map((inf) => (
        <SelectOption key={`${inf.name}+saName`} value={`${inf.name}+saName`}>
          {inf.name}
        </SelectOption>
      ))}
    </SelectOptionGroup>,
    <SelectOptionGroup key="external" name="External Names" search>
      {externalNames.map((en) => (
        <SelectOption key={`${en}+externalName`} value={`${en}+externalName`}>
          {en}
        </SelectOption>
      ))}
    </SelectOptionGroup>,
  ];
  const selectedField = editingDefault.saName
    ? { value: `${editingDefault.saName}+saName`, text: editingDefault.saName, type: 'saName' }
    : { value: `${editingDefault.externalName}+externalName`, text: editingDefault.externalName, type: 'externalName' };
  const lookupOptions = itemFields.map((item) => (
    <SelectOption key={item.name} value={item.name}>
      {item.name}
    </SelectOption>
  ));
  const setDefaultsAndSave = (field, value) => {
    const existingDefaults = { defaultType, staticValue, lookup };
    const setters = {
      defaultType: setDefaultType,
      staticValue: setStaticValue,
      lookup: setLookup,
    };
    existingDefaults[field] = value;
    setters[field](value);
    const newDefault = updateDefaultTypeAndValues(existingDefaults);
    setEditingDefault(newDefault);
    restartTimer(newDefault);
  };

  const radioSelect = () => {
    if (bypassItemValidation) {
      return (
        <div className={`${classes.flex}`}>
          <Radio
            options={[defaultTypeOptions[0]]}
            selectedItem={{ text: defaultType, value: defaultType }}
            disabled={importType === IMPORT_TYPES.ITEM.value}
            onChange={(item) => setDefaultsAndSave('defaultType', item.value)}
          />
          <Tooltip text={bypassTTText}>
            <Radio options={[defaultTypeOptions[1]]} selectedItem={{ text: defaultType, value: defaultType }} disabled={true} />
          </Tooltip>
        </div>
      );
    }
    return (
      <Radio
        options={defaultTypeOptions}
        selectedItem={{ text: defaultType, value: defaultType }}
        disabled={importType === IMPORT_TYPES.ITEM.value}
        onChange={(item) => setDefaultsAndSave('defaultType', item.value)}
      />
    );
  };

  return (
    <Row className={`${justSaved ? classes.justSaved : ''} ${etlDefault.importErrors.length > 0 ? classes.rowError : ''}`}>
      <Col className={classes.selectColumn}>
        <ETLImportErrors errors={etlDefault.importErrors} />
        {selectedField.type === 'saName' ? (
          <FieldTypeIndicator text="IN" background={Theme.green10} color={Theme.greenDark} />
        ) : (
          <FieldTypeIndicator text="EX" background={Theme.orange10} color={Theme.orangeDark} />
        )}
        <Select
          containerClassName={classes.fieldSelect}
          minimal
          search
          selectedItems={[selectedField]}
          onChange={(values) => setTargetFieldValue(values)}
        >
          {fieldOptions}
        </Select>
      </Col>
      <Col>{radioSelect()}</Col>
      <Col className={classes.selectColumn}>
        <Select
          minimal
          search
          disabled={defaultType === IMPORT_TYPE_OPTIONS.STATIC || bypassItemValidation}
          selectedItems={[{ text: lookup, value: lookup }]}
          onChange={(values) => setDefaultsAndSave('lookup', values[0].value)}
        >
          {lookupOptions}
        </Select>
      </Col>
      <Col>
        <div className={classes.textBoxWrap}>
          <TextBox
            style={{ width: 50, textAlign: 'left', paddingLeft: 8 }}
            type="text"
            value={staticValue}
            onChange={(value) => setDefaultsAndSave('staticValue', value)}
          />
        </div>
      </Col>
      <Col className={classes.trashColumn}>
        <Button
          warning
          resting={true}
          icon={TrashIcon}
          className={classes.trashButton}
          onClick={(e) => {
            e.preventDefault();
            deleteDefault(editingDefault);
          }}
        />
      </Col>
    </Row>
  );
};

ETLDefault.propTypes = {
  classes: PropTypes.object,
  etlDefault: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    saName: PropTypes.string,
    externalName: PropTypes.string,
    defaultValues: PropTypes.arrayOf(
      PropTypes.shape({
        defaultType: PropTypes.string,
        defaultValue: PropTypes.string,
      })
    ),
    importErrors: PropTypes.arrayOf(PropTypes.string),
  }),
  possibleInternalFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  itemFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  externalNames: PropTypes.arrayOf(PropTypes.string),
  updateDefault: PropTypes.func,
  deleteDefault: PropTypes.func,
  justSaved: PropTypes.bool,
  importType: PropTypes.string,
  setSaveInProgress: PropTypes.func,
  bypassItemValidation: PropTypes.bool,
};

export default injectSheet(styles)(ETLDefault);
