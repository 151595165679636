import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class ActivityImportService {
  import(file) {
    const importUrl = `${AppSettings.GRAPHQL_SERVER_URL}import/activity`;
    const body = new FormData();
    body.append('activityFile', file);
    return fetchWithToken(importUrl, {
      body,
      method: 'POST',
    });
  }
}

const activityImportService = new ActivityImportService();
export default activityImportService;
