import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { TextInput, AlertService, Modal, ModalContent, ModalFooter, EmailCard, Button, Theme } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { SaveInventoriesMutation } from './inventory-manager-mutations';

const useStyles = createUseStyles({
  modalContent: {
    width: 652,
  },
  inventoryFields: {
    maxHeight: 400,
    overflowY: 'auto',
    border: `1px solid ${Theme.grey30}`,
    padding: '10px 10px 0',
  },
  box: {
    marginBottom: 10,
  },
  fieldBox: {
    extend: 'box',
  },
  fieldLabel: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.3)',
    userSelect: 'none',
    display: 'block',
  },
  info: {
    listStyle: 'outside',
    paddingLeft: '2em',
    color: 'rgba(0, 0, 0, 0.3)',
    margin: '0 0 20px',
    '& li': {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.3)',
    },
  },
  tagForm: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  emojiTable: {
    width: '100%',
    marginBottom: 10,
    borderCollapse: 'collapse',
    border: '1px #000 solid',
    borderRight: 'none',
    textAlign: 'left',
    '& td, & th': {
      borderRight: '1px #000 solid',
      padding: 5,
    },
  },
  tagBox: {
    display: 'flex',
    flexFlow: 'row wrap',
    minHeight: 60,
    background: Theme.white,
    boxShadow: `inset 0 0 5px ${Theme.grey10}`,
    padding: 10,
    paddingTop: 2,
    marginBottom: 10,
    overflowY: 'auto',
    '&>div': {
      overflow: 'hidden',
    },
    '&>div>div': {
      padding: [6, 8],
      height: 'auto',
      '&>span': {
        fontSize: '.8rem',
      },
      '&>div': {
        display: 'none',
      },
    },
  },
  extraTagList: {
    extend: 'tagBox',
  },
  tagField: {
    flex: '1 0 200px',
    marginRight: 10,
  },
});

const InventoryEditor = ({ inventory, setInventory, handleHideModal, tagList }) => {
  const classes = useStyles();
  const [currentTag, setCurrentTag] = useState('');
  const [saveInventory, { loading: saving }] = useMutation(SaveInventoriesMutation);

  const handleSave = () => {
    const variables = {
      inventories: [
        {
          _id: inventory._id,
          uploadedReservePrice: inventory.uploadedReservePrice,
          tags: inventory.tags,
          unitCost: inventory.unitCost,
          unitPrice: inventory.unitPrice,
          unitGrossWeight: inventory.unitGrossWeight,
          unitNetWeight: inventory.unitNetWeight,
        },
      ],
    };
    saveInventory({ variables })
      .then((response) => {
        if (response.data.saveInventories.errors?.length > 0) {
          throw new Error(response.data.saveInventories.errors[0].message);
        }
        setInventory(null);
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
    const { tags } = variables.inventories[0];
    if (tags?.length > 0) {
      const userTags = localStorage.getItem('userTags:v1')?.split(',') || [];
      const newUserTags = [...new Set([...userTags, ...tags])];
      localStorage.setItem('userTags:v1', newUserTags.join(','));
    }
  };

  const updateValue = (field, value) => {
    const _inventory = { ...inventory };
    _inventory[field] = value;
    setInventory(_inventory);
  };

  const addTag = (tag) => {
    const existingTags = inventory.tags || [];
    const tags = [...new Set([...existingTags, tag])];
    updateValue('tags', tags);
  };

  const submitTag = (ev) => {
    ev.preventDefault();
    addTag(currentTag);
    setCurrentTag('');
  };

  const editExistingTag = (tag) => {
    const tags = [...inventory.tags].filter((t) => t !== tag);
    updateValue('tags', tags);
    setCurrentTag(tag);
  };

  const matches = useMemo(() => {
    if (currentTag === '') return null;
    const parts = currentTag.split('/');
    if (parts.length === 1) return { emoji: '{none}', flyout: parts[0], tooltip: '{none}' };
    return {
      emoji: parts[0] && parts[0].trim() !== '' && parts[2] ? parts[0] : '{none}',
      flyout: parts[0] && parts[1] ? `${parts[0]} ${parts[1]}` : '{none}',
      tooltip: parts[2] || '{none}',
    };
  }, [currentTag]);

  return (
    <Modal onHide={handleHideModal} open={!!inventory} closeOnEsc closeOnOutsideClick>
      <ModalContent>
        <div className={classes.modalContent}>
          <h2>Edit Inventory</h2>
          {inventory?._id && (
            <div className={classes.inventoryFields}>
              <ul className={classes.info}>
                <li>ItemName: {inventory.itemName}</li>
                <li>Site Name: {inventory.siteName}</li>
                <li>Best By Date: {moment(inventory.bestByDate).format('MMM D, YYYY')}</li>
                <li>Cases uploaded: {inventory.originalQuantity}</li>
                <li>Cases available: {inventory.availableQuantity}</li>
              </ul>
              {!inventory.status.accepted && (
                <div className={classes.fieldBox}>
                  <TextInput
                    className={classes.textField}
                    onChange={updateValue.bind(this, 'unitCost')}
                    value={inventory.unitCost || ''}
                    min="0"
                    step="0.01"
                    type="number"
                    labelText="Unit Cost"
                    required
                  />
                </div>
              )}
              {!inventory.status.received && (
                <div className={classes.fieldBox}>
                  <TextInput
                    className={classes.textField}
                    onChange={updateValue.bind(this, 'unitPrice')}
                    value={inventory.unitPrice || ''}
                    type="number"
                    min="0"
                    step="0.01"
                    labelText="Unit Price"
                    required
                  />
                </div>
              )}
              <div className={classes.fieldBox}>
                <TextInput
                  className={classes.textField}
                  onChange={updateValue.bind(this, 'unitGrossWeight')}
                  value={inventory.unitGrossWeight || ''}
                  type="number"
                  min="0"
                  step="0.01"
                  labelText="Unit Gross Weight"
                  required
                />
              </div>
              <div className={classes.fieldBox}>
                <TextInput
                  className={classes.textField}
                  onChange={updateValue.bind(this, 'unitNetWeight')}
                  value={inventory.unitNetWeight || ''}
                  type="number"
                  min="0"
                  step="0.01"
                  labelText="Unit Net Weight"
                  required
                />
              </div>
              {!inventory.status.received && (
                <div className={classes.fieldBox}>
                  <TextInput
                    className={classes.textField}
                    onChange={updateValue.bind(this, 'uploadedReservePrice')}
                    value={inventory.uploadedReservePrice || ''}
                    type="number"
                    min="0"
                    step="0.01"
                    labelText="Uploaded Reserve Price"
                    required
                  />
                </div>
              )}
              <div className={classes.fieldBox}>
                <div className={classes.fieldLabel}>Tags for this inventory</div>
                <div className={classes.tagBox}>
                  {inventory.tags?.map((tag) => (
                    <EmailCard
                      key={tag}
                      email={tag}
                      backgroundColor={Theme.teal5}
                      borderColor={Theme.tealDark}
                      handleEdit={(t) => editExistingTag(t)}
                    />
                  ))}
                </div>
                <form onSubmit={submitTag} className={classes.tagForm}>
                  <TextInput
                    placeholder="🔥/Flyout Text!/Tooltip Text"
                    className={classes.tagField}
                    onChange={(value) => setCurrentTag(value)}
                    value={currentTag}
                    type="text"
                  />
                  <Button submit>Add Tag</Button>
                </form>
                {matches && (
                  <table className={classes.emojiTable}>
                    <thead>
                      <tr>
                        <th>Thumbnail Emoji</th>
                        <th>Tooltip</th>
                        <th>Flyout</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{matches.emoji}</td>
                        <td>{matches.tooltip}</td>
                        <td>{matches.flyout}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <div className={classes.fieldLabel}>Previously used tags, click to add...</div>
                <div className={classes.extraTagList}>
                  {tagList?.map((tag) => (
                    <EmailCard key={tag} email={tag} backgroundColor={Theme.green30} borderColor={Theme.greenDark} handleEdit={(t) => addTag(t)} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={handleHideModal} link>
          Cancel
        </Button>
        <Button type="submit" disabled={saving} primary loading={saving} loadingText="Saving..." onClick={handleSave}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

InventoryEditor.propTypes = {
  inventory: PropTypes.object,
  setInventory: PropTypes.func,
  handleHideModal: PropTypes.func,
  tagList: PropTypes.arrayOf(PropTypes.string),
};

export default InventoryEditor;
