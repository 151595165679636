import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Card, Button } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { BulkDeleteAdminUser } from '../../../graphql/mutations';

const styles = {
  button__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
};

@injectSheet(styles)
@graphql(BulkDeleteAdminUser)
export default class DeleteUserAction extends Component {
  state = {
    flyoutUser: this.props.flyoutUser,
    deleting: false,
  };

  static getDerivedStateFromProps(nextProps) {
    return { user: nextProps.user };
  }

  error = (response) => {
    const message = 'Sorry, deleting this user failed. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    this.setState({ deleting: false });
    this.props.onHide(response, message);
  };

  hide = () => {
    this.setState({ deleting: false });
    this.props.onClose();
  };

  delete = () => {
    const { refetchQueries, mutate } = this.props;
    this.setState({ deleting: true });
    mutate({
      variables: { userIds: [this.state.flyoutUser._id] },
      refetchQueries,
    })
      .then(this.hide)
      .catch(this.error);
  };

  render() {
    return (
      <Card>
        <h3>Deactivate User</h3>
        <p>Are you sure you want to deactivate this user? This action cannot be undone.</p>
        <div className={this.props.classes.button__container}>
          <Button type="button" link onClick={this.props.onHide} disabled={this.state.deleting}>
            Cancel
          </Button>
          <Button type="button" primary onClick={this.delete} loading={this.state.deleting} loadingText="Wait...">
            Deactivate
          </Button>
        </div>
      </Card>
    );
  }
}

DeleteUserAction.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  flyoutUser: PropTypes.object,
  onHide: PropTypes.func,
  onClose: PropTypes.func,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};

DeleteUserAction.defaultProps = {
  onHide: () => {},
};
