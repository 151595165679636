import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Button, Select, SelectOption, Theme as theme } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import { LOOKER_USER_INFO, UPDATE_LOOKER_USER_ATTRIBUTE_MUTATION } from './looker-site-queries';

const useStyles = createUseStyles({
  exportRunContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  dataExportsContainer: {
    width: '80%',
  },
  dataExportImportField: {
    width: '20%',
  },
  siteSelectWrap: {
    marginTop: '10px',
    borderRadius: '4px',
    border: `1px ${theme.grey30} solid`,
    marginRight: 8,
    width: 260,
    paddingRight: 8,
    boxSizing: 'content-box',
  },
  siteSelectOption: {
    paddingRight: '10px !important',
  },
  siteListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 25,
  },
  lookerSiteHeader: {
    marginTop: '10px',
  },
  lookerSaveButton: {
    marginTop: '10px',
  },
});

const LookerSiteManager = ({ user }) => {
  const classes = useStyles();

  useEffect(() => {
    TitleService.setTitles('LookerSiteManager');
    Breadcrumbs.set([
      {
        url: '/looker-site-manager',
        title: 'Looker Site Manager',
      },
    ]);
  });

  const { data, loading, error } = useQuery(LOOKER_USER_INFO, {
    variables: {
      email: user?.email,
    },
    refetchQueries: [
      {
        query: useQuery(LOOKER_USER_INFO, {
          variables: {
            email: user?.email,
          },
        }),
      },
    ],
  });

  const [updateLookerUserAttribute, { data: updatedData, loading: loadingData, error: dataError }] =
    useMutation(UPDATE_LOOKER_USER_ATTRIBUTE_MUTATION);

  const [siteSelection, setSiteSelection] = useState(null);
  let userId = '';
  let userAttributeId = '';
  let userEmail = '-';

  const sites = data?.lookerSiteQuery?.allSellerOrgs;

  if (data && data.lookerSiteQuery) {
    userId = data.lookerSiteQuery.lookerUser.userId;
    userAttributeId = data.lookerSiteQuery.lookerUser.userAttributeId;
    userEmail = data.lookerSiteQuery.lookerUser.lookerUserEmail;
  }

  let lookerSite = sites?.filter((s) => s._id === data?.lookerSiteQuery?.lookerUser?.siteId);

  if (updatedData?.updateLookerUserSiteIdAttribute?.value) {
    lookerSite = sites?.filter((s) => s._id === updatedData?.updateLookerUserSiteIdAttribute?.value);
  }

  //
  const updateLookerUser = () => {
    updateLookerUserAttribute({
      variables: { userId, userAttributeId, value: siteSelection?.[0].value },
    });
  };

  if (lookerSite && lookerSite[0] && !siteSelection) {
    const itemobj = [{ value: `${lookerSite?.[0]?._id}`, text: `${lookerSite?.[0]?.siteName}` }];
    setSiteSelection(itemobj);
  }

  const changeSite = (options) => {
    setSiteSelection(options);
  };

  const buttonDisabled = siteSelection?.[0]?.value === lookerSite?.[0]?._id;
  return (
    <div>
      <label>Looker User Email:</label>
      <p className={classes.lookerSiteHeader}>
        {userEmail || `There is no Looker account associated with your email. Please contact an administrator`}
      </p>
      <label>Change Looker Site To:</label>
      <div className={classes.siteSelectWrap}>
        <Select minimal search placeholderText="Select a Site" onChange={(options) => changeSite(options)} selectedItems={siteSelection}>
          {sites &&
            sites.map((s) => (
              <SelectOption key={s._id} value={s._id} searchText={s.siteName} className={classes.siteSelectOption}>
                <div className={classes.siteListItem}>{s.siteName}</div>
              </SelectOption>
            ))}
        </Select>
      </div>

      <Button
        className={classes.lookerSaveButton}
        onClick={() => updateLookerUser()}
        key="looker-site"
        loading={loadingData}
        disabled={buttonDisabled}
        loadingText="Saving..."
      >
        Save
      </Button>
    </div>
  );
};

LookerSiteManager.propTypes = {
  user: PropTypes.object,
};

export default LookerSiteManager;
