import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class ProxyService {
  downloadFile(path) {
    const baseUrl = `${AppSettings.GRAPHQL_SERVER_URL}proxy/`;
    return fetchWithToken(`${baseUrl}${path}`, {
      method: 'GET',
      headers: this.headers,
    });
  }
}

const proxyService = new ProxyService();
export default proxyService;
