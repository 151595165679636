import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class ZendeskService {
  zendeskSsoUrl = (params) =>
    fetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}zendesk`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    });
}

const zendeskService = new ZendeskService();
export default zendeskService;
