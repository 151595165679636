import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class FileUploadService {
  importLogo(file, path) {
    const importUrl = `${AppSettings.GRAPHQL_SERVER_URL}logo`;
    const body = new FormData();
    body.append('file', file);
    body.append('path', path);
    return fetchWithToken(importUrl, {
      body,
      method: 'POST',
    });
  }

  deleteLogo(fileUrl) {
    const importUrl = `${AppSettings.GRAPHQL_SERVER_URL}logo`;
    return fetchWithToken(importUrl, {
      body: JSON.stringify({ fileUrl }),
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

const fileUploadService = new FileUploadService();
export default fileUploadService;
