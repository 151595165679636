import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent, ModalFooter, Button } from '@spoiler-alert/ui-library';
import ETLProfileForm from './etl-profile-form';

const ETLEditProfileModal = ({ setShowEditModal, showEditModal, importProfile, updateImportProfile, validateImportProfile }) => {
  const [editingImportProfile, setEditingImportProfile] = useState(() => ({ ...importProfile }));

  useEffect(() => {
    setEditingImportProfile({
      ...importProfile,
      fileType: importProfile?.delimiter ? 'CSV' : 'EXCEL',
      headerNames: importProfile?.headerNames.join(importProfile.delimiter || '\t'),
    });
  }, [importProfile?._id]);

  const hideModal = () => setShowEditModal(false);

  const saveImportProfile = async () => {
    await updateImportProfile({
      variables: {
        importProfile: {
          ...editingImportProfile,
          headerNames: editingImportProfile.headerNames.toLowerCase().split(editingImportProfile.delimiter || '\t'),
          validated: undefined,
          fileType: undefined,
          __typename: undefined,
        },
      },
    });
    await validateImportProfile({ variables: { importProfileId: editingImportProfile._id } });
    hideModal();
  };

  return (
    <Modal onHide={hideModal} open={showEditModal} closeOnEsc closeOnOutsideClick>
      <ModalContent>
        <h2>Settings</h2>
        <ETLProfileForm importProfile={editingImportProfile} onChange={setEditingImportProfile} />
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={hideModal} link>
          Cancel
        </Button>
        <Button type="submit" disabled={false} primary loading={false} loadingText="Saving..." onClick={saveImportProfile}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ETLEditProfileModal.propTypes = {
  classes: PropTypes.object,
  setShowEditModal: PropTypes.func,
  showEditModal: PropTypes.bool,
  importProfile: PropTypes.object,
  updateImportProfile: PropTypes.func,
  validateImportProfile: PropTypes.func,
};

export default ETLEditProfileModal;
