import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Column, DataTableNaked, AlertService, Button, compose } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { CustomFiltersQuery } from '../../graphql/queries';
import { UpdateCustomFilter } from '../../graphql/mutations';
import CustomFilterModal from './custom-filter-modal';
import { Breadcrumbs } from '../../store';

const styles = {
  confirm_buttons: {
    display: 'flex',
    marginTop: 10,
  },
  table__grid: {
    border: '1px #D1D6DA solid',
    display: 'flex',
    margin: '25px 0 0',
  },
  modal__wrapper: {
    marginTop: '800px',
  },
};

@compose(graphql(CustomFiltersQuery), graphql(UpdateCustomFilter, { name: 'updateCustomFilter' }))
@injectSheet(styles)
export default class CustomFilters extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitles('Custom Filters Administration');
    Breadcrumbs.set([
      {
        url: '/custom-filters/',
        title: 'Custom Filters Administration',
      },
    ]);

    this.state = {
      showModal: false,
      filterName: '',
      filterId: '',
      siteSearch: '',
      query: '',
      filterSiteName: '',
      filterSiteId: '',
      showImportProfile: false,
      importProfileId: '',
      importProfiles: null,
      importProfileName: '',
    };
  }

  static propTypes = {
    user: PropTypes.object,
    classes: PropTypes.object,
    data: PropTypes.object,
    match: PropTypes.object,
    updateCustomFilter: PropTypes.func,
  };

  columns = [
    new Column({ field: 'name', displayName: 'Custom Filter Name', sortable: false, defaultSort: true }),
    new Column({ field: 'siteName', displayName: 'Site', sortable: false, defaultSort: false }),
  ];

  hideModal = () => {
    this.setState({
      showModal: false,
      filterName: '',
      filterId: '',
      filterSiteId: '',
      filterSiteName: '',
      query: '',
      siteSearch: '',
      showImportProfile: false,
      importProfileId: '',
      importProfiles: null,
      importProfileName: '',
    });
  };

  showModal = () => {
    this.setState({ showModal: true, importProfileName: '', importProfileId: '' });
  };

  handleRowClick = (row) => {
    this.setState({
      importProfileId: row.importProfileId,
      showModal: true,
      filterName: row.name,
      filterId: row._id,
      filterSiteId: row.siteId,
      filterSiteName: row.siteName,
      query: row.query,
      importProfiles: null,
    });
  };

  removeSite = () => {
    this.setState({
      filterSiteName: '',
      filterSiteId: '',
      importProfileId: '',
      showImportProfile: false,
      importProfiles: null,
      importProfileName: '',
    });
  };

  addSite = (selectedSite) => {
    this.setState({ filterSiteId: selectedSite.result._id, filterSiteName: selectedSite.result.siteName, showImportProfile: true });
  };

  addImportProfile = (selectedProfile) => {
    this.setState({ importProfileId: selectedProfile[0].value, importProfileName: selectedProfile[0].text });
  };

  setImportProfiles = (importProfiles) => {
    this.setState({ importProfiles });
  };

  saveCustomFilter = (ev) => {
    ev.preventDefault();
    const { query, filterName, filterId, filterSiteId, importProfileId } = this.state;
    let queryValid = true;
    try {
      JSON.parse(query);
    } catch (e) {
      AlertService.alert({ type: 'warning', message: 'JSON is invalid, please fix your JSON' });
      queryValid = false;
    }
    if (!queryValid) return;

    this.props
      .updateCustomFilter({
        variables: { filterName, filterId, filterSiteId, filterQuery: `${query}`, importProfileId },
        refetchQueries: [
          {
            query: CustomFiltersQuery,
          },
        ],
      })
      .then(() => {
        AlertService.alert({
          type: 'success',
          message: 'Custom filter successfully saved!',
          autoDismiss: true,
          dismissDelay: 5000,
        });
        this.setState({
          showModal: false,
          filterSiteName: '',
          importProfiles: null,
          importProfileId: '',
          importProfileName: '',
          filterSiteId: '',
          filterId: '',
        });
      })
      .catch(() =>
        AlertService.alert({
          type: 'warning',
          message: 'There was a problem saving the custom filter',
        })
      );
  };

  setFilterName = (filterName) => {
    this.setState({ filterName: filterName.trim() });
  };

  setQuery = (query) => {
    this.setState({ query });
  };

  render() {
    const { data, classes } = this.props;
    const {
      filterName,
      filterSiteId,
      filterSiteName,
      filterId,
      showModal,
      siteSearch,
      query,
      showImportProfile,
      importProfileId,
      importProfiles,
      importProfileName,
    } = this.state;
    const customFilters = data.adminUserQuery ? data.adminUserQuery.customFilters : [];
    return (
      <div>
        <Button type="button" primary onClick={this.showModal}>
          Add New Custom Filter
        </Button>
        <div className={classes.table__grid}>
          <DataTableNaked data={customFilters} columns={this.columns} onRowClick={this.handleRowClick} />
        </div>
        <div className={classes.modal__wrapper}>
          <CustomFilterModal
            filterName={filterName}
            filterSiteName={filterSiteName}
            filterSiteId={filterSiteId}
            filterId={filterId}
            open={showModal}
            onHide={this.hideModal}
            onRemoveSite={this.removeSite}
            onSetFilterName={this.setFilterName}
            onAddSite={this.addSite}
            siteSearch={siteSearch}
            onSave={this.saveCustomFilter}
            onSetQuery={this.setQuery}
            query={query}
            showImportProfile={showImportProfile}
            onAddImportProfile={this.addImportProfile}
            importProfileId={importProfileId}
            setImportProfiles={this.setImportProfiles}
            importProfiles={importProfiles}
            importProfileName={importProfileName}
          />
        </div>
      </div>
    );
  }
}
