import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button, Select, SelectOption } from '@spoiler-alert/ui-library';

const styles = {
  wrap: {
    height: 'calc(100vh - 250px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '300px',
  },
  heading: {
    textAlign: 'center',
    fontSize: '22px',
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const NewDataTableProfile = ({ classes, possibleDataTableProfiles, setReferenceProfile, tableType }) => {
  const [selectedProfile, setSelectedProfile] = useState(undefined);

  const createProfile = () => {
    setReferenceProfile(possibleDataTableProfiles.find((p) => p.id === selectedProfile.value));
  };

  const formatDataTableProfileOptions = () =>
    possibleDataTableProfiles.map((dtp) => (
      <SelectOption key={dtp.id} value={dtp.id}>
        {dtp.siteName} {dtp.dataTableName}
      </SelectOption>
    ));

  const changeSelectedProfile = (option) => {
    setSelectedProfile({ value: option[0].value, text: option[0].text });
  };

  useEffect(() => {
    setSelectedProfile(undefined);
  }, [tableType]);

  return (
    <div className={classes.wrap}>
      <h2 className={classes.heading}>Create a new data table</h2>
      <Select label="Data table" selectedItem={selectedProfile} onChange={changeSelectedProfile}>
        {formatDataTableProfileOptions()}
      </Select>
      <div className={classes.buttonWrap}>
        <Button onClick={createProfile} disabled={!selectedProfile} style={{ width: '300px' }}>
          Create New
        </Button>
      </div>
    </div>
  );
};

NewDataTableProfile.propTypes = {
  classes: PropTypes.object,
  possibleDataTableProfiles: PropTypes.array,
  referenceProfile: PropTypes.object,
  setReferenceProfile: PropTypes.func,
  tableType: PropTypes.string,
};

export default injectSheet(styles)(NewDataTableProfile);
