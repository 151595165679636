export default class VColumn {
    constructor(params) {
        this.visible = true;
        this.sortable = false;
        this.defaultSort = false;
        this.defaultSortDirection = 'asc';
        this.visibleOnHover = false;
        this.style = null;
        this.align = 'left';
        this.sortField = null;
        this.resizable = true;
        this.calculatedWidth = 0;
        this.calculatedResizable = true;
        if (params.field)
            this.field = params.field;
        if (params.displayName)
            this.displayName = params.displayName;
        if (params.visible !== undefined)
            this.visible = params.visible;
        if (params.sortable !== undefined)
            this.sortable = params.sortable;
        if (params.defaultSort !== undefined)
            this.defaultSort = params.defaultSort;
        if (params.defaultSortDirection)
            this.defaultSortDirection = params.defaultSortDirection;
        if (params.visibleOnHover !== undefined)
            this.visibleOnHover = params.visibleOnHover;
        if (params.style)
            this.style = params.style;
        if (params.align)
            this.align = params.align;
        if (params.formatter)
            this.formatter = params.formatter;
        if (params.enumMap)
            this.enumMap = params.enumMap;
        if (params.className)
            this.className = params.className;
        if (params.sortField)
            this.sortField = params.sortField;
        if (params.resizable !== undefined)
            this.resizable = params.resizable;
        if (params.width)
            this.width = params.width;
        this.dataKey = `${this.field}::${this.displayName}`;
    }
    render(value, row) {
        if (this.formatter && typeof this.formatter === 'function') {
            return this.formatter(value, row, this);
        }
        return value;
    }
}
