import AppSettings from '../app-settings';

class TitleService {
  constructor() {
    this.titleInstance = undefined;
  }

  setInstance(instance) {
    this.titleInstance = instance;
  }

  setTitles(headerTitle, pageTitle) {
    if (this.titleInstance) this.titleInstance.setTitle(headerTitle);
    this.setTitleTag(pageTitle || headerTitle);
  }

  setTitleTag(title = 'Welcome') {
    let suffix = ' | Administration Panel';
    const env = AppSettings.ENVIRONMENT_NAME;
    if (env !== 'prod') {
      suffix = `${suffix} | ${env}`;
    }
    if (document.querySelector('title')) {
      document.querySelector('title').innerText = `${title}${suffix}`;
    }
  }
}

const service = new TitleService();
export default service;
