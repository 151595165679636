import React from 'react';
import PropTypes from 'prop-types';
import { AlertTriangleIcon, Theme, Tooltip } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';

const styles = {
  wrap: {
    marginLeft: 'auto',
    flexGrow: 0,
  },
  iconHolder: {
    width: 18,
    height: 18,
    fill: Theme.red,
    marginRight: 9,
  },
  errorNotice: {
    display: 'flex',
    color: Theme.red,
    fontSize: '14px',
    cursor: 'pointer',
  },
  errorList: {
    textAlign: 'left',
    '& div': {
      marginBottom: '1em',
    },
    '& div:last-child': {
      marginBottom: 0,
    },
  },
};

const ETLImportErrors = ({ classes, errors, showCount, showLabel }) => {
  if (!errors || errors.length === 0) return <></>;

  const errorList = (
    <div className={classes.errorList}>
      {errors.map((err, index) => (
        <div key={`${err} ${index}`}>{err}</div>
      ))}
    </div>
  );

  return (
    <div className={classes.wrap}>
      <Tooltip text={errorList}>
        <div className={classes.errorNotice}>
          <div className={classes.iconHolder}>
            <AlertTriangleIcon />
          </div>
          <div>
            {showCount && errors.length} {showLabel && 'Validation errors'}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

ETLImportErrors.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.array,
  showCount: PropTypes.bool,
  showLabel: PropTypes.bool,
};

export default injectSheet(styles)(ETLImportErrors);
