import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MainContent extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.content !== this.props.content || nextProps.user !== this.props.user;
  }

  render() {
    return React.cloneElement(this.props.content, { user: this.props.user });
  }
}

MainContent.propTypes = {
  content: PropTypes.node,
  user: PropTypes.object,
};
