import { gql } from '@apollo/client';

export const UpdateExcludedValues = gql`
  mutation updateExcludedValues($siteId: String!, $exclusionType: String!, $excludedValues: [String]!) {
    updateExcludedValues(siteId: $siteId, exclusionType: $exclusionType, excludedValues: $excludedValues) {
      _id
      siteId
      siteName
      exclusionType
      excludedValues
    }
  }
`;
