import React, { useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { ErrorBoundary } from '@spoiler-alert/ui-library';
import ETLDefaultsTable from './etl-defaults-table';
import ETLMappingsTable from './etl-mappings-table';
import ETLOperations from './etl-operations';

const styles = {
  viewWrap: {
    display: 'flex',
    width: '100%',
    paddingBottom: 26,
  },
  operationsPane: {
    width: 300,
    marginLeft: 24,
  },
  mappingsPane: {
    flexGrow: 1,
  },
};

const ETLView = ({ classes, profileFields, importProfile, mutations, loadings, updated, setSaveInProgress }) => {
  const [selectedOperationId, setSelectedOperationId] = useState(null);

  const editOperation = (mappingId) => {
    setSelectedOperationId(mappingId);
  };

  const createMapping = (importMapping) => {
    importMapping.importProfileId = importProfile._id;
    mutations.createImportMapping({ variables: { importMapping } });
  };

  const createDefault = () => {
    mutations.createImportDefault({ variables: { importProfileId: importProfile._id } });
  };

  const updateMapping = (newMapping) => {
    if (newMapping.operation) {
      newMapping.operation.__typename = undefined;
    }
    mutations.updateImportMapping({ variables: { importMapping: newMapping } });
  };

  const updateDefault = (newDefault) => {
    newDefault.defaultValues.forEach((df) => {
      df.__typename = undefined;
    });
    mutations.updateImportDefault({ variables: { importDefault: newDefault } });
  };

  const deleteMapping = (importMapping) => {
    mutations.deleteImportMapping({ variables: { importMapping } });
  };

  const deleteDefault = (importDefault) => {
    importDefault.defaultValues.forEach((df) => {
      df.__typename = undefined;
    });
    mutations.deleteImportDefault({ variables: { importDefault } });
  };

  const externalNames = profileFields.headerNames?.map((hn) => hn.toLowerCase());

  return (
    <div className={classes.viewWrap}>
      <div className={classes.mappingsPane}>
        <ErrorBoundary>
          <ETLMappingsTable
            mappings={profileFields.mappings}
            externalNames={externalNames}
            possibleValidators={profileFields.possibleValidators}
            possibleInternalFields={profileFields.possibleInternalFields}
            itemFields={profileFields.itemFields}
            updateMapping={updateMapping}
            newMapping={createMapping}
            deleteMapping={deleteMapping}
            editOperation={editOperation}
            loadings={loadings}
            updatedMapping={updated.updatedMapping}
            setSaveInProgress={setSaveInProgress}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <ETLDefaultsTable
            defaults={profileFields.defaults}
            externalNames={externalNames}
            possibleInternalFields={profileFields.possibleInternalFields}
            itemFields={profileFields.itemFields}
            updateDefault={updateDefault}
            newDefault={createDefault}
            deleteDefault={deleteDefault}
            loadings={loadings}
            updatedDefault={updated.updatedDefault}
            importType={importProfile.importType}
            bypassItemValidation={importProfile.bypassItemValidation}
            setSaveInProgress={setSaveInProgress}
          />
        </ErrorBoundary>
      </div>
      <div className={classes.operationsPane}>
        <ErrorBoundary>
          <ETLOperations
            operations={profileFields.mappings.filter((m) => m.operation)}
            possibleOperators={profileFields.possibleOperators}
            possibleInternalFields={profileFields.possibleInternalFields}
            externalNames={externalNames}
            updateMapping={updateMapping}
            deleteMapping={deleteMapping}
            newMapping={createMapping}
            selectedOperationId={selectedOperationId}
            updatedMapping={updated.updatedMapping}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

ETLView.propTypes = {
  profileFields: PropTypes.object,
  importProfile: PropTypes.object,
  mutations: PropTypes.object,
  classes: PropTypes.object,
  loadings: PropTypes.object,
  updated: PropTypes.object,
  setSaveInProgress: PropTypes.func,
};

export default injectSheet(styles)(ETLView);
