import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { GraphQLDataTable, Column, Button } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { AdminSitesQuery } from '../../graphql/queries';
import TransactionCycle from './transaction-cycle';
import { Breadcrumbs } from '../../store';

const styles = {
  confirm_buttons: {
    display: 'flex',
    marginTop: 10,
  },
  distribution_button: {
    display: 'flex',
    marginTop: 5,
    marginRight: 10,
  },
  button_wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
};

@injectSheet(styles)
export default class Sites extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitles('Sites Administration');
    Breadcrumbs.set([
      {
        url: '/sites',
        title: 'Sites Administration',
      },
    ]);
  }

  static propTypes = {
    user: PropTypes.object,
    classes: PropTypes.object,
    history: PropTypes.object,
  };

  handleDynamicRowClick = (row) => {
    this.props.history.push(`/distribution-lists/${row._id}`);
  };

  buttonRow = (row) => {
    const { classes } = this.props;
    let rowValue;
    if (row.siteType === 'ORGANIZATION') {
      rowValue = (
        <div className={classes.button_wrapper}>
          <Button onClick={this.handleDynamicRowClick.bind(this, row)} className={classes.distribution_button}>
            Distribution Lists
          </Button>
          <TransactionCycle site={row} />
        </div>
      );
    } else if (row.siteType === 'BUSINESS_UNIT') {
      rowValue = (
        <div className={classes.button_wrapper}>
          <Button onClick={this.handleDynamicRowClick.bind(this, row)} className={classes.distribution_button}>
            Distribution Lists
          </Button>
        </div>
      );
    }
    return rowValue;
  };

  columns = [
    new Column({ field: 'siteName', displayName: 'Site Name', sortable: true, defaultSort: true }),
    new Column({
      field: 'transactionCycle',
      displayName: 'Site Actions',
      formatter: (value, row) => this.buttonRow(row),
    }),
  ];

  render() {
    return <GraphQLDataTable query={AdminSitesQuery} queryName="adminUserQuery" transition queryField="customerSites" columns={this.columns} />;
  }
}
