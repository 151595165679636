import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { DataTableNaked, Column, RowAction, CreateListingsIcon, BuildingIcon, Theme as theme } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';

const useStyles = createUseStyles({
  tableWrap: {
    border: `1px ${theme.grey30} solid`,
  },
});

const StorefrontList = ({ bread, storefronts, loadingStorefronts, history }) => {
  const classes = useStyles();

  useEffect(() => {
    TitleService.setTitles('Marketplace Manager');
    Breadcrumbs.set(bread);
  });

  const columns = useMemo(() => {
    return [
      new Column({ field: 'logo', displayName: 'Logo', formatter: (value) => <img src={value} width="50" height="50" /> }),
      new Column({ field: 'siteName', displayName: 'Storefront', sortable: true }),
      new Column({
        field: 'description',
        displayName: 'Description',
        sortable: true,
      }),
      new Column({
        field: 'website',
        displayName: 'Website',
        sortable: true,
      }),
      new Column({
        field: 'facilities',
        displayName: '# of Facilities',
        formatter: (value) => value.length,
        sortable: true,
      }),
      new Column({
        field: 'updatedAt',
        displayName: 'Last updated',
        formatter: (value) => moment(value).format('MM/DD/YYYY [at] h:mm A'),
        sortable: true,
        defaultSort: true,
        defaultSortDirection: 'desc',
      }),
    ];
  }, []);

  const handleInventoryClick = (row) => {
    history.push(`${routePaths.marketplaceManager}/${row._id}/skus`);
    return (event) => event.stopPropagation();
  };

  const handleStorefrontClick = (row) => {
    history.push(`${routePaths.marketplaceManager}/${row._id}`);
    return (event) => event.stopPropagation();
  };

  return (
    <div>
      <h4>Storefronts</h4>
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={storefronts || []}
          filterable="none"
          filterParameters={{}}
          filters={[]}
          loading={loadingStorefronts}
          columns={columns}
          rowActions={[
            <RowAction key="edit-storefront" tooltipText="Edit Storefront Details" icon={BuildingIcon} onClick={handleStorefrontClick} />,
            <RowAction key="skus" tooltipText="Manage Inventory" icon={CreateListingsIcon} onClick={handleInventoryClick} secondary />,
          ]}
        />
      </div>
    </div>
  );
};

StorefrontList.propTypes = {
  bread: PropTypes.array,
  storefronts: PropTypes.array,
  loadingStorefronts: PropTypes.bool,
  history: PropTypes.object,
};

export default StorefrontList;
