import { Theme as theme } from '@spoiler-alert/ui-library';

const formStyles = {
  form__container: {
    padding: '32px 32px 24px',
    width: 578,
    marginBottom: 24,
  },
  form__wrapper: {
    marginBottom: 24,
  },
  form__header: {
    color: theme.textColorPrimary,
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 'normal',
    marginBottom: 13,
  },
  form__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  form__item: {
    display: 'flex',
    flexDirection: 'column',
    width: '47%',
    marginTop: 15,
  },
  'form__item--row': {
    display: 'flex',
    flexDirection: 'row',
    width: '47%',
    marginTop: 21,
  },
  button__container: {
    borderTop: '1px solid #D9DEE1',
    paddingTop: 24,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginTop: 28,
  },
  '@media (max-width: 840px)': {
    form__container: {
      width: '100%',
    },
  },
};

export default formStyles;
