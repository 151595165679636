import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ActionMenu, ActionMenuOption, Card, DataSource, LoadingSmall, AlertService } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { GetActiveAdminUserQuery } from '../../graphql/queries';
import { DeleteUserAction, EditUserAction } from './actions';

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  'post-detail__container': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  'post-detail__header': {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    flexShrink: 0,
  },
  header_h2: {
    fontSize: '1.5rem',
    margin: 0,
  },
  info__block: {
    display: 'block',
    marginBottom: '16px',
  },
  info__title: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#333333',
  },
  info__header: {
    color: '#4eaac2',
    fontSize: '0.875rem',
    margin: 0,
    padding: 0,
  },
  info__contents: {
    fontSize: '1rem',
    color: '#333333',
    margin: 0,
    padding: 0,
  },
  info__card: {
    margin: '12px 0',
  },
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content__container: {
    opacity: 1,
    transitionDuration: '200ms',
    transitionProperty: 'opacity',
    position: 'relative',
    zIndex: 1,
  },
  'content__container--hidden': {
    opacity: 0,
  },
  actioncard__container: {
    position: 'relative',
    display: 'block',
  },
  action__box: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'scale(.6, .6)',
    opacity: 0,
    display: 'block',
    transitionDuration: '200ms',
    transitionProperty: 'opacity,transform',
    width: '100%',
  },
  'action__box--visible': {
    transform: 'scale(1, 1)',
    opacity: 1,
    zIndex: 2,
  },
};

@injectSheet(styles)
export default class ActiveUserDetails extends Component {
  state = {
    data: { loading: true, error: true, post: {} },
    id: this.props.id,
    shouldUpdate: true,
    showAction: false,
    action: '',
    codes: undefined,
    editing: false,
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    viewingSelf: this.props.id === this.props.user._id,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.id !== nextProps.id ||
      this.state.data !== nextState.data ||
      this.state.showAction !== nextState.showAction ||
      this.state.action !== nextState.action ||
      this.state.editing !== nextState.editing
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { shouldUpdate: nextProps.id !== prevState.id, id: nextProps.id };
  }

  dataReceived = (data) => {
    if (data.error) this.loadErrored();
    else this.setState({ data, shouldUpdate: false });
  };

  loadErrored() {
    this.props.onHide();
    const message = 'Sorry there was an error loading this user. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  }

  hideAction = (response, message) => {
    this.setState({ showAction: false, action: undefined });
    if (!response.data && message) this.actionErrored(message);
  };

  actionErrored(message) {
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  }

  close = () => {
    this.props.onHide();
  };

  get renderAction() {
    if (this.state.action)
      return (
        <this.state.action
          viewingSelf={this.state.viewingSelf}
          onHide={this.hideAction}
          onClose={this.close}
          user={this.props.user}
          flyoutUser={this.state.data.adminUserByIdQuery}
          refetchQueries={this.props.refetchQueries}
        />
      );
    return null;
  }

  deactivateUser = () => {
    this.setState({ action: DeleteUserAction });
  };

  editUser = () => {
    this.setState({ action: EditUserAction });
  };

  renderDate = (date) => (date ? moment(date).format('MMMM D, YYYY') : 'Never');

  render() {
    const { classes } = this.props;
    const { data, action, id, shouldUpdate, viewingSelf } = this.state;
    const showClass = action ? ` ${classes['content__container--hidden']}` : '';
    const contentClass = `${classes.content__container}${showClass}`;
    const actionShowClass = action ? ` ${classes['action__box--visible']}` : '';
    const actionClass = `${classes.action__box}${actionShowClass}`;
    return (
      <div className={classes.container}>
        <DataSource query={GetActiveAdminUserQuery} variables={{ id }} shouldUpdate={shouldUpdate} onFetch={this.dataReceived} />
        {!data.loading && !data.error && (
          <div className={classes['post-detail__container']}>
            <div className={classes['post-detail__header']}>
              <h2 className={classes.header_h2}>
                {data.adminUserByIdQuery.firstName} {data.adminUserByIdQuery.lastName}
              </h2>
              <ActionMenu hovered={true} position="topright">
                {!viewingSelf && <ActionMenuOption onClick={this.deactivateUser}>Deactivate User</ActionMenuOption>}
                <ActionMenuOption onClick={this.editUser}>Edit User</ActionMenuOption>
              </ActionMenu>
            </div>
            <div className={classes.actioncard__container}>
              <div className={actionClass}>{this.renderAction}</div>
            </div>
            <div className={contentClass}>
              <Card className={classes.info__card}>
                <div className={classes.info__block}>
                  <p className={classes.info__header}>First Name</p>
                  <p className={classes.info__contents}>{data.adminUserByIdQuery.firstName}</p>
                </div>
                <div className={classes.info__block}>
                  <p className={classes.info__header}>Last Name</p>
                  <p className={classes.info__contents}>{data.adminUserByIdQuery.lastName}</p>
                </div>
                <div className={classes.info__block}>
                  <p className={classes.info__header}>Email</p>
                  <p className={classes.info__contents}>{data.adminUserByIdQuery.email}</p>
                </div>
              </Card>
              <Card className={classes.info__card}>
                <div className={classes.info__block}>
                  <p className={classes.info__header}>Joined</p>
                  <p className={classes.info__contents}>{this.renderDate(data.adminUserByIdQuery.createdAt)}</p>
                </div>
                {data.adminUserByIdQuery.invitedBy && (
                  <div className={classes.info__block}>
                    <p className={classes.info__header}>Invited By</p>
                    <p className={classes.info__contents}>{data.adminUserByIdQuery.invitedBy}</p>
                  </div>
                )}
                <div className={classes.info__block}>
                  <p className={classes.info__header}>Last Logged In</p>
                  <p className={classes.info__contents}>{this.renderDate(data.adminUserByIdQuery.lastLoginDate)}</p>
                </div>
              </Card>
            </div>
          </div>
        )}
        {data.loading && (
          <div className={classes.loading}>
            <LoadingSmall />
          </div>
        )}
      </div>
    );
  }
}

ActiveUserDetails.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object,
  refetchQueries: PropTypes.array,
  onHide: PropTypes.func,
  user: PropTypes.object,
};
