import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Select, Row, Col, TrashIcon, TextBox, EmbiggeningTextBox, Button, Theme, ColorPicker } from '@spoiler-alert/ui-library';
import { listToOptions, cleanModel, SAVE_DELAY } from '../etl-helpers';
import RowOrderer from './row-orderer';

const styles = {
  textBoxWrap: {
    '&>div': {
      height: 32,
    },
  },
  trashColumn: {
    width: 75,
  },
  trashButton: {
    margin: [-7, 0, -10, 0],
  },
  fieldSelect: {},
  selectColumn: {
    '&>span': {
      display: 'flex',
      alignItems: 'center',
      '&>$fieldSelect': {
        flexGrow: 1,
        '&>div': {
          width: '100%',
          '&>div>span': {
            paddingLeft: '0 !important',
          },
        },
      },
    },
  },
  justSaved: {
    boxShadow: `inset 4px 0 0 ${Theme.green30}`,
  },
  colorPickerContainer: {
    display: 'flex',
  },
};

const ExportMapping = ({
  classes,
  mapping,
  possibleInternalFields,
  possibleFormatters,
  possibleColors,
  deleteMapping,
  updateMapping,
  onUp,
  onDown,
  position,
  first,
  last,
  justSaved,
}) => {
  const [editingMapping, setEditingMapping] = useState(() => cleanModel(mapping));

  const timerRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    setEditingMapping(cleanModel(mapping));
  }, [mapping]);

  const restartTimer = (recordToSave) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      updateMapping(recordToSave);
    }, SAVE_DELAY);
  };

  const setFieldValue = (field, value) => {
    const newMapping = { ...editingMapping };
    newMapping[field] = value;
    setEditingMapping(newMapping);
    restartTimer(newMapping);
  };

  const setDropdownValue = (field, value) => {
    setFieldValue(field, value.length > 0 ? value[0].value : null);
  };

  const internalFields = possibleInternalFields.map((f) => f.name);

  return (
    <Row className={justSaved ? classes.justSaved : ''}>
      <Col>
        <RowOrderer onUp={onUp} onDown={onDown} position={position} first={first} last={last} />
      </Col>
      <Col>
        <div className={classes.textBoxWrap}>
          <TextBox
            style={{ width: 200, textAlign: 'left', paddingLeft: 8 }}
            value={editingMapping.label || ''}
            onChange={(value) => setFieldValue('label', value)}
          />
        </div>
      </Col>
      <Col className={classes.selectColumn}>
        <Select
          minimal
          search
          disabled={editingMapping.locked}
          containerClassName={classes.fieldSelect}
          selectedItem={{ value: editingMapping.cellType, text: editingMapping.cellType }}
          onChange={(value) => setDropdownValue('cellType', value)}
        >
          {listToOptions(['money', 'number', 'date', 'percent', 'weight', 'dropdown', 'fraction', 'number extended', 'string'])}
        </Select>
      </Col>
      <Col>
        <div className={classes.textBoxWrap}>
          <TextBox
            style={{ width: 57, textAlign: 'right', paddingRight: 8 }}
            value={editingMapping.widthChars || ''}
            onChange={(value) => setFieldValue('widthChars', parseFloat(value))}
          />
        </div>
      </Col>
      <Col>
        <Select
          minimal
          search
          disabled={editingMapping.locked}
          containerClassName={classes.fieldSelect}
          selectedItem={{
            value: editingMapping.field,
            text: editingMapping.field,
          }}
          onChange={(value) => setDropdownValue('field', value)}
        >
          {listToOptions(internalFields)}
        </Select>
      </Col>
      <Col>
        <Select
          minimal
          search
          disabled={editingMapping.locked}
          containerClassName={classes.fieldSelect}
          selectedItem={{ value: editingMapping.formatter, text: editingMapping.formatter }}
          onChange={(value) => setDropdownValue('formatter', value)}
        >
          {listToOptions(possibleFormatters)}
        </Select>
      </Col>
      <Col>
        <div className={classes.textBoxWrap}>
          <TextBox
            style={{ width: 175, textAlign: 'left', paddingLeft: 8 }}
            value={editingMapping.formatterArg || ''}
            disabled={editingMapping.locked}
            onChange={(value) => setFieldValue('formatterArg', value)}
          />
        </div>
      </Col>
      <Col>
        <div className={classes.textBoxWrap}>
          <EmbiggeningTextBox
            style={{ width: 244, textAlign: 'left', paddingLeft: 8 }}
            value={editingMapping.formula || ''}
            disabled={editingMapping.locked}
            onChange={(value) => setFieldValue('formula', value)}
          />
        </div>
      </Col>
      <Col>
        <div className={classes.colorPickerContainer}>
          <ColorPicker
            label={'H'}
            title={'Header Color'}
            colors={possibleColors}
            selectedColor={editingMapping.headerStyles.color}
            onSelect={(value) => setFieldValue('headerStyles', { color: value })}
          />
          <ColorPicker
            label={'C'}
            title={'Column Color'}
            colors={possibleColors}
            selectedColor={editingMapping.columnStyles.color}
            onSelect={(value) => setFieldValue('columnStyles', { color: value })}
          />
        </div>
      </Col>
      <Col className={classes.trashColumn}>
        <Button
          warning
          resting={true}
          icon={TrashIcon}
          className={classes.trashButton}
          disabled={editingMapping.locked}
          onClick={(e) => {
            e.preventDefault();
            deleteMapping(editingMapping);
          }}
        />
      </Col>
    </Row>
  );
};

ExportMapping.propTypes = {
  classes: PropTypes.object,
  mapping: PropTypes.object,
  possibleInternalFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  possibleFormatters: PropTypes.array,
  possibleSheetWriters: PropTypes.array,
  possibleColors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hex: PropTypes.string,
    })
  ),
  deleteMapping: PropTypes.func,
  updateMapping: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  position: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  justSaved: PropTypes.bool,
};

export default injectSheet(styles)(ExportMapping);
