import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  plus: {
    display: 'inline-block',
    height: 13,
    width: 13,
    position: 'relative',
    top: 2,
    borderRadius: 2,
    marginRight: 6,
    backgroundColor: theme.teal,
    transition: 'background-color .3s',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 3,
      left: 6,
      backgroundColor: theme.white,
      width: 1,
      height: 7,
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 6,
      left: 3,
      backgroundColor: theme.white,
      width: 7,
      height: 1,
    },
    '&.naked': {
      marginRight: 0,
      backgroundColor: 'transparent',
      '&:before, &:after': {
        backgroundColor: theme.teal,
      },
    },
  },
  button: {
    color: theme.teal,
    lineHeight: '16px',
    height: 16,
    cursor: 'pointer',
    transition: 'color .3s',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.tealDark,
      '& $plus': {
        backgroundColor: theme.tealDark,
      },
      '& $plus.naked': {
        backgroundColor: 'transparent',
        '&:before, &:after': {
          backgroundColor: theme.tealDark,
        },
      },
    },
  },
};

const NewButton = ({ classes, children, onClick, naked }) => (
  <a className={classes.button} onClick={onClick}>
    <span className={`${classes.plus} ${naked ? 'naked' : ''}`}></span>
    {children}
  </a>
);

NewButton.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.string,
  onClick: PropTypes.func,
  naked: PropTypes.bool,
};

NewButton.defaultProps = {
  onClick: () => {},
  naked: false,
};

export default injectSheet(styles)(NewButton);
