import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button, Theme as theme } from '@spoiler-alert/ui-library';
import { RefreshService } from '../../services';

const styles = {
  refresher: {
    display: 'flex',
    padding: '10px 30px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 30,
    left: 30,
    backgroundColor: '#292F34',
    color: '#ECF1F5',
    boxShadow: '0px 0px 17px 3px rgba(0, 0, 0, 0.4)',
    zIndex: 5000,
    borderRadius: theme.borderRadius,
    animation: 'fade-in 500ms forwards',
  },
};

@injectSheet(styles)
export default class Refresher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      different: false,
    };

    this.checkVersion();
    this.interval = window.setInterval(this.checkVersion, this.duration);
  }

  // 5 minute duration
  get duration() {
    return 5 * 60 * 1000;
  }

  checkVersion = async () => {
    const serverVersion = await RefreshService.checkServerVersion();
    if (!this.version) this.version = serverVersion.version;
    if (serverVersion.version !== this.version) {
      this.setState({ different: true });
      window.clearInterval(this.interval);
    }
  };

  refresh = (ev) => {
    ev.preventDefault();
    window.location.reload(true);
  };

  render() {
    const { classes } = this.props;
    const { different } = this.state;
    if (!different) return null;
    return (
      <div className={classes.refresher}>
        There is a newer version of Admin Panel available.{' '}
        <Button type="button" link onClick={this.refresh}>
          REFRESH
        </Button>
      </div>
    );
  }
}

Refresher.propTypes = {
  classes: PropTypes.object,
};
