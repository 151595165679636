const pricingStrategyAnchor = new Map([
  ['COGS', 'COGS'],
  ['Price', 'List Price'],
  ['BestPriceReceivedGlobal', 'Best Price Received (Global)'],
  ['BestPriceReceivedDL', 'Best Price Received (DL)'],
  ['SetOnUpload', 'Set On Upload'],
  ['FlatRate', 'Flat Rate'],
]);

export default pricingStrategyAnchor;
