import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import client from '../apollo/client';

const styles = {
  logout: {
    textAlign: 'center',
    paddingTop: '3rem',
  },
};

@injectSheet(styles)
export default class Logout extends Component {
  logout = async () => {
    localStorage.removeItem('token');
    await client.clearStore();
    this.props.history.push('/sign-in');
  };

  componentDidMount() {
    const { user, mutate } = this.props;
    if (user && user.isImpersonating) {
      mutate().then(this.logout);
    } else {
      this.logout();
    }
  }

  render() {
    return (
      <div className={this.props.classes.logout}>
        <h1>Logging out ... </h1>
      </div>
    );
  }
}

Logout.propTypes = {
  user: PropTypes.object,
  mutate: PropTypes.func,
  classes: PropTypes.object,
  history: PropTypes.object,
};
