import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import { Loading } from '@spoiler-alert/ui-library';
import { AdminUserQuery } from './graphql/queries';
import Routes from './router';

const styles = {
  site__wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
};

@graphql(AdminUserQuery, {
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
})
@injectSheet(styles)
export default class SiteContext extends React.Component {
  state = {
    user: undefined,
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.data.adminUserQuery && localStorage.getItem('token')) {
      return { user: nextProps.data.adminUserQuery };
    }
    return null;
  }

  get loading() {
    return localStorage.getItem('token') && !this.state.user;
  }

  get children() {
    const { user } = this.state;
    return <Routes user={user} />;
  }

  render() {
    return (
      <Loading loading={this.loading}>
        <div className={this.props.classes.site__wrapper}>{!this.loading && this.children}</div>
      </Loading>
    );
  }
}

SiteContext.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};
