import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { TextInput, Button, AlertService, Card, TypeAhead, Pill, Select, SelectOption } from '@spoiler-alert/ui-library';
import { graphql } from '@apollo/client/react/hoc';
import { UpdateBuyerSellerRelationship } from '../../graphql/mutations';
import { SiteRoleTypeAheadQuery, BsrNameTypeAheadQuery } from '../../graphql/queries';
import formStyles from '../../components/forms/form-styles';

const styles = {
  ...formStyles,
  'buyer-relationship__submit-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  'seller-relationship__container': {
    display: 'flex',
    flexDirection: 'column',
  },
  'buyer-relationship__container': {
    display: 'flex',
    flexDirection: 'column',
  },
  'add-relationship__wrapper': {
    width: '75%',
    display: 'flex',
  },
  relationship__submit: {
    alignSelf: 'flex-end',
  },
  input__container: {
    flexDirection: 'row',
    display: 'flex',
  },
  card__wrapper: {
    width: '100%',
  },
};

const siteRoles = { BUYER: 'BUYER', SELLER: 'SELLER' };
@graphql(UpdateBuyerSellerRelationship, { name: 'updateBuyerSellerRelationship' })
@injectSheet(styles)
export default class EditRelationship extends Component {
  static propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    serialize: PropTypes.func,
    mutate: PropTypes.func,
    updateBuyerSellerRelationship: PropTypes.func,
  };

  state = {
    buyerSiteName: '',
    sellerSiteName: '',
    currentBuyerEmail: '',
    buyerEmails: [],
    currentSellerEmail: '',
    sellerEmails: [],
    allowEmailEntry: false,
    bsrName: '',
    bsrType: '',
    originalBsrName: '',
    mode: undefined,
    bsrSellerSiteId: undefined,
  };

  formatBSRName = (sellerSiteName, buyerSiteName, bsrType) => `${sellerSiteName} - ${buyerSiteName} - ${bsrType}`;

  updateBuyerSiteName = (_, value) => {
    const buyerSiteName = value.result.siteName;
    this.setState({ buyerSiteName, bsrName: this.formatBSRName(this.state.sellerSiteName, buyerSiteName, this.state.bsrType) });
  };

  updateSellerSiteName = (_, value) => {
    const updatedState = { sellerSiteName: value.result.siteName, bsrSellerSiteId: value.result._id };
    if (this.state.buyerSiteName) updatedState.allowEmailEntry = true;
    this.setState(updatedState);
  };

  selectBsrByName = (value) => {
    const { buyerSiteName, buyerUserEmails, sellerUserEmails, name, type } = value.result;
    this.setState({
      buyerSiteName,
      buyerEmails: buyerUserEmails,
      sellerEmails: sellerUserEmails,
      originalBsrName: name,
      bsrName: name,
      bsrType: type,
      allowEmailEntry: true,
    });
  };

  get buyerSiteNameTypeahead() {
    const params = { siteRole: siteRoles.BUYER };
    return (
      <TypeAhead
        query={SiteRoleTypeAheadQuery}
        queryName="siteRoleTypeAheadQuery"
        dataFormat={[{ displayText: 'buyerSiteName' }]}
        labelText={'Buyer Site'}
        value={this.state.buyerSiteName}
        onChange={this.updateBuyerSiteName.bind(this, 'buyerSiteName')}
        itemLimit={200}
        queryVariables={params}
        autoWidth
      />
    );
  }

  get sellerSiteNameTypeahead() {
    const params = { siteRole: siteRoles.SELLER };
    return (
      <TypeAhead
        query={SiteRoleTypeAheadQuery}
        queryName="siteRoleTypeAheadQuery"
        dataFormat={[{ displayText: 'sellerSiteName' }]}
        labelText={'Seller Site'}
        onChange={this.updateSellerSiteName.bind(this, 'sellerSiteName')}
        value={this.state.sellerSiteName}
        itemLimit={200}
        queryVariables={params}
        autoWidth
      />
    );
  }

  setCurrentBuyerEmail = (email) => {
    this.setState({ currentBuyerEmail: email.trim() });
  };

  setCurrentSellerEmail = (email) => {
    this.setState({ currentSellerEmail: email.trim() });
  };

  onRemovebuyerEmail = (email) => {
    const { buyerEmails } = this.state;
    this.setState({ buyerEmails: buyerEmails.filter((e) => e !== email) });
  };

  onRemoveSellerEmail = (email) => {
    const { sellerEmails } = this.state;
    this.setState({ sellerEmails: sellerEmails.filter((e) => e !== email) });
  };

  addBuyerEmail = () => {
    const { buyerEmails, currentBuyerEmail } = this.state;
    this.setState({ buyerEmails: [...buyerEmails, currentBuyerEmail], currentBuyerEmail: '' });
  };

  addSellerEmail = () => {
    const { sellerEmails, currentSellerEmail } = this.state;
    this.setState({ sellerEmails: [...sellerEmails, currentSellerEmail], currentSellerEmail: '' });
  };

  listBuyerPills = () => {
    const { buyerEmails } = this.state;
    return buyerEmails.map((be) => <Pill key={`.pill-${be}`} fileName={be} onCancel={this.onRemovebuyerEmail} />);
  };

  listSellerPills = () => {
    const { sellerEmails } = this.state;
    return sellerEmails.map((be) => <Pill key={`.pill-${be}`} fileName={be} onCancel={this.onRemoveSellerEmail} />);
  };

  resetData = () => {
    this.setState({
      buyerSiteName: '',
      sellerSiteName: '',
      bsrSellerSiteId: '',
      currentBuyerEmail: '',
      buyerEmails: [],
      currentSellerEmail: '',
      sellerEmails: [],
      allowEmailEntry: false,
      mode: undefined,
      bsrName: '',
      bsrType: '',
    });
  };

  setBsrName = (value) => {
    this.setState({ bsrName: value.trim() });
  };

  setBsrType = (bsr) => {
    this.setState({ bsrType: bsr[0].value, bsrName: this.formatBSRName(this.state.sellerSiteName, this.state.buyerSiteName, bsr[0].value) });
  };

  saveBuyerSellerRelationship = () => {
    const { buyerSiteName, sellerSiteName, buyerEmails, sellerEmails, bsrName, bsrType, mode, originalBsrName } = this.state;
    this.props
      .updateBuyerSellerRelationship({
        variables: { buyerSiteName, sellerSiteName, buyerEmails, sellerEmails, bsrName, bsrType, editBsr: mode === 'edit', originalBsrName },
      })
      .then((res) => {
        AlertService.alert({
          type: 'success',
          message: res.data.updateBuyerSellerRelationship.result,
          autoDismiss: true,
          dismissDelay: 5000,
        });
      })
      .catch((err) => {
        AlertService.alert({
          type: 'warning',
          message: err.message,
        });
      });
  };

  setMode = (mode) => {
    this.setState({ mode });
  };

  get bsrNameTypeAhead() {
    const additionalQueryParams = { sellerSiteId: this.state.bsrSellerSiteId, mode: this.state.mode === 'edit' ? 'edit' : 'add' };
    return (
      <TypeAhead
        query={BsrNameTypeAheadQuery}
        queryName="bsrNameTypeAheadQuery"
        labelText={'Buyer Seller Relationship Name'}
        onChange={this.selectBsrByName}
        onSelect={this.onSelect}
        itemLimit={10}
        value={this.state.originalBsrName}
        queryVariables={additionalQueryParams}
        autoWidth
      />
    );
  }

  setSellerSiteName = (sellerSiteName) => {
    this.setState({ sellerSiteName });
  };

  setBuyerSiteName = (buyerSiteName) => {
    this.setState({ buyerSiteName });
  };

  get generateBuyerSellerRelationshipListTypes() {
    const buyerSellerRelationshipTypes = ['OFFER', 'AWARD', 'SEED'];
    return buyerSellerRelationshipTypes.map((list) => (
      <SelectOption value={list} key={list}>
        {list}
      </SelectOption>
    ));
  }

  render() {
    const { classes } = this.props;
    const { currentBuyerEmail, currentSellerEmail, allowEmailEntry, bsrName, bsrType, mode, bsrSellerSiteId } = this.state;
    const selectedItem = { value: bsrType, text: bsrType };
    const titleText = mode === 'add' ? 'Add Buyer-Seller Relationship' : 'Edit Buyer-Seller Relationship';
    return (
      <div>
        <div>
          <Button className={classes.relationship__submit} onClick={() => this.setMode('add')} id="reset-buyer-relationship-button">
            Add BSR
          </Button>
          <Button className={classes.relationship__submit} onClick={() => this.setMode('edit')} id="buyer-relationship-button">
            Edit BSR
          </Button>
        </div>
        {mode && (
          <div id="add-relationship-wrapper" className={classes['add-relationship__wrapper']}>
            <Card className={classes.card__wrapper}>
              <h3>{titleText}</h3>
              {mode === 'edit' && (
                <div>
                  {this.sellerSiteNameTypeahead}
                  {bsrSellerSiteId && this.bsrNameTypeAhead}
                </div>
              )}
              {(mode === 'add' || bsrName) && (
                <div className={classes['buyer-relationship__container']}>
                  <div>{this.buyerSiteNameTypeahead}</div>
                  <div>{this.sellerSiteNameTypeahead}</div>
                </div>
              )}
              {allowEmailEntry ? (
                <div>
                  <div className={classes['seller-relationship__container']}>
                    <div>
                      <div className={classes.input__container}>
                        <TextInput
                          inputClassName={classes.relationship__input}
                          onChange={this.setBsrName}
                          id="bsr-name"
                          required
                          type="text"
                          labelText="Updated Name"
                          value={bsrName}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={classes.input__container}>
                        <Select
                          label="BSR Type"
                          id="bsr-type"
                          inputClassName={classes.relationship__input}
                          onChange={this.setBsrType}
                          required
                          selectedItem={selectedItem}
                        >
                          {this.generateBuyerSellerRelationshipListTypes}
                        </Select>
                      </div>
                    </div>
                    <div>
                      {this.listBuyerPills()}
                      <div className={classes.input__container}>
                        <TextInput
                          inputClassName={classes.relationship__input}
                          onChange={this.setCurrentBuyerEmail}
                          id="buyer-email"
                          required
                          type="email"
                          labelText="Buyer emails"
                          value={currentBuyerEmail}
                        />
                        <Button type="button" onClick={this.addBuyerEmail}>
                          Add
                        </Button>
                      </div>
                    </div>
                    <div>
                      {this.listSellerPills()}
                      <div className={classes.input__container}>
                        <TextInput
                          inputClassName={classes.relationship__input}
                          onChange={this.setCurrentSellerEmail}
                          id="seller-email"
                          required
                          type="email"
                          labelText="Seller emails"
                          value={currentSellerEmail}
                        />
                        <Button type="button" onClick={this.addSellerEmail}>
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className={classes['buyer-relationship__submit-container']}>
                    <Button
                      className={classes.relationship__submit}
                      onClick={this.resetData}
                      id="reset-buyer-relationship-button"
                      type="submit"
                      loadingText="Loading..."
                    >
                      Reset Form
                    </Button>
                    <Button
                      className={classes.relationship__submit}
                      onClick={this.saveBuyerSellerRelationship}
                      id="buyer-relationship-button"
                      type="submit"
                      loadingText="Loading..."
                    >
                      Save Relationship
                    </Button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </Card>
          </div>
        )}
      </div>
    );
  }
}
