import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const allowedFormats = ['bold', 'italic', 'link', 'strike', 'script', 'underline', 'header', 'blockquote', 'list', 'image', 'video'];

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike', { script: 'sub' }, { script: 'super' }], // superscript/subscript
  ['link', 'image', 'video'],
  ['blockquote', { header: 1 }, { header: 2 }, { list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],

  ['clean'], // remove formatting button
];

// QuillEditor is an uncontrolled React component
const QuillEditor = forwardRef(({ readOnly, defaultValue, onTextChange, onSelectionChange, placeholder }, ref) => {
  const containerRef = useRef(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onTextChange);
  const onSelectionChangeRef = useRef(onSelectionChange);

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    onSelectionChangeRef.current = onSelectionChange;
  });

  useEffect(() => {
    ref.current?.enable(!readOnly);
  }, [ref, readOnly]);

  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
    const quill = new Quill(editorContainer, {
      theme: 'snow',
      placeholder,
      formats: allowedFormats,
      modules: {
        toolbar: toolbarOptions,
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true,
        },
        clipboard: true,
      },
    });

    ref.current = quill;

    if (defaultValueRef.current) {
      quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);
    }

    quill.on(Quill.events.TEXT_CHANGE, () => {
      onTextChangeRef.current?.(quill.getSemanticHTML());
    });

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      onSelectionChangeRef.current?.(...args);
    });

    return () => {
      ref.current = null;
      container.innerHTML = '';
    };
  }, [ref]);

  return <div ref={containerRef}></div>;
});

QuillEditor.propTypes = {
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  onTextChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  placeholder: PropTypes.string,
};

QuillEditor.defaultProps = {
  readOnly: false,
  onSelectionChange: () => {},
};

QuillEditor.displayName = 'QuillEditor';

export default QuillEditor;
