import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AlertService } from '@spoiler-alert/ui-library';

const ETLErrors = ({ errors }) => {
  useEffect(() => {
    const errorMessages = errors.filter((e) => !!e);
    if (errorMessages.length > 0) {
      AlertService.alert({ type: 'warning', message: <span>GraphQL Error: {errorMessages[0].message}</span> });
    }
  }, errors);
  return <></>;
};

ETLErrors.propTypes = {
  errors: PropTypes.array,
};

export default ETLErrors;
