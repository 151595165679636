import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class ItemImportService {
  import(file, effectiveDate, siteId) {
    const importUrl = `${AppSettings.GRAPHQL_SERVER_URL}import/item`;
    const body = new FormData();
    body.append('itemFile', file);
    body.append('effectiveDate', effectiveDate.toISOString());
    body.append('siteId', siteId);
    return fetchWithToken(importUrl, {
      body,
      method: 'POST',
    });
  }
}

const itemImportService = new ItemImportService();
export default itemImportService;
