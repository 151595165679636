import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Column, DataTableNaked, RowAction, BoxIcon, Theme as theme } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';

const useStyles = createUseStyles({
  tableWrap: {
    border: `1px ${theme.grey30} solid`,
  },
});

const StorefrontSKUList = ({ bread, match, history, storefront, skuList, loadingSKUList }) => {
  const classes = useStyles();

  useEffect(() => {
    TitleService.setTitles(`Marketplace Manager => SKUS for ${storefront?.siteName}`);
    Breadcrumbs.set(bread);
  }, [bread, history.location]);

  const columns = useMemo(() => {
    return [
      new Column({ field: 'thumbnailImage', displayName: 'Thumbnail', formatter: (value) => <img src={value} width="50" height="50" /> }),
      new Column({ field: '_id', displayName: 'UPC', sortable: true, defaultSort: true }),
      new Column({
        field: 'description',
        displayName: 'Description',
        sortable: true,
      }),
      new Column({
        field: 'truckType',
        displayName: 'Truck Type',
        sortable: true,
      }),
    ];
  }, []);

  const handleSKUClick = (row) => {
    history.push(`${routePaths.marketplaceManager}/${match.params.storefrontId}/skus/${row._id}`);
    return (event) => event.stopPropagation();
  };

  return (
    <div className={classes.tableWrap}>
      <DataTableNaked
        data={skuList}
        loading={loadingSKUList}
        filterable="none"
        filterParameters={{}}
        filters={[]}
        columns={columns}
        rowActions={[<RowAction key="edit-sku" tooltipText="Edit SKU Details" icon={BoxIcon} onClick={handleSKUClick} />]}
      />
    </div>
  );
};

StorefrontSKUList.propTypes = {
  bread: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
  skuList: PropTypes.array,
  loadingSKUList: PropTypes.bool,
  storefront: PropTypes.object,
};

export default StorefrontSKUList;
