import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class DataExportService {
  export = (exportType) =>
    fetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}export/${exportType}`, {
      method: 'POST',
    });
}

const dataExportService = new DataExportService();
export default dataExportService;
