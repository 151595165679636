import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TextInput, Button, Loading, AlertService } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { InviteService, LoginService, TitleService } from '../../services';
import client from '../../apollo/client';
import routePaths from '../../route-paths';

const styles = {
  login__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '1170px',
    margin: '0 auto',
  },
  login__title: {
    fontSize: '1.3rem',
    marginBottom: '40px',
    textAlign: 'center',
  },
  form__row: {
    width: '360px',
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  form__container: {
    marginBottom: '2.8rem',
  },
  form__button: {
    width: '100%',
    textTransform: 'uppercase',
  },
  message__container: {
    width: '360px',
    borer: '1px solid #d6e9c6',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    height: '36px',
    marginBottom: '15px',
  },
  'message__container--error': {
    borer: '1px solid #ebccd1',
    backgroundColor: '#f2dede',
    color: '#a94442',
    textAlign: 'center',
  },
  'form__input--left': {
    marginRight: '5px',
  },
  'form__input--right': {
    marginLeft: '5px',
  },
  login__link: {
    color: '#5d9bd1',
    cursor: 'pointer',
  },
  loading__container: {
    maxHeight: '76vh',
  },
  login__input: {
    backgroundColor: 'transparent',
  },
};

@injectSheet(styles)
export default class RegisterInvitee extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Register Invitee');
    this.state = {
      errored: false,
      expired: false,
      notFound: false,
      exists: false,
      password: '',
      confirmPassword: '',
      messageType: 'none',
      loading: true,
    };

    if (this.isAuthenticated) return this.props.history.push(routePaths.home);
  }

  get isAuthenticated() {
    return !!localStorage.getItem('token');
  }

  async componentDidMount() {
    try {
      const response = await InviteService.getInvite(this.props.match.params.token);
      const invite = await response.json();
      if (!response.ok) throw Error(invite.message);
      this.setState({
        invite: {
          email: invite.email,
          firstName: invite.firstName,
          lastName: invite.lastName,
          _id: invite.id,
        },
        loading: false,
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError = (err) => {
    this.setState({
      loading: false,
      errored: true,
      expired: err.message === 'Invitation is expired',
      notFound: err.message === 'Invitation is not found',
      exists: err.message === 'User with that email address already exists',
    });
  };

  updateForm(key, value) {
    let newValue;
    if (key === 'phone' && value.length >= 10) {
      newValue = value.replace(/[^0-9]/g, '');
      newValue = `(${newValue.substring(0, 3)}) ${newValue.substring(3, 6)}-${newValue.substring(6, 10)}`;
    }

    this.setState({
      [key]: newValue,
    });
  }

  updateInvite(key, value) {
    const { invite } = this.state;
    this.setState({
      invite: {
        ...invite,
        [key]: value,
      },
    });
  }

  validatePassword() {
    const { password, confirmPassword } = this.state;
    const valid = password.trim().length >= 6 && password === confirmPassword;
    if (!valid) {
      this.setState({
        messageType: 'error',
        message: 'Your passwords do not match.',
      });
    }
    return valid;
  }

  validateOnSubmit = () => this.validatePassword();

  refetch = () => {
    client.resetStore();
  };

  createUserAndLogin = (profile) => {
    const { password, invite } = this.state;
    InviteService.acceptInvite(invite._id, password, profile)
      .then(() => {
        this.setState({
          message: 'Account Created! Now Logging In...',
          messageType: 'success',
        });
        LoginService.login(profile.email, password)
          .then(this.refetch)
          .catch(() => AlertService.alert({ type: 'warning', message: <span>We had a problem logging in.</span>, autoDismiss: true }));
      })
      .catch(() => {
        this.setState({
          messageType: 'error',
          message: 'We had a problem creating the account.',
          saving: false,
        });
      });
  };

  submitNewInvitee = (event) => {
    event.preventDefault();
    if (!this.validateOnSubmit()) return;

    const { invite } = this.state;
    this.setState({ saving: true });

    const profile = {
      firstName: invite.firstName,
      lastName: invite.lastName,
      email: invite.email,
    };
    this.createUserAndLogin(profile);
  };

  render() {
    const { classes } = this.props;
    const { expired, notFound, messageType, message, loading, saving, invite, password, confirmPassword, exists, errored } = this.state;
    const messageClassName = `${classes.message__container} ${messageType === 'error' ? classes['message__container--error'] : ''}`;

    return (
      <div className={classes.loading__container}>
        <Loading loading={loading}>
          <div>
            {!loading && !errored && (
              <div className={classes.login__container} data-element="register-invitee">
                <h4 className={classes.login__title}>
                  Welcome to the Spoiler Alert Administration Panel! <br />
                  <br />
                  Fill in the details below to create your account for <b>{invite.email}</b>.
                </h4>
                {message && (
                  <div className={messageClassName} data-element={`message-block-${messageType}`}>
                    {message}
                  </div>
                )}
                <form onSubmit={this.submitNewInvitee} className={classes.form__container}>
                  <div className={classes.form__row} data-element="name">
                    <TextInput
                      id="first-name"
                      inputClassName={classes.login__input}
                      className={classes['form__input--left']}
                      onChange={this.updateInvite.bind(this, 'first')}
                      required
                      type="text"
                      labelText="First Name"
                      value={invite.firstName}
                      tabIndex={1}
                      autoComplete="first-name"
                    />
                    <TextInput
                      id="last-name"
                      inputClassName={classes.login__input}
                      className={classes['form__input--right']}
                      onChange={this.updateInvite.bind(this, 'last')}
                      required
                      type="text"
                      labelText="Last Name"
                      value={invite.lastName}
                      tabIndex={2}
                      autoComplete="last-name"
                    />
                  </div>

                  <div className={classes.form__row} data-element="password">
                    <TextInput
                      id="password"
                      inputClassName={classes.login__input}
                      onChange={this.updateForm.bind(this, 'password')}
                      required
                      type="password"
                      labelText="New Password"
                      value={password}
                      pattern=".{6,}"
                      title="Your Password must be at least 6 characters"
                      tabIndex={4}
                      autoComplete="password"
                    />
                  </div>

                  <div className={classes.form__row} data-element="confirm-password">
                    <TextInput
                      id="confirm-password"
                      inputClassName={classes.login__input}
                      onChange={this.updateForm.bind(this, 'confirmPassword')}
                      required
                      type="password"
                      labelText="Confirm New Password"
                      value={confirmPassword}
                      pattern=".{6,}"
                      title="Your Password must be at least 6 characters"
                      tabIndex={5}
                      autoComplete="confirm-password"
                    />
                  </div>

                  <div className={classes.form__row}>
                    <Button className={classes.form__button} id="create-account" type="submit" loading={saving} loadingText="Loading...">
                      Create my SA Administration account
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {expired && <h3>We&apos;re sorry, but your invitation is expired.</h3>}
            {notFound && <h3>We&apos;re sorry, but the invitation was not found.</h3>}
            {exists && (
              <h3>
                The invitation was already accepted. Try <Link to={routePaths.signIn}>logging in</Link> instead.
              </h3>
            )}
          </div>
        </Loading>
      </div>
    );
  }
}

RegisterInvitee.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};
