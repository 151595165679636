import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { RowAction, DataTableNaked, Column, ManualIcon, ReturnIcon, Timing, Theme as theme, ColorBadge } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import { TimebombsQuery } from '../../graphql/queries';
import { ToggleTimebombMutation } from '../../graphql/mutations';
import RemindersManager from './reminders-manager';

const SUPPORTED_TIMEBOMBS = {
  1604: 'UAT Backup/Restore',
  1700: 'Due Date Reminder',
};

const useStyles = createUseStyles({
  tableWrap: {
    border: `1px ${theme.grey30} solid`,
  },
});

const TimebombManager = ({ user }) => {
  const classes = useStyles();
  useEffect(() => {
    TitleService.setTitles('Timebomb Manager');
    Breadcrumbs.set([
      {
        url: '/timebombs',
        title: 'Timebomb Manager',
      },
    ]);
  }, []);
  const { data, loading, error } = useQuery(TimebombsQuery);
  const [toggleTimebomb, { loading: togglingTimebomb }] = useMutation(ToggleTimebombMutation);

  const timebombs = useMemo(() => {
    if (!data) return [];
    const { getTimebombsQuery, getRulesQuery } = data;
    const rulesMap = getRulesQuery.reduce((acc, item) => {
      acc.set(item.eventId, item);
      return acc;
    }, new Map());
    return getTimebombsQuery
      .filter((bomb) => !!SUPPORTED_TIMEBOMBS[bomb.event])
      .map((bomb) => {
        const rule = rulesMap.get(bomb.event);
        return {
          ...bomb,
          enabled: rule?.enabled,
          niceName: rule?.eventName,
        };
      });
  }, [data]);

  const columns = useMemo(() => {
    return [
      new Column({ field: 'event', displayName: 'Event Type', formatter: (value) => SUPPORTED_TIMEBOMBS[value], sortable: true, defaultSort: true }),
      new Column({
        field: 'enabled',
        displayName: 'Enabled',
        formatter: (value) => (
          <ColorBadge theme={value ? theme.badgeColors.green : theme.badgeColors.red}>{value ? 'ENABLED' : 'DISABLED'}</ColorBadge>
        ),
        sortable: true,
      }),
      new Column({
        field: 'firingID',
        displayName: 'Target ID',
        sortable: true,
      }),

      new Column({
        field: 'expires',
        displayName: 'Expires On',
        formatter: (value) => {
          return moment(value).calendar();
        },
        sortable: true,
      }),
    ];
  }, []);

  const disabledPauses = timebombs.filter((tb) => tb.enabled === false).map((tb) => tb._id);
  const disabledResumes = timebombs.filter((tb) => tb.enabled === true).map((tb) => tb._id);

  const toggleTimebombHandler = Timing.debounce((timebomb, enabled) => {
    toggleTimebomb({
      variables: {
        eventId: timebomb.event,
        enabled,
      },
    });
  }, 1000);

  return (
    <div>
      {error}
      <h4>Pending Events</h4>
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={timebombs}
          filterable="none"
          filterParameters={{}}
          filters={[]}
          loading={loading || togglingTimebomb}
          rowActions={[
            <RowAction
              key="pause"
              tooltipText="Pause Timebomb"
              loadingTooltipText="Pausing Timebomb"
              icon={ManualIcon}
              onClick={(row) => toggleTimebombHandler.bind(this, row, false)}
              disabledRows={disabledPauses}
              warning
            />,
            <RowAction
              key="resume"
              tooltipText="Resume Timebomb"
              loadingTooltipText="Resuming Timebomb"
              icon={ReturnIcon}
              disabledRows={disabledResumes}
              onClick={(row) => toggleTimebombHandler.bind(this, row, true)}
            />,
          ]}
          onRowClick={() => {}}
          columns={columns}
        />
      </div>
      <RemindersManager user={user} />
    </div>
  );
};

TimebombManager.propTypes = {
  user: PropTypes.object,
};

export default TimebombManager;
