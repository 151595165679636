import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class EmailService {
  SendNamedEmail = (emailName, params) =>
    fetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}email/${emailName}`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    });

  SiteResponsibleParty = (siteId) => this.SendNamedEmail('site-responsible-party', { siteId });
}

const emailService = new EmailService();
export default emailService;
