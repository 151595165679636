import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button, Select, SelectOptionGroup, SelectOption, TextInput, Theme, CancelIcon } from '@spoiler-alert/ui-library';
import NewButton from './new-button';
import { listToOptions, listToSelected, cleanModel } from '../etl-helpers';

const styles = {
  wrap: {
    border: `1px ${Theme.borderColor} solid`,
    padding: [16, 0],
    height: '100%',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: [0, 16, 12],
    paddingBottom: 17,
    borderBottom: `1px ${Theme.borderColor} solid`,
    alignItems: 'center',
    '& h4': {
      margin: 0,
    },
  },
  justSaved: {},
  rowError: {},
  bubble: {
    backgroundColor: '#f2f2f3',
    borderRadius: 6,
    marginBottom: 8,
    padding: [8, 12],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Theme.green30,
    },
    '&$justSaved': {
      backgroundColor: Theme.green10,
    },
    '&$rowError': {
      background: Theme.pink10,
    },
  },
  window: {
    overflow: 'hidden',
  },
  slider: {
    width: '200%',
    transform: 'translate(0, 0)',
    position: 'relative',
    display: 'flex',
    transition: 'transform .5s ease-in-out',
    '&.open': {
      transform: 'translate(-50%, 0)',
    },
  },
  list: {
    width: '50%',
    padding: [0, 16],
  },
  single: {
    width: '50%',
    padding: [0, 16],
  },
  cancel: {
    color: Theme.teal,
    lineHeight: '16px',
    height: 16,
    cursor: 'pointer',
    '&:hover': {
      color: Theme.tealDark,
    },
  },
  newParams: {
    display: 'flex',
    '& a': {
      marginRight: 16,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 32,
    '& button': {
      width: 'calc(50% - 42px)',
    },
  },
  newOperationMessage: {
    marginTop: '30vh',
    fontSize: '1rem',
    textAlign: 'center',
    padding: [0, 40],
    lineHeight: '1.4em',
  },
  paramDisplay: {
    display: 'flex',
  },
  selectHolder: {
    flexGrow: '10',
    minWidth: 'inherit',
    margin: [0, 5, 0, 0],
  },
  textFieldWidth: {
    width: 'calc(100% - 15px)',
    margin: [0, 0, 0, -5],
    paddingLeft: '5px',
    '& hr': {
      width: 'calc(100% - 5px)',
    },
    '& label': {
      paddingLeft: '5px',
    },
  },
  fieldWidth: {
    width: 'calc(100% - 15px)',
    margin: [0, 0, 0, -5],
    paddingLeft: '5px',
  },
  cancelButton: {
    margin: [-7, 0, -10, 0],
    alignSelf: 'center',
    maxWidth: '13px',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:hover div': {
      fill: '#292F34',
    },
  },
};

const ETLOperations = ({
  classes,
  operations,
  possibleInternalFields,
  externalNames,
  possibleOperators,
  selectedOperationId,
  newMapping,
  updateMapping,
  deleteMapping,
  updatedMapping,
}) => {
  const [selectedOperation, setSelectedOperation] = useState(null);
  useEffect(() => {
    if (selectedOperationId) {
      setSelectedOperation(cleanModel(operations.find((op) => op._id === selectedOperationId)));
    }
  }, [selectedOperationId]);

  useEffect(() => {
    if (selectedOperation) {
      const foundOperation = operations.find((op) => op._id === selectedOperation?._id);
      if (foundOperation) {
        setSelectedOperation(cleanModel(foundOperation));
      } else {
        setSelectedOperation(null);
      }
    }
  }, [operations.length]);

  const editOperation = (operation) => {
    setSelectedOperation(cleanModel(operation));
  };

  const createOperation = () => {
    setSelectedOperation({ operation: {} });
  };

  const setValue = (field, value) => {
    const newOp = {
      ...selectedOperation,
    };
    newOp[field] = value;
    setSelectedOperation(newOp);
  };

  const setOperationValue = (field, value, index) => {
    const newOp = {
      ...selectedOperation,
    };
    if (index >= 0) {
      newOp.operation[field][index] = value;
    } else {
      newOp.operation[field] = value;
    }
    setSelectedOperation(newOp);
  };

  const deleteOperationField = (field, index) => {
    if (index >= 0) {
      const newOp = {
        ...selectedOperation,
      };
      newOp.operation[field].splice(index, 1);
      setSelectedOperation(newOp);
    }
  };

  const setDropdownValue = (field, value, multiple = false) => {
    if (multiple) {
      setValue(
        field,
        value.map((v) => v.value)
      );
    } else {
      setValue(field, value[0].value);
    }
  };

  const setOperationDropdownValue = (field, value, index) => {
    setOperationValue(field, value[0].value, index);
  };

  const addParam = () => {
    const newOp = {
      ...selectedOperation,
    };
    if (newOp.operation.params) {
      newOp.operation.params.push('');
    } else {
      newOp.operation.params = [''];
    }
    setSelectedOperation(newOp);
  };

  const addStaticParam = () => {
    const newOp = {
      ...selectedOperation,
    };
    if (newOp.operation.staticParams) {
      newOp.operation.staticParams.push('');
    } else {
      newOp.operation.staticParams = [''];
    }
    setSelectedOperation(newOp);
  };

  const isOperationValid = (operation) => {
    if (!operation.operation.operator) {
      return false;
    }
    return true;
  };

  const saveIt = () => {
    if (selectedOperation._id) {
      updateMapping(selectedOperation);
    } else {
      newMapping(selectedOperation);
    }
    setSelectedOperation(null);
  };

  const deleteIt = () => {
    deleteMapping(selectedOperation);
    setSelectedOperation(null);
  };

  const mapSaName = (operation) => {
    if (Array.isArray(operation.saName) && operation.saName.includes('meta')) {
      return `${operation.saName[0]}.${operation.operation.staticParams[0]}`;
    }
    return operation.saName.join(', ');
  };

  const fieldOptions = [
    <SelectOptionGroup key="internal" name="Internal Names">
      {possibleInternalFields.map((inf) => (
        <SelectOption key={inf.name} value={inf.name}>
          {inf.name}
        </SelectOption>
      ))}
    </SelectOptionGroup>,
    <SelectOptionGroup key="external" name="External Names">
      {externalNames.map((en) => (
        <SelectOption key={en} value={`xtrnl ${en}`}>
          {en}
        </SelectOption>
      ))}
    </SelectOptionGroup>,
  ];

  const justSaved = (mapping) => {
    return mapping._id === updatedMapping?.updateImportMapping._id;
  };
  return (
    <div className={classes.wrap}>
      <div className={classes.heading}>
        <h4>Operations</h4>
        {selectedOperation ? (
          <span className={classes.cancel} onClick={() => setSelectedOperation(null)}>
            Cancel
          </span>
        ) : (
          <NewButton onClick={createOperation}>New</NewButton>
        )}
      </div>
      <div className={classes.window}>
        <div className={`${classes.slider} ${selectedOperation ? 'open' : ''}`}>
          <div className={classes.list}>
            {operations.length === 0 && (
              <div className={classes.newOperationMessage}>
                Click <NewButton onClick={createOperation}>New</NewButton> to create an operation
              </div>
            )}
            {operations.map((op) => (
              <div
                key={op._id}
                className={`${classes.bubble} ${justSaved(op) ? classes.justSaved : ''} ${op.importErrors.length > 0 ? classes.rowError : ''}`}
                onClick={() => editOperation(op)}
              >
                {mapSaName(op)}
              </div>
            ))}
          </div>
          <div className={classes.single}>
            {selectedOperation && (
              <div>
                <Select
                  label="Internal name(s)"
                  search
                  multiple
                  selectedItems={listToSelected(selectedOperation.saName)}
                  onChange={(values) => setDropdownValue('saName', values, true)}
                  containerClassName={classes.fieldWidth}
                >
                  {possibleInternalFields.map((field) => (
                    <SelectOption key={field.name} value={field.name}>
                      {field.name}
                    </SelectOption>
                  ))}
                </Select>
                <TextInput
                  type="number"
                  labelText="Order"
                  value={selectedOperation.order || 0}
                  onChange={(value) => setValue('order', parseInt(value, 10))}
                  className={classes.textFieldWidth}
                />
                <Select
                  label="Operator"
                  search
                  selectedItems={listToSelected([selectedOperation.operation.operator])}
                  onChange={(value) => setOperationDropdownValue('operator', value)}
                  containerClassName={classes.fieldWidth}
                >
                  {listToOptions(possibleOperators)}
                </Select>
                {selectedOperation.operation.params?.map((param, index) => (
                  <div className={classes.paramDisplay} key={index}>
                    <div className={classes.selectHolder}>
                      <Select
                        key={param}
                        label="Parameter"
                        search
                        selectedItems={listToSelected([param])}
                        onChange={(value) => setOperationDropdownValue('params', value, index)}
                      >
                        {fieldOptions}
                      </Select>
                    </div>
                    <Button
                      resting={true}
                      icon={CancelIcon}
                      className={classes.cancelButton}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteOperationField('params', index);
                      }}
                    />
                  </div>
                ))}
                {selectedOperation.operation.staticParams?.map((param, index) => (
                  <div className={classes.paramDisplay} key={index}>
                    <div className={classes.selectHolder}>
                      <TextInput
                        key={index}
                        type="text"
                        labelText="Static Parameter"
                        value={param}
                        onChange={(value) => setOperationValue('staticParams', value.trim(), index)}
                      />
                    </div>
                    <Button
                      resting={true}
                      icon={CancelIcon}
                      className={classes.cancelButton}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteOperationField('staticParams', index);
                      }}
                    />
                  </div>
                ))}
                <div className={classes.newParams}>
                  <NewButton naked onClick={addParam}>
                    Parameter
                  </NewButton>
                  <NewButton naked onClick={addStaticParam}>
                    Static Parameter
                  </NewButton>
                </div>
                <div className={classes.buttons}>
                  {selectedOperation._id && (
                    <Button warning onClick={deleteIt}>
                      Delete
                    </Button>
                  )}
                  <Button onClick={saveIt} disabled={!isOperationValid(selectedOperation)}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ETLOperations.propTypes = {
  classes: PropTypes.object,
  operations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      operation: PropTypes.object,
      saName: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  possibleInternalFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  externalNames: PropTypes.arrayOf(PropTypes.string),
  possibleOperators: PropTypes.arrayOf(PropTypes.string),
  selectedOperationId: PropTypes.string,
  updateMapping: PropTypes.func,
  deleteMapping: PropTypes.func,
  newMapping: PropTypes.func,
  updatedMapping: PropTypes.object,
};

export default injectSheet(styles)(ETLOperations);
