import { createUseStyles } from 'react-jss';
import { Theme } from '@spoiler-alert/ui-library';

export const useStyles = createUseStyles(
  {
    table: {
      width: '100%',
    },
    grid: ({ border }) => ({
      border: border ? `1px solid ${Theme.tableBorderColor}` : 'none',
      borderTop: 'none',
    }),
    rowAction: {},
    bodyCell: {
      fontSize: '0.875rem',
      color: Theme.tableTextColor,
      border: 'none',
      margin: 0,
      position: 'relative',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      minWidth: 6,
      boxSizing: 'border-box',
      '&:first-child': {
        marginLeft: 10,
      },
      '&.fixed-width $cellInner': {
        minWidth: 'auto',
        textOverflow: 'unset',
      },
      '&.collapsed': {
        '&>:not($dragHandle)': {
          display: 'none',
        },
        '&>$dragHandle': {
          display: 'flex',
        },
      },
    },
    cellInner: {
      overflow: 'hidden',
      padding: '0 8px 0',
      textOverflow: 'ellipsis',
      '&>span[data-element=status-badge]': {
        display: 'inline-block',
      },
    },
    headerColumn: {
      extend: 'bodyCell',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: 0,
      height: 60,
      textAlign: 'left',
      whiteSpace: 'normal',
      textTransform: 'none',
      fontWeight: '500',
      overflow: 'hidden',
      color: Theme.tableHeaderTextColor,
      margin: 0,
      verticalAlign: 'middle',
      '&.ReactVirtualized__Table__sortableHeaderColumn': {
        cursor: 'pointer',
      },
      '&:has(>$dragHandleActive)': {
        backgroundColor: Theme.grey10,
      },
    },
    headerTruncated: {
      padding: '0 8px 0 8px',
      alignSelf: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    headerTruncatedWithSort: {
      extend: 'headerTruncated',
      paddingRight: 4,
    },
    dragHandle: {
      flex: '0 0 6px',
      width: 6,
      marginLeft: 'auto',
      zIndex: '2',
      cursor: 'col-resize',
      display: 'none',
      position: 'absolute',
      height: '100%',
      color: Theme.greyDark,
      right: 0,
      '&:hover': {
        backgroundColor: Theme.grey30,
      },
    },
    dragHandleActive: {
      color: Theme.white,
      zIndex: 3,
      backgroundColor: Theme.grey80,
      '&:hover': {
        backgroundColor: Theme.grey80,
      },
    },
    dragHandleIcon: {
      flex: '0 0 5px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: `1px solid ${Theme.tableBorderColor}`,
      '&:hover': {
        backgroundColor: Theme.tableRowHoverBgColor,
        '& $dragHandle': {
          display: 'flex',
        },
      },
      '&:not(:hover) $rowAction': {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        fill: Theme.grey80,
      },
    },
    selectedRow: {
      extend: 'row',
      backgroundColor: Theme.green30,
      '&:hover': {
        backgroundColor: Theme.green50,
      },
    },
    clickableRow: {
      cursor: 'pointer',
    },
    stickyRow: ({ stickyOffset, border }) => ({
      position: 'sticky',
      top: stickyOffset || 0,
      backgroundColor: Theme.white,
      zIndex: 1,
      [border ? 'border' : 'borderBottom']: `1px solid ${Theme.tableBorderColor}`,
    }),
    noRowsMessage: {
      width: '100%',
      minHeight: 400,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rowActionContainer: {
      display: 'flex',
    },
  },
  { name: 'VTable' }
);
