import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Table, Row, Col, LoadingLine, ErrorBoundary } from '@spoiler-alert/ui-library';
import NewButton from '../import-manager/new-button';
import MultilineHeader from './multiline-header';

const styles = {
  tableContainer: {
    position: 'relative',
    width: '100%',
  },
  newCol: {},
  table: {
    '& tr>td': {
      padding: 8,
      '&$newCol': {
        padding: [15, 8, 17, 24],
      },
    },
    marginBottom: '24px',
  },
  overflowTable: {
    overflow: 'visible',
  },
};

const MultilineHeadersTable = ({ classes, exportProfile, mutations, possibleColors, loading, site }) => {
  const [editingExportProfile, setEditingExportProfile] = useState(() => ({
    ...exportProfile,
    multilineHeaders: exportProfile.multilineHeaders.map((multilineHeader) => {
      const headerStyles = { ...multilineHeader.headerStyles } || {};
      const columnStyles = { ...multilineHeader.columnStyles } || {};
      const headerType = multilineHeader.headerType || 'Input';
      if (headerType === 'Info' && !multilineHeader.headerStyles?.color) headerStyles.color = 'Snow';
      if (headerType === 'Input' && !multilineHeader.headerStyles?.color) headerStyles.color = 'Bumblebee';
      if (headerType === 'Input' && !multilineHeader.columnStyles?.color) columnStyles.color = 'Snow';
      return { ...multilineHeader, headerType, headerStyles, columnStyles };
    }),
  }));

  const [savedHeader, setSavedHeader] = useState('');

  useEffect(() => {
    setEditingExportProfile({
      ...exportProfile,
      multilineHeaders: exportProfile.multilineHeaders.map((multilineHeader) => {
        const headerStyles = { ...multilineHeader.headerStyles } || {};
        const columnStyles = { ...multilineHeader.columnStyles } || {};
        const headerType = multilineHeader.headerType || 'Input';
        if (headerType === 'Info' && !multilineHeader.headerStyles?.color) headerStyles.color = 'Snow';
        if (headerType === 'Input' && !multilineHeader.headerStyles?.color) headerStyles.color = 'Bumblebee';
        if (headerType === 'Input' && !multilineHeader.columnStyles?.color) columnStyles.color = 'Snow';
        return { ...multilineHeader, headerType, headerStyles, columnStyles };
      }),
    });
  }, [exportProfile]);

  const updateMultilineHeader = (multilineHeader) => {
    editingExportProfile.multilineHeaders = editingExportProfile.multilineHeaders.map((mlh) => {
      const updatedHeader = mlh._id === multilineHeader._id || mlh.order === multilineHeader.order ? { ...multilineHeader } : { ...mlh };
      updatedHeader.headerStyles = { ...updatedHeader.headerStyles, __typename: undefined };
      updatedHeader.columnStyles = { ...updatedHeader.columnStyles, __typename: undefined };
      return { ...updatedHeader, __typename: undefined };
    });
    setEditingExportProfile({ ...editingExportProfile });
    setSavedHeader(multilineHeader._id);
    mutations.updateExportProfile({
      variables: {
        exportProfile: {
          ...editingExportProfile,
          mappings: undefined,
          __typename: undefined,
        },
      },
    });
  };

  const createMultilineHeader = (multilineHeader) => {
    multilineHeader.order = editingExportProfile.multilineHeaders.length
      ? editingExportProfile.multilineHeaders[editingExportProfile.multilineHeaders.length - 1].order + 1
      : 0;
    if (!editingExportProfile.multilineHeaders) editingExportProfile.multilineHeaders = [];
    const updatedHeaders = [...editingExportProfile.multilineHeaders];
    updatedHeaders.push(multilineHeader);
    editingExportProfile.multilineHeaders = updatedHeaders;
    editingExportProfile.multilineHeaders = editingExportProfile.multilineHeaders.map((mlh) => {
      const headerStyles = { ...mlh.headerStyles, __typename: undefined };
      const columnStyles = { ...mlh.columnStyles, __typename: undefined };
      return { ...mlh, headerStyles, columnStyles, __typename: undefined };
    });
    setEditingExportProfile({ ...editingExportProfile });
    setSavedHeader(multilineHeader._id);
    mutations.updateExportProfile({
      variables: {
        exportProfile: {
          ...editingExportProfile,
          mappings: undefined,
          __typename: undefined,
        },
      },
    });
  };

  const createNewMultilineHeader = () => createMultilineHeader({});

  const deleteMultilineHeader = (multilineHeader) => {
    editingExportProfile.multilineHeaders = editingExportProfile.multilineHeaders.filter(
      (mlh) => mlh._id !== multilineHeader._id || mlh.order !== multilineHeader.order
    );
    editingExportProfile.multilineHeaders = editingExportProfile.multilineHeaders.map((mlh) => {
      const headerStyles = { ...mlh.headerStyles, __typename: undefined };
      const columnStyles = { ...mlh.columnStyles, __typename: undefined };
      return { ...mlh, headerStyles, columnStyles, __typename: undefined };
    });
    setEditingExportProfile({ ...editingExportProfile });
    mutations.updateExportProfile({
      variables: {
        exportProfile: {
          ...editingExportProfile,
          mappings: undefined,
          __typename: undefined,
        },
      },
    });
  };

  const updateOrder = (list) => {
    editingExportProfile.multilineHeaders = list;
    editingExportProfile.multilineHeaders = editingExportProfile.multilineHeaders.map((mlh) => {
      const headerStyles = { ...mlh.headerStyles, __typename: undefined };
      const columnStyles = { ...mlh.columnStyles, __typename: undefined };
      return { ...mlh, headerStyles, columnStyles, __typename: undefined };
    });
    setEditingExportProfile(editingExportProfile);
    mutations.updateExportProfile({
      variables: {
        exportProfile: {
          ...editingExportProfile,
          mappings: undefined,
          __typename: undefined,
        },
      },
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const temp = { ...result[startIndex], order: result[endIndex].order };
    result[startIndex] = { ...result[endIndex], order: result[startIndex].order };
    result[endIndex] = temp;
    return result;
  };

  const onUp = (i) => {
    const sortedRows = reorder(editingExportProfile.multilineHeaders, i, i - 1);
    updateOrder(sortedRows);
  };

  const onDown = (i) => {
    const sortedRows = reorder(editingExportProfile.multilineHeaders, i, i + 1);
    updateOrder(sortedRows);
  };

  const isMarketplaceOrStorefront = site.siteName.toLowerCase() === 'spoiler alert marketplace' || site.siteType?.toLowerCase() === 'storefront';

  return (
    <ErrorBoundary>
      <div className={classes.tableContainer}>
        <LoadingLine loading={loading} />
        <Table className={classes.table} containerClassName={classes.overflowTable}>
          {[
            <Row key="first row" header>
              <Col></Col>
              <Col header>Label</Col>
              {isMarketplaceOrStorefront && <Col header>Link To Storefront</Col>}
              <Col header>Type</Col>
              <Col header>Cell Merge Count</Col>
              <Col header>Colors</Col>
              <Col header></Col>
            </Row>,
            ...editingExportProfile.multilineHeaders.map((m, i) => (
              <MultilineHeader
                required
                key={m._id}
                multilineHeader={m}
                possibleColors={possibleColors}
                deleteMultilineHeader={deleteMultilineHeader}
                updateMultilineHeader={updateMultilineHeader}
                onUp={onUp}
                onDown={onDown}
                position={i}
                first={i === 0}
                last={i === editingExportProfile.multilineHeaders.length - 1}
                justSaved={m._id === savedHeader}
                marketplace={isMarketplaceOrStorefront}
              />
            )),
            <Row key="last row">
              <Col className={classes.newCol} colspan={9}>
                <NewButton onClick={createNewMultilineHeader}>New</NewButton>
              </Col>
            </Row>,
          ]}
        </Table>
      </div>
    </ErrorBoundary>
  );
};

MultilineHeadersTable.propTypes = {
  classes: PropTypes.object,
  exportProfile: PropTypes.object,
  mutations: PropTypes.object,
  possibleColors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hex: PropTypes.string,
    })
  ),
  site: PropTypes.object,
  loading: PropTypes.bool,
};

export default injectSheet(styles)(MultilineHeadersTable);
