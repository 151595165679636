import { gql } from '@apollo/client';

// Fragments
const MAPPING_FIELDS = gql`
  fragment MappingFields on ExportMapping {
    _id
    exportProfileId
    label
    widthChars
    field
    formatter
    formatterArg
    formula
    cellType
    hide
    order
    locked
    headerStyles {
      color
    }
    columnStyles {
      color
    }
  }
`;

const MULTILINE_HEADER_FIELDS = gql`
  fragment MultilineHeaderFields on MultilineHeader {
    _id
    label
    linkToStorefront
    merges
    headerType
    order
    headerStyles {
      color
    }
    columnStyles {
      color
    }
  }
`;

const EXPORT_PROFILE_FIELDS = gql`
  ${MULTILINE_HEADER_FIELDS}
  fragment ExportProfileFields on ExportProfile {
    _id
    siteId
    exportType
    sheetWriterClass
    dateFormat
    splitSheetsBySupplierSite
    listName
    multilineHeaders {
      ...MultilineHeaderFields
    }
  }
`;

// Queries
export const CUSTOMERS_AND_EXPORT_PROFILES = gql`
  ${MULTILINE_HEADER_FIELDS}
  query getSitesAndExportProfiles {
    exportManagerQuery {
      _id
      allSellerOrgs {
        _id
        siteName
        exportProfiles {
          _id
          siteId
          exportType
          sheetWriterClass
          dateFormat
          splitSheetsBySupplierSite
          listName
          multilineHeaders {
            ...MultilineHeaderFields
          }
        }
      }
      possibleSheetWriters
    }
  }
`;

export const EXPORT_PROFILE_FIELDS_QUERY = gql`
  ${MAPPING_FIELDS}
  ${MULTILINE_HEADER_FIELDS}
  query getFieldsByExportProfileId($exportProfileId: String) {
    exportManagerQuery {
      _id
      exportProfile(exportProfileId: $exportProfileId) {
        _id
        mappings {
          ...MappingFields
        }
        multilineHeaders {
          ...MultilineHeaderFields
        }
        possibleInternalFields {
          name
          type
        }
        possibleOfferExportFormatters
        possibleAwardExportFormatters
        possibleColors {
          name
          hex
        }
      }
    }
  }
`;

// Mutations
export const CREATE_OFFER_EXPORT_PROFILE_MUTATION = gql`
  ${EXPORT_PROFILE_FIELDS}
  ${MAPPING_FIELDS}
  mutation createOfferExportProfile($exportProfile: ExportProfileInputType) {
    createOfferExportProfile(exportProfile: $exportProfile) {
      ...ExportProfileFields
      mappings {
        ...MappingFields
      }
    }
  }
`;

export const CREATE_AWARD_EXPORT_PROFILE_MUTATION = gql`
  ${EXPORT_PROFILE_FIELDS}
  ${MAPPING_FIELDS}
  mutation createAwardExportProfile($exportProfile: ExportProfileInputType) {
    createAwardExportProfile(exportProfile: $exportProfile) {
      ...ExportProfileFields
      mappings {
        ...MappingFields
      }
    }
  }
`;

export const CREATE_MARKETPLACE_AUCTION_EXPORT_PROFILE_MUTATION = gql`
  ${EXPORT_PROFILE_FIELDS}
  ${MAPPING_FIELDS}
  mutation createMarketplaceAuctionExportProfile($exportProfile: ExportProfileInputType) {
    createMarketplaceAuctionExportProfile(exportProfile: $exportProfile) {
      ...ExportProfileFields
      mappings {
        ...MappingFields
      }
    }
  }
`;

export const UPDATE_EXPORT_PROFILE_MUTATION = gql`
  ${EXPORT_PROFILE_FIELDS}
  mutation updateExportProfile($exportProfile: ExportProfileInputType) {
    updateExportProfile(exportProfile: $exportProfile) {
      ...ExportProfileFields
    }
  }
`;

export const UPDATE_EXPORT_MAPPING_MUTATION = gql`
  ${MAPPING_FIELDS}
  mutation updateExportMapping($exportMapping: ExportMappingInputType) {
    updateExportMapping(exportMapping: $exportMapping) {
      ...MappingFields
    }
  }
`;

export const UPDATE_MAPPINGS_ORDER_MUTATION = gql`
  ${MAPPING_FIELDS}
  mutation updateExportMappingsOrder($rows: RowsInputType) {
    updateExportMappingsOrder(rows: $rows) {
      _id
      mappings {
        ...MappingFields
      }
    }
  }
`;

export const CREATE_EXPORT_MAPPING_MUTATION = gql`
  ${MAPPING_FIELDS}
  mutation createExportMapping($exportMapping: ExportMappingInputType) {
    createExportMapping(exportMapping: $exportMapping) {
      _id
      mappings {
        ...MappingFields
      }
    }
  }
`;

export const DELETE_EXPORT_MAPPING_MUTATION = gql`
  mutation deleteExportMapping($exportMapping: ExportMappingInputType) {
    deleteExportMapping(exportMapping: $exportMapping) {
      _id
      mappings {
        _id
      }
    }
  }
`;
