import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Table, Row, Col, LoadingLine } from '@spoiler-alert/ui-library';
import ETLDefault from './etl-default';
import NewButton from './new-button';

const styles = {
  wrap: {
    padding: [24, 0, 0],
  },
  heading: {
    margin: [0, 0, 0, 0],
  },
  tableContainer: {
    position: 'relative',
    marginTop: 5,
  },
  newCol: {},
  table: {
    '& tr>td': {
      padding: 8,
      '&$newCol': {
        padding: [15, 8, 17, 24],
      },
    },
  },
};

const ETLDefaultsTable = ({ classes, defaults, newDefault, loadings, updatedDefault, ...rest }) => {
  const createNewDefault = () => newDefault({});
  return (
    <div className={classes.wrap}>
      <h4 className={classes.heading}>Defaults</h4>
      <div className={classes.tableContainer}>
        <LoadingLine loading={loadings.creatingDefault || loadings.savingDefault || loadings.deletingDefault} />
        <Table className={classes.table}>
          {[
            <Row key="header" header>
              <Col header>Set Default for</Col>
              <Col header>Type</Col>
              <Col header>Lookup item</Col>
              <Col header>Static Default</Col>
              <Col header></Col>
            </Row>,
            ...defaults.map((d) => <ETLDefault key={d._id} etlDefault={d} justSaved={d._id === updatedDefault?.updateImportDefault._id} {...rest} />),
            <Row key="new">
              <Col className={classes.newCol} colspan={8}>
                <NewButton onClick={createNewDefault}>New</NewButton>
              </Col>
            </Row>,
          ]}
        </Table>
      </div>
    </div>
  );
};

ETLDefaultsTable.propTypes = {
  classes: PropTypes.object,
  defaults: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ),
  newDefault: PropTypes.func,
  loadings: PropTypes.object,
  updatedDefault: PropTypes.object,
};

export default injectSheet(styles)(ETLDefaultsTable);
