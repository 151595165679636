import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Select } from '@spoiler-alert/ui-library';

const MemorySelect = ({ cacheId, onChange, children: selectOptions, ...rest }) => {
  const cacheAddress = `MemorySelect::${cacheId}`;

  const [hasUpdatedValue, setHasUpdatedValue] = useState(false);

  const memoryOnChange = (items, item) => {
    onChange(items, item);
    if (items.length === 0) {
      localStorage.removeItem(cacheAddress);
    } else {
      const _items = items.map(({ value }) => value);
      const cacheObject = {
        selected: _items,
        storedAt: Date.now(),
      };
      localStorage.setItem(cacheAddress, JSON.stringify(cacheObject));
    }
  };

  useEffect(() => {
    if (!hasUpdatedValue) {
      const options = React.Children.toArray(selectOptions);
      const json = localStorage.getItem(cacheAddress);
      if (options.length > 0 && json) {
        const { selected, storedAt } = JSON.parse(json);
        const dateDiff = Date.now() - storedAt;
        const aWeek = 1000 * 60 * 60 * 24 * 7;
        if (selected?.length > 0 && dateDiff < aWeek) {
          const items = [];
          const values = [...selected];
          options.forEach((child) => {
            if (child.props.value && values.includes(child.props.value)) {
              const { children: text, value, category } = child.props;
              items.push({ text, value, category });
            }
          });
          onChange(items, items[0]);
        }
        setHasUpdatedValue(true);
      }
    }
  }, [selectOptions, hasUpdatedValue]);

  return (
    <Select onChange={memoryOnChange} {...rest}>
      {selectOptions}
    </Select>
  );
};

MemorySelect.propTypes = {
  cacheId: PropTypes.string.isRequired,
  children: PropTypes.node,
  selectedItems: PropTypes.array,
  selectedItem: PropTypes.object,
  onChange: PropTypes.func,
};

export default MemorySelect;
