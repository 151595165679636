import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Button, Select, SelectOption, LoadingLine, Modal, ModalContent, ModalFooter, TextInput } from '@spoiler-alert/ui-library';

const useStyles = createUseStyles({
  siteSelect: {
    width: 300,
  },
  modalContent: {
    width: 652,
  },
  hint: {
    fontSize: '0.6rem',
    marginTop: -5,
  },
  drillWrap: {
    position: 'relative',
    top: -10,
  },
});

const EditDashboardModal = ({
  handleHideModal,
  editingDashboard,
  loadingDrills,
  creating,
  updateValue,
  saveDashboard,
  drillThruOptions,
  siteSelectOptions,
}) => {
  const classes = useStyles();

  const drillSelectOptions = useMemo(() => {
    return drillThruOptions.map((option) => (
      <SelectOption checkbox key={option.dashboardId} value={option.dashboardId}>
        {option.name}
      </SelectOption>
    ));
  }, [drillThruOptions]);

  const noAvailableDrills = !loadingDrills && drillSelectOptions.length === 0 && editingDashboard?.dashboardId;

  return (
    <Modal onHide={handleHideModal} open={!!editingDashboard} closeOnEsc closeOnOutsideClick>
      <ModalContent>
        <div className={classes.modalContent}>
          <h2>{editingDashboard?._id ? 'Edit Dashboard' : 'Create Dashboard'}</h2>
          {!editingDashboard?._id && (
            <TextInput
              className={classes.textField}
              onChange={updateValue.bind(this, 'dashboardId')}
              value={editingDashboard?.dashboardId || ''}
              type="number"
              labelText="Holistics Dashboard ID"
              required
            />
          )}
          {editingDashboard?._id && (
            <TextInput
              className={classes.textField}
              onChange={updateValue.bind(this, 'name')}
              value={editingDashboard?.name || ''}
              type="text"
              labelText="Dashboard Name"
              required
            />
          )}
          <Select
            placeholderText="Default for Location?"
            onChange={updateValue.bind(this, 'defaultForLocation')}
            selectedItems={editingDashboard?.defaultForLocation || []}
          >
            <SelectOption value="">None</SelectOption>
            <SelectOption value="DASHBOARD_TAB">Dashboard tab</SelectOption>
            <SelectOption value="REPORTS_TAB">Reports tab</SelectOption>
            <SelectOption value="OFFER_VIZ">Offer viz popup</SelectOption>
          </Select>
          <p className={classes.hint}>Should this report show up as the default report on one of the reporting pages?</p>
          <Select
            placeholderText="Report Type"
            onChange={updateValue.bind(this, 'dashboardType')}
            selectedItems={editingDashboard?.dashboardType || []}
          >
            <SelectOption value="CORE">CORE</SelectOption>
            <SelectOption value="CUSTOM">CUSTOM</SelectOption>
          </Select>
          <p className={classes.hint}>If Custom, you&rsquo;ll need to choose which sellers can view it.</p>
          {editingDashboard?.dashboardType?.length > 0 && editingDashboard.dashboardType[0].value === 'CUSTOM' && (
            <Select
              multiple
              search
              placeholderText="Sites with access"
              onChange={updateValue.bind(this, 'sellerSiteIds')}
              selectedItems={editingDashboard?.sellerSiteIds || []}
            >
              {siteSelectOptions}
            </Select>
          )}
          <Select
            multiple
            selectAll
            disabled={noAvailableDrills || loadingDrills}
            search={drillSelectOptions.length > 5}
            placeholderText={loadingDrills ? 'Loading available drills...' : 'Available Drillthroughs'}
            onChange={updateValue.bind(this, 'drillthroughs')}
            selectedItems={editingDashboard?.drillthroughs || []}
          >
            {drillSelectOptions}
          </Select>
          <div className={classes.drillWrap}>
            <LoadingLine loading={loadingDrills} />
          </div>
          <p className={classes.hint}>
            {noAvailableDrills ? (
              <>There are no available drillthroughs for this dashboard</>
            ) : (
              <>These dashboards are available as drillthroughs for this report.</>
            )}
          </p>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={handleHideModal} link>
          Cancel
        </Button>
        <Button type="submit" disabled={creating} primary loading={creating} loadingText="Saving..." onClick={saveDashboard}>
          {editingDashboard?._id ? 'Save Changes' : 'Create'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const SelectOptionList = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })
);

EditDashboardModal.propTypes = {
  editingDashboard: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    drillthroughs: SelectOptionList,
    sellerSiteIds: SelectOptionList,
    dashboardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dashboardType: SelectOptionList,
    defaultForLocation: SelectOptionList,
  }),
  drillThruOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dashboardId: PropTypes.number,
    })
  ),
  handleHideModal: PropTypes.func,
  loadingDrills: PropTypes.bool,
  creating: PropTypes.bool,
  updateValue: PropTypes.func,
  saveDashboard: PropTypes.func,
  siteSelectOptions: PropTypes.arrayOf(PropTypes.node),
};

export default EditDashboardModal;
