import { makeAutoObservable } from 'mobx';
class BreadcrumbsStore {
    constructor() {
        this.breadcrumbs = [{ url: '/', title: 'Home' }];
        makeAutoObservable(this);
    }
    set(crumbs) {
        this.breadcrumbs = crumbs;
    }
}
export default BreadcrumbsStore;
