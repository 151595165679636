import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Switch, Route } from 'react-router-dom';
import { MarketplaceStorefrontsQuery } from './marketplace-queries';
import StorefrontList from './storefront-list';
import routePaths from '../../route-paths';
import StorefrontDetails from './storefront-details';
import StorefrontSKUs from './storefront-skus';

const MarketplaceManager = () => {
  const { data, loading: loadingStorefronts } = useQuery(MarketplaceStorefrontsQuery);
  const storefronts = data?.adminMarketplaceQuery || [];

  const bread = [
    {
      url: '/marketplace',
      title: 'Marketplace Manager',
    },
  ];

  return (
    <div>
      <Switch>
        <Route
          exact
          path={routePaths.marketplaceManager}
          render={(routeProps) => <StorefrontList bread={bread} storefronts={storefronts} loadingStorefronts={loadingStorefronts} {...routeProps} />}
        />
        <Route
          exact
          path={`${routePaths.marketplaceManager}/:storefrontId`}
          render={(routeProps) => (
            <StorefrontDetails bread={bread} storefronts={storefronts} loadingStorefronts={loadingStorefronts} {...routeProps} />
          )}
        />
        <Route
          path={`${routePaths.marketplaceManager}/:storefrontId/skus`}
          render={(routeProps) => <StorefrontSKUs bread={bread} storefronts={storefronts} loadingStorefronts={loadingStorefronts} {...routeProps} />}
        />
      </Switch>
    </div>
  );
};

MarketplaceManager.propTypes = {
  history: PropTypes.object,
};

export default MarketplaceManager;
