import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import QuillEditor from './quill-editor';

const useStyles = createUseStyles(
  {
    editor: {
      position: 'relative',
      zIndex: 100,
    },
  },
  { name: 'HTMLEditor' }
);

const HTMLEditor = ({ onChange, defaultValue, className, disabled, placeholder }) => {
  const quillRef = useRef();
  const classes = useStyles();

  return (
    <div className={`${className} ${classes.editor}`}>
      <QuillEditor ref={quillRef} defaultValue={defaultValue} onTextChange={onChange} readOnly={disabled} placeholder={placeholder} />
    </div>
  );
};

HTMLEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default HTMLEditor;
