import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'mobx-react';
import { JssProvider } from 'react-jss';
import client from './apollo/client';
import stackdriverErrorReporter from './logging/stackdriver-error-reporter';
import SiteContext from './site-context';
import { rootStoreInstance, Breadcrumbs } from './store';
import AppSettings from './app-settings';

ReactGA.initialize(AppSettings.GOOGLE_ANALYTICS_ID);

let classIdNum = 0;
const generateClassNames = (rule, sheet) => {
  classIdNum += 1;
  const componentName = sheet.options.meta
    .split(',')[0]
    .replace(/Apollo\((.+)\)/, '$1')
    .replace(/inject-with-\w+Store\((\w+)\)/, '$1') // For components using OLD mobx pattern. Replace inject('NAME') with inject((store) => store) variant
    .replace(/inject\((\w+)\)/, '$1'); // For components using NEW mobx pattern. (The functional variant of `inject`. See docs for more info.)
  return `${componentName}-${rule.key}-${classIdNum}`;
};

export default class App extends Component {
  render() {
    stackdriverErrorReporter.start();
    return (
      <ApolloProvider client={client}>
        <SiteContext />
      </ApolloProvider>
    );
  }
}

render(
  <Provider rootStore={rootStoreInstance} breadcrumbsStore={Breadcrumbs}>
    <JssProvider generateClassName={generateClassNames}>
      <App />
    </JssProvider>
  </Provider>,
  document.getElementById('app')
);

if (typeof module.hot !== 'undefined') {
  module.hot.accept(); // eslint-disable-line no-undef
}
