import React from 'react';
import { SelectOption } from '@spoiler-alert/ui-library';

export const listToSelected = (list) =>
  Array.isArray(list)
    ? list.map((l) => {
        if (l?.includes('meta')) return { value: l.split('.')[0], text: l };
        return { value: l, text: l };
      })
    : [];

export const listToOptions = (list, checkbox = false) =>
  list.map((l) => (
    <SelectOption checkbox={checkbox} key={l} value={l}>
      {l}
    </SelectOption>
  ));

export const cleanModel = (model) => {
  const newModel = JSON.parse(JSON.stringify(model));
  newModel.__typename = undefined;
  newModel.importErrors = undefined;
  Object.keys(newModel).forEach((propName) => {
    if (
      typeof newModel[propName] === 'object' &&
      !Array.isArray(newModel[propName]) &&
      newModel[propName] !== null &&
      newModel[propName].__typename
    ) {
      newModel[propName].__typename = undefined;
    }
  });
  return newModel;
};

export const SAVE_DELAY = 500;
