import React from 'react';
import PropTypes from 'prop-types';

export default class EnvelopeFolded extends React.Component {
  render() {
    return (
      <svg className={this.props.className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
        <path
          id="Folded"
          d="M4,12.2l7.5,4.7c0.2,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l7.5-4.7l2-1.2c0-1.1-0.9-2-2-2H4 c-1.1,0-2,0.9-2,2L4,12.2z M18.1,11L12,14.8L5.9,11H18.1z"
        />
      </svg>
    );
  }
}

EnvelopeFolded.propTypes = {
  className: PropTypes.string,
};
