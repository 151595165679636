import { gql } from '@apollo/client';

export const AllSellerSiteNamesAndIdsQuery = gql`
  query allSellerSiteNamesAndIdsQuery {
    allSellerSiteNamesAndIdsQuery {
      _id
      siteName
    }
  }
`;

export const ExcludedValuesQuery = gql`
  query excludedValuesQuery($siteId: String!, $exclusionType: String!) {
    excludedValuesQuery(siteId: $siteId, exclusionType: $exclusionType) {
      _id
      siteId
      siteName
      exclusionType
      excludedValues
    }
  }
`;
