import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import AppSettings from '../app-settings';

const graphqlUrl = `${AppSettings.GRAPHQL_SERVER_URL}graphql`;

const fetcher = (input, init) => window.fetch(input, init);

const requestLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    onError(({ networkError }) => {
      if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
        localStorage.removeItem('token');
      }
    }),
    requestLink,
    createHttpLink({
      uri: graphqlUrl,
      fetch: fetcher,
    }),
  ]),
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});

export default client;
