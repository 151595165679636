import { fetchUnauthed } from './fetch-with-token';
import AppSettings from '../app-settings';

class InviteService {
  getInvite(token) {
    const inviteUrl = `${AppSettings.GRAPHQL_SERVER_URL}invite/${token}`;
    return fetchUnauthed(inviteUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  acceptInvite(inviteId, password, profile) {
    const inviteUrl = `${AppSettings.GRAPHQL_SERVER_URL}invite`;
    const body = JSON.stringify({
      inviteId,
      password,
      profile,
    });
    return fetchUnauthed(inviteUrl, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

const inviteService = new InviteService();
export default inviteService;
