import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { LoadingLarge } from '@spoiler-alert/ui-library';
import { useQuery } from '@apollo/client';
import routePaths from '../../route-paths';
import { MarketplaceSKUQuery } from './marketplace-queries';
import SKUDetails from './sku-details';
import StorefrontSKUList from './storefront-sku-list';

const StorefrontSKUs = ({ bread, match, storefronts, loadingStorefronts }) => {
  const storefront = storefronts?.find((s) => s._id === match.params.storefrontId);
  const facilityIds = storefront?.facilities.map((s) => s._id);
  const { data, loading } = useQuery(MarketplaceSKUQuery, {
    variables: { facilityIds },
    skip: !storefront,
  });

  const breadcrumbs = useMemo(
    () => [
      ...bread,
      {
        url: `${routePaths.marketplaceManager}/${storefront?._id}/skus`,
        title: `SKUS for ${storefront?.siteName}`,
      },
    ],
    [storefront]
  );

  const tagList = useMemo(() => {
    const userTags = localStorage.getItem('userTags:v1')?.split(',');
    const tagSet = new Set(userTags);
    data?.adminMarketplaceSKUQuery.forEach((sku) => sku.tags?.forEach((t) => tagSet.add(t)));
    return [...tagSet];
  }, [data?.adminMarketplaceSKUQuery]);

  if (loadingStorefronts || !storefront) return <LoadingLarge />;
  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${routePaths.marketplaceManager}/:storefrontId/skus`}
          render={(routeProps) => (
            <StorefrontSKUList
              bread={breadcrumbs}
              storefront={storefront}
              loadingSKUList={loading}
              skuList={data?.adminMarketplaceSKUQuery || []}
              {...routeProps}
            />
          )}
        />
        <Route
          exact
          path={`${routePaths.marketplaceManager}/:storefrontId/skus/:sku`}
          render={(routeProps) => (
            <SKUDetails
              bread={breadcrumbs}
              storefront={storefront}
              skus={data?.adminMarketplaceSKUQuery}
              loadingSKUs={loading}
              facilityIds={facilityIds}
              tagList={tagList}
              {...routeProps}
            />
          )}
        />
      </Switch>
    </div>
  );
};

StorefrontSKUs.propTypes = {
  bread: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
  loadingStorefronts: PropTypes.bool,
  storefronts: PropTypes.array,
};

export default StorefrontSKUs;
