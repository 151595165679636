import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Table, Row, Col, LoadingLine, ErrorBoundary } from '@spoiler-alert/ui-library';
import NewButton from '../import-manager/new-button';
import DataTableColumn from './data-table-column';

const styles = {
  tableContainer: {
    position: 'relative',
    width: '100%',
  },
  newCol: {},
  table: {
    '& tr>td': {
      padding: 8,
      '&$newCol': {
        padding: [15, 8, 17, 24],
      },
    },
    marginBottom: '150px',
  },
};

const DataTableColumnsTable = ({ classes, columns, possibleFields, loading, editingColumns, tableType, setErrorList }) => {
  const [activeColumns, setActiveColumns] = useState(() => columns.map((c) => ({ ...c, key: Math.random() })));

  useEffect(() => {
    editingColumns.current = activeColumns;
    const errorList = [];
    if (activeColumns.length === 0) {
      errorList.push('Data Table Profile cannot be empty. Please contact engineering if you wish to delete this profile.');
    }
    activeColumns.forEach((c) => {
      if (!c.field) {
        errorList.push('Internal Name is required');
      }
    });
    setErrorList(errorList);
  }, [activeColumns]);

  const createColumn = (dataTableColumn) => {
    const updatedColumns = [...activeColumns, dataTableColumn];
    setActiveColumns(updatedColumns);
  };
  const createNewColumn = () => createColumn({ key: Math.random(), visible: true, sortable: true, defaultSort: false });

  const updateColumn = (editedColumn, position) => {
    const updatedColumns = [...activeColumns];
    updatedColumns[position] = editedColumn;
    setActiveColumns(updatedColumns);
  };

  const deleteColumn = (position) => {
    const updatedColumns = activeColumns.filter((c, i) => i !== position);
    setActiveColumns(updatedColumns);
  };

  const clearFormerSortPreferences = (position, value) => {
    const updatedColumns = activeColumns.map((c, i) => {
      if (i !== position) {
        c.defaultSort = false;
        c.defaultSortDirection = null;
      } else {
        c.defaultSort = value;
      }
      return c;
    });
    setActiveColumns(updatedColumns);
  };

  const onUp = (i) => {
    if (i > 0) {
      const targetI = i - 1;
      const temp = activeColumns[targetI];
      activeColumns[targetI] = activeColumns[i];
      activeColumns[i] = temp;
      setActiveColumns([...activeColumns]);
    }
  };

  const onDown = (i) => {
    if (i < activeColumns.length) {
      const targetI = i + 1;
      const temp = activeColumns[targetI];
      activeColumns[targetI] = activeColumns[i];
      activeColumns[i] = temp;
      setActiveColumns([...activeColumns]);
    }
  };

  return (
    <ErrorBoundary>
      <div className={classes.tableContainer}>
        <LoadingLine loading={loading} />
        <Table className={classes.table}>
          {[
            <Row key="first row" header>
              <Col></Col>
              <Col header>Label</Col>
              <Col header>Internal Name</Col>
              <Col header>Formatter</Col>
              <Col header>Default Sort</Col>
              <Col header>Sort Direction</Col>
              <Col header></Col>
            </Row>,
            ...activeColumns.map((c, i) => (
              <DataTableColumn
                required
                key={c.key}
                column={c}
                possibleFields={possibleFields}
                onUp={onUp}
                onDown={onDown}
                position={i}
                first={i === 0}
                last={i === activeColumns.length - 1}
                updateColumn={updateColumn}
                deleteColumn={deleteColumn}
                clearFormerSortPreferences={clearFormerSortPreferences}
                tableType={tableType}
              />
            )),
            <Row key="last row">
              <Col className={classes.newCol} colspan={9}>
                <NewButton onClick={createNewColumn}>New</NewButton>
              </Col>
            </Row>,
          ]}
        </Table>
      </div>
    </ErrorBoundary>
  );
};

DataTableColumnsTable.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array,
  possibleFields: PropTypes.array,
  loading: PropTypes.bool,
  editingColumns: PropTypes.object,
  tableType: PropTypes.string,
  setErrorList: PropTypes.func,
};

export default injectSheet(styles)(DataTableColumnsTable);
