import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TypeAhead, TextInput, Button, AlertService } from '@spoiler-alert/ui-library';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { SiteNameTypeAheadQuery } from '../../graphql/queries';
import { CreateBuyerXToken } from '../../graphql/mutations';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';

const useStyles = createUseStyles({
  panels: {
    display: 'flex',
  },
  panel: {
    width: '33%',
    padding: [0, 20, 20, 0],
  },
  buttons: {
    display: 'flex',
    marginBottom: '2em',
    '&>button': {
      marginRight: 10,
    },
  },
  urls: {
    wordBreak: 'break-all',
  },
});

const BuyerPortalAccess = ({ user }) => {
  const classes = useStyles();
  const [sellerSite, setSellerSite] = useState(null);
  const [buyerSite, setBuyerSite] = useState(null);
  const [emailAddress, setEmailAddress] = useState(user.email);
  const [userToken, setUserToken] = useState();
  const [serverPrefix, setServerPrefix] = useState();

  const [createBuyerToken] = useMutation(CreateBuyerXToken);

  useEffect(() => {
    TitleService.setTitles('Buyer Portal Access');
    Breadcrumbs.set([
      {
        url: '/buyerx-access',
        title: 'Buyer Portal Access',
      },
    ]);
  }, []);

  const getToken = () => {
    return createBuyerToken({
      variables: {
        sellerSiteId: sellerSite._id,
        buyerSiteId: buyerSite._id,
        emailAddress,
      },
    })
      .then((result) => {
        const { error } = result.data.createBuyerToken;
        if (error) throw new Error(error);
        return result.data.createBuyerToken;
      })
      .catch((err) => AlertService.alert({ type: 'warning', message: <span>{err.message}</span> }));
  };

  const generateToken = () => {
    getToken().then(({ token, pathPrefix }) => {
      setUserToken(token);
      setServerPrefix(pathPrefix);
    });
  };

  const buyerXURL = userToken ? `${serverPrefix}${userToken}` : '';
  const marketplaceURL = userToken ? `${serverPrefix}auctions/${userToken}` : '';

  return (
    <>
      <div className={classes.panels}>
        <div className={classes.panel}>
          <TypeAhead
            query={SiteNameTypeAheadQuery}
            queryName="siteNameTypeAheadQuery"
            labelText={'Seller Site Name *'}
            onChange={(res) => setSellerSite(res.result)}
            itemLimit={200}
            value={sellerSite ? sellerSite.siteName : ''}
            queryVariables={{ siteRole: 'SELLER' }}
            autoWidth
          />
        </div>
        <div className={classes.panel}>
          <TypeAhead
            query={SiteNameTypeAheadQuery}
            queryName="siteNameTypeAheadQuery"
            labelText={'Buyer Site Name *'}
            onChange={(res) => setBuyerSite(res.result)}
            itemLimit={200}
            value={buyerSite ? buyerSite.siteName : ''}
            queryVariables={{ siteRole: 'BUYER' }}
            autoWidth
          />
        </div>
        <div className={classes.panel}>
          <TextInput labelText="Buyer User Email" value={emailAddress} onChange={(value) => setEmailAddress(value)} />
        </div>
      </div>
      <div>
        <p>
          Token: {sellerSite ? sellerSite.siteName : 'None'} + {buyerSite ? buyerSite.siteName : 'None'} + {emailAddress}
        </p>
        {sellerSite && buyerSite && emailAddress && (
          <div className={classes.buttons}>
            <Button onClick={generateToken}>Generate Token</Button>
          </div>
        )}
        {userToken && (
          <div className={classes.urls}>
            BuyerX:{' '}
            <a href={buyerXURL} target="_blank" rel="noreferrer">
              {buyerXURL}
            </a>
            <br />
            Marketplace:{' '}
            <a href={marketplaceURL} target="_blank" rel="noreferrer">
              {marketplaceURL}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

BuyerPortalAccess.propTypes = {
  user: PropTypes.object,
};

export default BuyerPortalAccess;
