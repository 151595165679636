import React from 'react';
import PropTypes from 'prop-types';

export default class EnvelopeBottom extends React.Component {
  render() {
    return (
      <svg className={this.props.className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
        <path
          id="EnvelopeBottom"
          d="M20.9,10l-8.2,5h-1.4l-8.2-5C2.8,9.9,2,10,2,11v9c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2v-9 C22,10,21.2,9.9,20.9,10z M4,20v-8.1l6.7,4.1h2.6l6.7-4.1l0,8.1H4z"
        />
      </svg>
    );
  }
}

EnvelopeBottom.propTypes = {
  className: PropTypes.string,
};
