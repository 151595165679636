import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import {
  RowAction,
  DataTableNaked,
  Column,
  ColorBadge,
  ChangeQuantityIcon,
  ClearListIcon,
  AlertService,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  TextInput,
  Select,
  SelectOption,
  Checkbox,
  Tooltip,
  TypeAhead,
  Theme as theme,
} from '@spoiler-alert/ui-library';
import { RemindersQuery, SiteRoleTypeAheadQuery } from '../../graphql/queries';
import { SaveReminderMutation } from '../../graphql/mutations';

const useStyles = createUseStyles({
  reminderWrap: {
    marginTop: 30,
  },
  tableWrap: {
    border: `1px ${theme.grey30} solid`,
  },
  createNew: {
    display: 'inline-block',
  },
  toggleInput: {
    display: 'flex',
    paddingTop: 10,
    '&>span': {
      lineHeight: '18px',
      cursor: 'pointer',
    },
  },
  toggleInputCheckbox: {
    display: 'inline-block',
    marginRight: 10,
  },
  inputRowHalf: {
    display: 'flex',
  },
  numberInputField: {
    width: 60,
    marginRight: 15,
  },
});

const offsetUnits = [
  {
    value: 'days',
    text: 'Days',
  },
  {
    value: 'hours',
    text: 'Hours',
  },
  {
    value: 'minutes',
    text: 'Minutes',
  },
];

const siteRoles = { BUYER: 'BUYER', SELLER: 'SELLER' };

const ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
};

const RemindersManager = () => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [editingReminder, setEditingReminder] = useState(null);
  const [userSelectedSellerSite, setUserSelectedSellerSite] = useState(null);
  const { data: remindersQuery, loading: loadingReminders } = useQuery(RemindersQuery);
  const { data, loading: loadingSites } = useQuery(SiteRoleTypeAheadQuery, {
    variables: {
      typeAhead: {
        itemLimit: 1,
        siteRole: siteRoles.SELLER,
        search: editingReminder?.sellerSiteName,
      },
    },
    skip: !editingReminder?.sellerSiteName || userSelectedSellerSite,
  });
  const [saveReminder, { loading: savingReminder }] = useMutation(SaveReminderMutation);

  const loading = loadingReminders || loadingSites || savingReminder;

  const reminders = remindersQuery?.getRemindersQuery.list || [];

  const reminderTypes = useMemo(() => {
    return remindersQuery?.getRemindersQuery.reminderTypes.map((rt) => ({ text: rt, value: rt })) || [];
  }, [remindersQuery?.getRemindersQuery.reminderTypes]);

  const currentSellerSite = useMemo(() => {
    if (userSelectedSellerSite) {
      return userSelectedSellerSite;
    }
    if (data?.siteRoleTypeAheadQuery[0]?.result) {
      return data.siteRoleTypeAheadQuery[0].result;
    }
    return null;
  }, [data, userSelectedSellerSite]);

  const distributionListOptions = useMemo(() => {
    if (currentSellerSite) {
      return currentSellerSite.distributionLists.map(({ _id, name }) => ({ _id, name }));
    }
    return [];
  }, [currentSellerSite]);

  const openEditor = (reminder) => {
    if (reminder) {
      setEditingReminder({ ...reminder });
    } else {
      setEditingReminder({
        sellerSiteId: '',
        sellerSiteName: '',
        distributionListId: '',
        distributionListName: '',
        offsetValue: 1,
        offsetUnit: '',
        enabled: false,
        reminderType: '',
      });
    }
    setModalOpen(true);
  };

  const closeEditor = () => {
    setEditingReminder(null);
    setUserSelectedSellerSite(null);
    setModalOpen(false);
  };

  const handleDelete = (row) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this reminder?')) {
      saveReminder({
        variables: {
          action: ACTIONS.DELETE,
          fields: {
            _id: row._id,
          },
        },
        refetchQueries: [RemindersQuery],
      })
        .then((response) => {
          if (response?.data?.saveReminder.errors) {
            throw new Error(response.data.saveReminder.errors[0].message);
          }
        })
        .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
    }
  };

  const reminderEnablement = useMemo(() => {
    return {
      disabled: (
        <Tooltip key="disabled" className={classes.erroredTooltip} small bottom text="This reminder won't run next time you Publish staged listings">
          <ColorBadge theme={theme.badgeColors.red}>DISABLED</ColorBadge>
        </Tooltip>
      ),
      enabled: (
        <Tooltip key="enabled" className={classes.erroredTooltip} small bottom text="This reminder will run next time you Publish staged listings">
          <ColorBadge theme={theme.badgeColors.green}>ENABLED</ColorBadge>
        </Tooltip>
      ),
    };
  }, []);

  const columns = useMemo(() => {
    return [
      new Column({
        field: 'sellerSiteName',
        displayName: 'Seller Site',
        sortable: true,
        defaultSort: true,
      }),
      new Column({
        field: 'distributionListName',
        displayName: 'Distribution List',
        sortable: true,
      }),
      new Column({
        field: 'offsetValue',
        displayName: 'Offset Before Due Date',
        formatter: (value, row) => `${row.offsetValue} ${row.offsetUnit}`,
        sortable: true,
      }),
      new Column({
        field: 'enabled',
        displayName: 'Enabled',
        formatter: (value) => (value ? reminderEnablement.enabled : reminderEnablement.disabled),
        sortable: true,
      }),
      new Column({
        field: 'reminderType',
        displayName: 'Type',
        sortable: true,
      }),
    ];
  }, []);

  const handleSaveReminder = (event) => {
    event.preventDefault();
    const variables = {
      action: editingReminder._id ? ACTIONS.EDIT : ACTIONS.CREATE,
      fields: {
        _id: editingReminder._id || 'new',
        sellerSiteId: editingReminder.sellerSiteId,
        distributionListId: editingReminder.distributionListId,
        offsetValue: parseFloat(editingReminder.offsetValue),
        offsetUnit: editingReminder.offsetUnit,
        enabled: editingReminder.enabled,
        reminderType: editingReminder.reminderType,
      },
    };
    saveReminder({
      variables,
      refetchQueries: variables.action === ACTIONS.CREATE ? [RemindersQuery] : [],
    })
      .then((response) => {
        if (response?.data?.saveReminder.errors) {
          throw new Error(response.data.saveReminder.errors[0].message);
        }
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
    setEditingReminder(null);
    setUserSelectedSellerSite(null);
    setModalOpen(false);
  };

  const updateReminderValue = (key, value) => {
    const reminder = { ...editingReminder };
    reminder[key] = value;
    setEditingReminder(reminder);
  };

  const updateSite = (site) => {
    const reminder = { ...editingReminder };
    reminder.sellerSiteId = site._id;
    reminder.sellerSiteName = site.siteName;
    setEditingReminder(reminder);
    setUserSelectedSellerSite(site);
  };

  const updateDL = (dl) => {
    const reminder = { ...editingReminder };
    reminder.distributionListId = dl._id;
    reminder.distributionListName = dl.name;
    setEditingReminder(reminder);
  };

  const selectedOffsetUnit = offsetUnits.filter((u) => u.value === editingReminder?.offsetUnit);
  const selectedReminder = reminderTypes.filter((t) => t.value === editingReminder?.reminderType);

  return (
    <div className={classes.reminderWrap}>
      <h4>
        Reminders{' '}
        <Button link className={classes.createNew} onClick={openEditor}>
          Create New
        </Button>
      </h4>
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={reminders}
          filterable="none"
          filterParameters={{}}
          filters={[]}
          loading={loading}
          rowActions={[
            <RowAction
              key="edit"
              tooltipText="Edit Reminder"
              loadingTooltipText="Saving Reminder"
              icon={ChangeQuantityIcon}
              onClick={(row) => openEditor.bind(this, row)}
              secondary
            />,
            <RowAction
              key="delete"
              tooltipText="Delete Reminder"
              loadingTooltipText="Deleting Reminder"
              icon={ClearListIcon}
              onClick={(row) => handleDelete.bind(this, row)}
              warning
            />,
          ]}
          onRowClick={(row) => openEditor(row)}
          columns={columns}
        />
      </div>
      <Modal onHide={closeEditor} open={modalOpen} closeOnEsc closeOnOutsideClick>
        <form onSubmit={handleSaveReminder} className={classes.formContainer}>
          <ModalContent>
            <h2 className={classes.modalHeader}>{editingReminder?._id ? 'Edit' : 'Create'} Reminder</h2>
            <div>
              <TypeAhead
                query={SiteRoleTypeAheadQuery}
                queryName="siteRoleTypeAheadQuery"
                dataFormat={[{ displayText: 'sellerSiteName' }]}
                labelText={'Seller Site'}
                onChange={(choice) => updateSite(choice.result)}
                value={editingReminder?.sellerSiteName}
                itemLimit={20}
                queryVariables={{ siteRole: siteRoles.SELLER }}
                autoWidth
              />
            </div>
            <div>
              <TypeAhead
                dataArray={distributionListOptions}
                dataFormat={[{ displayText: 'name' }]}
                labelText={'Distribution List'}
                onChange={(choice) => updateDL(choice.result)}
                value={editingReminder?.distributionListName}
                disabled={distributionListOptions.length < 1}
                autoWidth
              />
            </div>
            <div className={classes.inputRowHalf}>
              <TextInput
                className={classes.numberInputField}
                onChange={updateReminderValue.bind(this, 'offsetValue')}
                value={editingReminder?.offsetValue}
                type="text"
                labelText="Offset #"
                required
              />
              <Select
                label="Offset Unit"
                onChange={(chosen) => updateReminderValue('offsetUnit', chosen[0].value)}
                required
                selectedItems={selectedOffsetUnit}
              >
                {offsetUnits.map((offset) => (
                  <SelectOption value={offset.value} key={offset.value}>
                    {offset.text}
                  </SelectOption>
                ))}
              </Select>
            </div>
            <div>
              <Select
                label="Reminder Type"
                onChange={(chosen) => updateReminderValue('reminderType', chosen[0].value)}
                required
                selectedItems={selectedReminder}
              >
                {reminderTypes.map((offset) => (
                  <SelectOption value={offset.value} key={offset.value}>
                    {offset.text}
                  </SelectOption>
                ))}
              </Select>
            </div>
            <div className={classes.toggleInput}>
              <Checkbox
                className={classes.toggleInputCheckbox}
                onChecked={(value) => updateReminderValue('enabled', value.checked)}
                checked={editingReminder?.enabled}
              />
              <span onClick={() => updateReminderValue('enabled', !editingReminder?.enabled)}>Enable Reminder?</span>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={closeEditor} secondary>
              Cancel
            </Button>
            <Button type="submit" className={classes.submit} primary>
              Save Reminder
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default RemindersManager;
