import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button } from '@spoiler-alert/ui-library';
import ExportProfileForm from './export-profile-form';

const styles = {
  wrap: {
    height: 'calc(100vh - 116px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '500px',
  },
  heading: {
    textAlign: 'center',
    fontSize: '22px',
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'end',
  },
};

const NewExportProfile = ({ classes, exportType, site, mutations, possibleSheetWriters }) => {
  const createBlankProfile = () => ({
    exportType,
  });

  const [exportProfile, setExportProfile] = useState(() => createBlankProfile());

  useEffect(() => {
    setExportProfile(createBlankProfile());
  }, [exportType, site]);

  const disableCreate = () => {
    if (!exportProfile.sheetWriterClass) return true;
    if (!exportProfile.dateFormat || exportProfile.dateFormat.length === 0) return true;
    return false;
  };

  const createProfile = () => {
    let create;
    if (exportType === 'OFFER SHEET') create = mutations.createOfferExportProfile;
    if (exportType === 'AWARD SHEET') create = mutations.createAwardExportProfile;
    if (exportType === 'MARKETPLACE AUCTION SHEET') create = mutations.createMarketplaceAuctionExportProfile;

    create({
      variables: {
        exportProfile: {
          siteId: site._id,
          exportType,
          sheetWriterClass: exportProfile.sheetWriterClass,
          dateFormat: exportProfile.dateFormat,
          splitSheetsBySupplierSite: exportProfile.splitSheetsBySupplierSite,
        },
      },
    });
  };

  return (
    <div className={classes.wrap}>
      <h2 className={classes.heading}>Create a new export</h2>
      <ExportProfileForm exportProfile={exportProfile} setEditingExportProfile={setExportProfile} possibleSheetWriters={possibleSheetWriters} />
      <div className={classes.buttonWrap}>
        <Button onClick={createProfile} disabled={disableCreate()}>
          Create New
        </Button>
      </div>
    </div>
  );
};

NewExportProfile.propTypes = {
  classes: PropTypes.object,
  exportType: PropTypes.string.isRequired,
  site: PropTypes.object,
  mutations: PropTypes.object,
  possibleSheetWriters: PropTypes.array,
};

export default injectSheet(styles)(NewExportProfile);
