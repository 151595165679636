import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  TextInput,
  TextArea,
  Pill,
  TypeAhead,
  Select,
  DataSource,
  SelectOption,
} from '@spoiler-alert/ui-library';
import { SiteNameTypeAheadQuery, ImportProfileQuery } from '../../graphql/queries';

const styles = {
  modal__title: {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
  input__row: {
    padding: '6px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  form__container: {
    width: '100%',
  },
  input__container: {
    padding: '0 12px',
    flexGrow: 1,
    maxHeight: 400,
    overflow: 'auto',
  },
  input__field: {
    width: '100%',
    '&:first-child': {
      marginRight: 24,
    },
  },
  'input__field--half': {
    width: '50%',
    '&:first-child': {
      marginRight: 24,
    },
  },
};

@graphql(ImportProfileQuery, { options: (props) => ({ variables: { siteId: props.filterSiteId } }) })
@injectSheet(styles)
export default class CustomFilterModal extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onHide: PropTypes.func,
    open: PropTypes.bool,
    filterName: PropTypes.string,
    siteSearch: PropTypes.string,
    filterSiteName: PropTypes.string,
    filterSiteId: PropTypes.string,
    filterId: PropTypes.string,
    query: PropTypes.string,
    saving: PropTypes.bool,
    onRemoveSite: PropTypes.func,
    onAddSite: PropTypes.func,
    onSave: PropTypes.func,
    onSetFilterName: PropTypes.func,
    onSetQuery: PropTypes.func,
    showImportProfile: PropTypes.bool,
    onAddImportProfile: PropTypes.func,
    importProfileId: PropTypes.string,
    setImportProfiles: PropTypes.func,
    importProfiles: PropTypes.array,
    importProfileName: PropTypes.string,
  };

  state = {
    importProfiles: null,
  };

  cancel = () => {
    this.props.onHide(false);
  };

  get siteNameTypeAhead() {
    return (
      <TypeAhead
        query={SiteNameTypeAheadQuery}
        queryName="siteNameTypeAheadQuery"
        labelText={'Site Name *'}
        onChange={this.props.onAddSite}
        itemLimit={10}
        value={this.props.siteSearch}
        resetOnSelection
        autoWidth
      />
    );
  }

  dataReceived = (data) => {
    const { setImportProfiles } = this.props;
    setImportProfiles(data.importProfileQuery);
  };

  get generateImportProfileOptions() {
    const { importProfiles, importProfileId } = this.props;
    if (importProfileId) {
      let ip = importProfiles.find((p) => p._id === importProfileId);
      if (!ip && importProfiles.length === 1) [ip] = importProfiles;
      return ip
        ? [
            <SelectOption value={ip._id} key={ip._id}>
              {ip.importName}
            </SelectOption>,
          ]
        : [];
    }
    return importProfiles.map((profile) => (
      <SelectOption value={profile._id} key={profile._id}>
        {profile.importName}
      </SelectOption>
    ));
  }

  render() {
    const {
      classes,
      filterName,
      open,
      saving,
      filterSiteId,
      filterSiteName,
      onSetFilterName,
      filterId,
      onRemoveSite,
      onSetQuery,
      query,
      onAddImportProfile,
      importProfileId,
      importProfiles,
      importProfileName,
    } = this.props;
    const modalText = filterId ? 'Edit Custom Filter' : 'Add Custom Filter';
    const selectedItem = { value: importProfileId, text: importProfileName };

    return (
      <Modal onHide={this.props.onHide} open={open} closeOnEsc closeOnOutsideClick>
        <form onSubmit={this.props.onSave} className={classes.form__container}>
          <ModalContent>
            <h2 className={classes.modal__title}>{modalText}</h2>
            <div className={classes.input__row}>
              <div className={classes.input__container}>
                <TextInput
                  className={classes.input__field}
                  onChange={onSetFilterName}
                  type="text"
                  labelText="Custom Filter Name *"
                  value={filterName}
                  required
                />
              </div>
            </div>
            <div className={classes.input__row}>
              <div className={classes.input__container}>
                {!filterSiteName && this.siteNameTypeAhead}
                {filterSiteName && (
                  <Pill key={`.pill-${filterSiteId}`} className={classes.input__field} fileName={filterSiteName} onCancel={onRemoveSite} />
                )}
              </div>
            </div>

            {importProfiles && (
              <Select label="Import Profile" onChange={onAddImportProfile} selectedItem={selectedItem}>
                {this.generateImportProfileOptions}
              </Select>
            )}

            {filterSiteId && <DataSource query={ImportProfileQuery} variables={{ siteId: filterSiteId }} onFetch={this.dataReceived} />}

            <div className={classes.input__row}>
              <div className={classes.input__container}>
                <TextArea onChange={onSetQuery} labelText="Enter the query. Use valid JSON *" value={query} required />
              </div>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={this.cancel} link disabled={saving}>
              Cancel
            </Button>
            <Button type="submit" disabled={!filterName || !query || !filterSiteId} primary loading={saving} loadingText="Wait...">
              Save Custom Filter
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
