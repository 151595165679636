import React from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { inject, observer } from 'mobx-react';
import Sidebar from '../components/sidebar';
import MainContent from './main-content';
import Refresher from '../components/refresher';
import UserMenu from './user-menu';
import Breadcrumbs from './breadcrumbs';

const styles = {
  site__container: {
    width: '100%',
    height: '100%',
  },
  site__wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  'site__wrapper--overlay': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    filter: 'blur(5px)',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    paddingLeft: 202,
    backgroundColor: '#fff',
    position: 'relative',
    transitionDuration: '300ms',
    transitionProperty: 'padding-left',
  },
  'mainContent--collapsed': {
    paddingLeft: 70,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  contentBody: {
    position: 'relative',
    padding: '0 16px',
    marginBottom: 30,
  },
  '@media (max-width: 840px)': {
    main__content: {
      paddingLeft: 0,
    },
  },
  siteBarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '.7%',
    alignItems: 'baseline',
  },
};

@injectSheet(styles)
@inject('rootStore')
@observer
export default class InternalLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overlaid: false,
      open: props.rootStore.sidebarStatus.open,
      collapsed: props.rootStore.sidebarStatus.collapsed,
    };
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onOverlayShow = () => {
    if (!this.state.overlaid) this.setState({ overlaid: true });
  };

  onOverlayHide = () => {
    const { rootStore } = this.props;
    if (this.state.overlaid) this.setState({ overlaid: false });
    if (this.state.overlaid) rootStore.setSidebar({ open: false });
  };

  close = () => {
    this.props.rootStore.setSidebar({ collapsed: true });
  };

  open = () => {
    this.props.rootStore.setSidebar({ collapsed: false });
  };

  render() {
    const { user, history, style, children, classes, rootStore } = this.props;
    const wrapperClass = this.state.overlaid ? classes['site__wrapper--overlay'] : classes.site__wrapper;
    const mainClass = `${classes.mainContent}${rootStore.sidebarStatus.collapsed ? ` ${classes['mainContent--collapsed']}` : ''}`;
    if (!user) return null;
    return (
      <div className={classes.site__container} style={style}>
        <div className={wrapperClass}>
          <Sidebar user={user} onClose={this.close} onOpen={this.open}>
            <UserMenu user={user} history={history} />
          </Sidebar>
          <div className={mainClass}>
            <div className={classes.mainContainer}>
              <div className={classes.siteBarWrapper}>
                <Breadcrumbs className={classes.outerBreadCrumbWrapper} />
              </div>
              <div className={classes.contentBody}>
                <MainContent user={user} history={history} content={children} />
              </div>
            </div>
          </div>
        </div>
        <Refresher />
        <Overlay onShow={this.onOverlayShow} onHide={this.onOverlayHide} />
      </div>
    );
  }
}

InternalLayout.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  title: PropTypes.string,
  style: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  rootStore: PropTypes.object,
  children: PropTypes.node,
};
