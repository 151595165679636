import { gql } from '@apollo/client';

// Queries
export const LOOKER_USER_INFO = gql`
  query getLookerUserInfo($email: String) {
    lookerSiteQuery {
      allSellerOrgs {
        _id
        siteName
      }
      lookerUser(email: $email) {
        siteId
        userAttributeId
        userId
        lookerUserEmail
      }
    }
  }
`;

// Mutations

export const UPDATE_LOOKER_USER_ATTRIBUTE_MUTATION = gql`
  mutation updateLookerUserSiteIdAttribute($userId: String!, $userAttributeId: String!, $value: String!) {
    updateLookerUserSiteIdAttribute(userId: $userId, userAttributeId: $userAttributeId, value: $value) {
      userId
      userAttributeId
      value
    }
  }
`;
