import { gql } from '@apollo/client';
import { InventoryDetailFragment } from './inventory-manager-fragments';

export const SaveInventoriesMutation = gql`
  mutation saveInventories($inventories: [SaveInventoryInput]) {
    saveInventories(inventories: $inventories) {
      inventories {
        ...InventoryDetails
      }
      errors {
        code
        message
      }
    }
  }
  ${InventoryDetailFragment}
`;
