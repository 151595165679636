import { buildColumnFormatter } from '@spoiler-alert/ui-library';
import VColumn from './v-column';

export const getVTableColumnsFromDataTableProfile = (profileName, site, columnOverrides = {}) => {
  const profiles = site.dataTableProfiles;
  const profile = profiles.find((d) => d.dataTableName === profileName);
  if (!profile) throw new Error(`No profile found for the ${profileName} table`);
  const columns = {
    visible: [],
    hidden: [],
  };
  profile.columns.forEach((column) => {
    const { field, displayName, visible, sortable, defaultSort, defaultSortDirection } = column;
    const formatter = buildColumnFormatter(column);
    const c = new VColumn({
      field,
      displayName,
      visible,
      sortable,
      defaultSort,
      defaultSortDirection,
      formatter,
      ...columnOverrides[field],
    });
    if (c.visible) {
      columns.visible.push(c);
    } else {
      columns.hidden.push(c);
    }
  });
  return columns;
};

export const getData = (obj, field) => {
  return field.split('.').reduce((o, i) => o && o[i], obj);
};
