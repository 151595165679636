import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
  indicator: {
    padding: 3,
    height: 19,
    width: 19,
    fontSize: '10px',
    marginRight: 8,
    textAlign: 'center',
    borderRadius: 2,
  },
};

const FieldTypeIndicator = ({ classes, text, background, color }) => (
  <div className={classes.indicator} style={{ color, background }}>
    {text}
  </div>
);

FieldTypeIndicator.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
};

export default injectSheet(styles)(FieldTypeIndicator);
