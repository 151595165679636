import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Column, Table } from 'react-virtualized';
import Draggable from 'react-draggable';
import { useKeyPress, LoadingLine } from '@spoiler-alert/ui-library';
import VSorter from './v-sorter';
import { useStyles } from './v-styles';
import { getData } from './columns-helpers';

const initColumnWidths = (localStorageKey, columnList) => {
  const fromCache = JSON.parse(localStorage.getItem(localStorageKey));
  if (!fromCache) return null;
  columnList.forEach((col) => {
    const key = col.dataKey;
    if (!fromCache[key] || fromCache[key] <= 0 || fromCache[key] >= 1) {
      fromCache[key] = 1 / columnList.length;
    }
  });
  return fromCache;
};

const VInnerTable = ({
  data: searchedTableData,
  columns,
  profileName,
  userSite,
  onRowClick,
  highlightRowId,
  noDataMessage,
  keyboardNavigation,
  loading,
  width,
  height,
  onChildScroll,
  isScrolling,
  scrollTop,
  border,
  sticky,
  stickyOffset,
}) => {
  const minimumColumnSize = 6;
  const maximumColumnSize = 400;
  const classes = useStyles({ sticky, stickyOffset, border });

  const localStorageKey = `columnWidths.v1::${userSite._id}::${profileName}`;

  const [columnWidths, setColumnWidths] = useState(initColumnWidths.bind(this, localStorageKey, columns));
  const [keyLocked, setKeyLocked] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(columns.find((col) => col.defaultSort));
  const [sortDirection, setSortDirection] = useState(columns.find((col) => col.defaultSort).defaultSortDirection);

  const handleSort = useCallback(
    ({ sortBy, sortDirection: _sortDirection }) => {
      let _lowerSortDirection = _sortDirection.toLowerCase();
      if (_lowerSortDirection === sortDirection) {
        if (sortDirection === 'asc') _lowerSortDirection = 'desc';
        else _lowerSortDirection = 'asc';
      }
      if (sortedColumn.dataKey !== sortBy) {
        setSortedColumn(columns.find((col) => col.dataKey === sortBy));
      }
      setSortDirection(_lowerSortDirection);
    },
    [columns, sortDirection, sortedColumn]
  );

  useEffect(() => {
    if (columns.length > 0 && columnWidths === null) {
      const autoSizeColumns = columns.filter((col) => !col.width);
      const _width = 1 / autoSizeColumns.length;
      const widths = {};
      autoSizeColumns.forEach((c) => {
        widths[c.dataKey] = _width;
      });
      setColumnWidths(widths);
    }
  }, [columns?.length]);

  useEffect(() => {
    if (columnWidths && columns) {
      localStorage.setItem(localStorageKey, JSON.stringify(columnWidths));
    }
  }, [columnWidths, localStorageKey]);

  const sortedTableData = useMemo(() => {
    searchedTableData.sort((a, b) => {
      let first;
      let second;
      if (sortedColumn.sortField && typeof sortedColumn.sortField === 'function') {
        first = sortedColumn.sortField(a);
        second = sortedColumn.sortField(b);
      } else {
        first = getData(a, sortedColumn.sortField ? sortedColumn.sortField : sortedColumn.field);
        second = getData(b, sortedColumn.sortField ? sortedColumn.sortField : sortedColumn.field);
      }
      if (first < second) return sortDirection === 'asc' ? -1 : 1;
      if (first > second) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
    return searchedTableData;
  }, [searchedTableData, sortDirection, sortedColumn]);

  const highlightIndex = useMemo(() => {
    return sortedTableData.findIndex((row) => row._id === highlightRowId);
  }, [sortedTableData, highlightRowId]);

  const upKeyPressed = useKeyPress('ArrowUp', keyboardNavigation);
  const downKeyPressed = useKeyPress('ArrowDown', keyboardNavigation);

  useEffect(() => {
    if (keyboardNavigation) {
      if (keyLocked && !upKeyPressed && !downKeyPressed) setKeyLocked(false);
      if (!keyLocked && (upKeyPressed || downKeyPressed)) {
        setKeyLocked(true);
        if (upKeyPressed && highlightIndex > 0) onRowClick(sortedTableData[highlightIndex - 1]);
        if (downKeyPressed && highlightIndex + 1 < sortedTableData.length) onRowClick(sortedTableData[highlightIndex + 1]);
      }
    }
  }, [sortedTableData, upKeyPressed, downKeyPressed, keyboardNavigation, keyLocked, highlightIndex, onRowClick]);

  const fixedWidthColumnOffset = useMemo(() => {
    return columns.reduce((sum, col) => {
      if (col.width) return sum + col.width;
      return sum;
    }, 0);
  }, [columns]);

  const resizeRow = ({ dataKey, deltaX }) => {
    const availableWidthPx = width - fixedWidthColumnOffset;
    const percentDelta = deltaX / availableWidthPx;
    const maximumColumnWidthPercent = maximumColumnSize / availableWidthPx;
    const minimumColumnWidthPercent = minimumColumnSize / availableWidthPx;
    const newWidths = {
      ...columnWidths,
      [dataKey]: Math.min(Math.max(columnWidths[dataKey] + percentDelta, minimumColumnWidthPercent), maximumColumnWidthPercent),
    };
    setColumnWidths(newWidths);
  };

  const handleDrag = (dataKey, event, { deltaX }) => {
    event.stopPropagation();
    resizeRow({
      dataKey,
      deltaX,
    });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection: _sortDirection }) => {
    const sorted = sortBy === columnData.dataKey;
    return (
      <React.Fragment key={dataKey}>
        <div className={sorted ? classes.headerTruncatedWithSort : classes.headerTruncated}>{label}</div>
        {!disableSort && columnData.sortable && <VSorter sorted={sorted} direction={_sortDirection} />}
        {columnData.calculatedResizable && (
          <Draggable
            axis="x"
            defaultClassName={classes.dragHandle}
            defaultClassNameDragging={classes.dragHandleActive}
            onDrag={handleDrag.bind(this, dataKey)}
            position={{ x: 0 }}
            zIndex={999}
          >
            <div onClick={(e) => e.stopPropagation()} title={columnData.calculatedWidth === minimumColumnSize ? label : undefined}>
              <span className={classes.dragHandleIcon}>⋮</span>
            </div>
          </Draggable>
        )}
      </React.Fragment>
    );
  };

  const cellRenderer = ({ columnData, rowData }) => {
    const rawValue = rowData[columnData.field];
    const value = columnData.formatter ? columnData.formatter(rawValue, rowData) : rawValue;
    let content = value;
    if (typeof value === 'string') content = <span title={value}>{value}</span>;
    return (
      <div className={classes.cellInner} style={{ width: columnData.calculatedWidth }}>
        {content}
      </div>
    );
  };

  const noRowsRenderer = () => {
    return (
      <div className={classes.noRowsMessage}>
        {loading && <LoadingLine loading={loading} />}
        {noDataMessage}
      </div>
    );
  };

  const renderedColumns = useMemo(() => {
    if (columnWidths !== null) {
      const lastColumn = columns[columns.length - 1];
      const adjustLastColumn = !!lastColumn.width;
      const lastColumnOffset = adjustLastColumn ? lastColumn.width : 0;
      const colCount = columns.length;
      let sumWidth = 0;
      columns.forEach((col) => {
        let columnWidth = col.width || Math.floor((width - fixedWidthColumnOffset) * columnWidths[col.dataKey]);
        if (!col.width && sumWidth + columnWidth + lastColumnOffset + 20 > width) {
          columnWidth = Math.max(width - sumWidth - lastColumnOffset - 20, minimumColumnSize);
        }
        col.calculatedWidth = columnWidth;
        sumWidth += columnWidth;
        return true;
      });
      sumWidth = 0;
      return columns.map((col, index) => {
        col.calculatedResizable = col.resizable;
        let extraClasses = `${col.width ? 'fixed-width' : ''}`;
        if (adjustLastColumn && index === colCount - 2) {
          col.calculatedWidth = Math.max(width - sumWidth - lastColumn.width - 20, minimumColumnSize);
          col.calculatedResizable = false;
        }
        if (col.calculatedWidth === minimumColumnSize) extraClasses = `${extraClasses} collapsed`;
        sumWidth += col.calculatedWidth;
        const cellStyle = { overflow: col.width ? 'visible' : 'hidden', ...col.style };

        return (
          <Column
            key={col.dataKey}
            headerRenderer={headerRenderer}
            columnData={col}
            disableSort={!col.sortable}
            cellRenderer={cellRenderer}
            dataKey={col.dataKey}
            label={col.displayName}
            flexShrink={0}
            width={col.calculatedWidth}
            headerClassName={`${classes.headerColumn} ${extraClasses}`}
            className={`${classes.bodyCell} ${extraClasses}`}
            style={cellStyle}
          />
        );
      });
    }
    return [];
  }, [columns, columnWidths, width, fixedWidthColumnOffset]);

  const handleRowClick = ({ rowData }) => onRowClick(rowData);

  const getRowClassNameFromIndex = ({ index }) => {
    const clickability = onRowClick && index >= 0 ? classes.clickableRow : '';
    const selected = index >= 0 && index === highlightIndex ? classes.selectedRow : classes.row;
    const stickyHeaderRow = index === -1 && sticky ? classes.stickyRow : '';
    return `${selected} ${clickability} ${stickyHeaderRow}`;
  };

  return (
    <Table
      autoHeight
      height={height}
      width={width}
      scrollToIndex={highlightIndex}
      scrollToAlignment="center"
      sort={handleSort}
      sortBy={sortedColumn.dataKey}
      sortDirection={sortDirection.toUpperCase()}
      headerHeight={60}
      rowHeight={52}
      overscanRowCount={20}
      onScroll={onChildScroll}
      isScrolling={isScrolling}
      scrollTop={scrollTop}
      rowCount={sortedTableData.length}
      rowGetter={({ index }) => sortedTableData[index]}
      noRowsRenderer={noRowsRenderer}
      onRowClick={onRowClick ? handleRowClick : undefined}
      rowClassName={getRowClassNameFromIndex}
      gridClassName={classes.grid}
      rowStyle={{ overflow: 'visible' }}
    >
      {renderedColumns}
    </Table>
  );
};

VInnerTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  profileName: PropTypes.string,
  userSite: PropTypes.object,
  onRowClick: PropTypes.func,
  highlightRowId: PropTypes.string,
  noDataMessage: PropTypes.node,
  keyboardNavigation: PropTypes.bool,
  cypressTagTable: PropTypes.string,
  loading: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onChildScroll: PropTypes.func,
  isScrolling: PropTypes.bool,
  scrollTop: PropTypes.number,
  border: PropTypes.bool,
  sticky: PropTypes.bool,
  stickyOffset: PropTypes.number,
};

export default VInnerTable;
