import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Table, Row, Col, LoadingLine, ErrorBoundary } from '@spoiler-alert/ui-library';
import NewButton from '../import-manager/new-button';
import ExportMapping from './export-mapping';

const styles = {
  tableContainer: {
    position: 'relative',
    width: '100%',
  },
  newCol: {},
  table: {
    '& tr>td': {
      padding: 8,
      '&$newCol': {
        padding: [15, 8, 17, 24],
      },
    },
    marginBottom: '150px',
  },
};

const ExportMappingsTable = ({
  classes,
  exportProfile,
  mutations,
  mappings,
  possibleInternalFields,
  possibleFormatters,
  possibleColors,
  updatedMapping,
  loading,
}) => {
  const [editingMappings, setEditingMappings] = useState(() =>
    mappings.map((mapping) => {
      const headerStyles = { ...mapping.headerStyles } || {};
      const columnStyles = { ...mapping.columnStyles } || {};
      if (!mapping.headerStyles?.color) headerStyles.color = 'Smoke';
      if (!mapping.columnStyles?.color) columnStyles.color = 'Snow';
      return { ...mapping, headerStyles, columnStyles };
    })
  );

  useEffect(() => {
    setEditingMappings(
      mappings.map((mapping) => {
        const headerStyles = { ...mapping.headerStyles } || {};
        const columnStyles = { ...mapping.columnStyles } || {};
        if (!mapping.headerStyles?.color) headerStyles.color = 'Smoke';
        if (!mapping.columnStyles?.color) columnStyles.color = 'Snow';
        return { ...mapping, headerStyles, columnStyles };
      })
    );
  }, [mappings]);

  const updateMapping = (exportMapping) => {
    // update all fields except order
    delete exportMapping.order;
    mutations.updateExportMapping({
      variables: { exportMapping },
    });
  };

  const createMapping = (exportMapping) => {
    exportMapping.exportProfileId = exportProfile._id;
    exportMapping.order = editingMappings.length ? editingMappings[editingMappings.length - 1].order + 1 : 0;
    mutations.createExportMapping({ variables: { exportMapping } });
  };

  const createNewMapping = () => createMapping({});

  const deleteMapping = (exportMapping) => {
    mutations.deleteExportMapping({
      variables: { exportMapping },
    });
  };

  const updateOrder = (list) => {
    mutations.updateMappingsOrder({
      variables: { rows: { exportProfileId: exportProfile._id, rowsData: list } },
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const temp = { ...result[startIndex], order: result[endIndex].order };
    result[startIndex] = { ...result[endIndex], order: result[startIndex].order };
    result[endIndex] = temp;
    return result;
  };

  const onUp = (i) => {
    const sortedRows = reorder(editingMappings, i, i - 1);
    const cleanRows = sortedRows.map((m) => ({ _id: m._id, order: m.order }));
    updateOrder(cleanRows);
  };

  const onDown = (i) => {
    const sortedRows = reorder(editingMappings, i, i + 1);
    const cleanRows = sortedRows.map((m) => ({ _id: m._id, order: m.order }));
    updateOrder(cleanRows);
  };

  return (
    <ErrorBoundary>
      <div className={classes.tableContainer}>
        <LoadingLine loading={loading} />
        <Table className={classes.table}>
          {[
            <Row key="first row" header>
              <Col></Col>
              <Col header>Label</Col>
              <Col header>Cell type</Col>
              <Col header>Column width</Col>
              <Col header>Internal name</Col>
              <Col header>Formatter</Col>
              <Col header>Argument</Col>
              <Col header>Formula</Col>
              <Col header>Colors</Col>
              <Col header></Col>
            </Row>,
            ...editingMappings.map((m, i) => (
              <ExportMapping
                required
                key={m._id}
                mapping={m}
                possibleInternalFields={possibleInternalFields}
                possibleFormatters={possibleFormatters}
                possibleColors={possibleColors}
                deleteMapping={deleteMapping}
                updateMapping={updateMapping}
                onUp={onUp}
                onDown={onDown}
                position={i}
                first={i === 0}
                last={i === editingMappings.length - 1}
                justSaved={m._id === updatedMapping?.updateExportMapping._id}
              />
            )),
            <Row key="last row">
              <Col className={classes.newCol} colspan={9}>
                <NewButton onClick={createNewMapping}>New</NewButton>
              </Col>
            </Row>,
          ]}
        </Table>
      </div>
    </ErrorBoundary>
  );
};

ExportMappingsTable.propTypes = {
  classes: PropTypes.object,
  exportProfile: PropTypes.object,
  mutations: PropTypes.object,
  mappings: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      order: PropTypes.number,
    })
  ),
  possibleInternalFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  possibleFormatters: PropTypes.array,
  possibleColors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hex: PropTypes.string,
    })
  ),
  updatedMapping: PropTypes.objectOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
};

export default injectSheet(styles)(ExportMappingsTable);
