import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Card, Button } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { BulkRevokeAdminInvite } from '../../../graphql/mutations';

const styles = {
  button__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
};

@injectSheet(styles)
@graphql(BulkRevokeAdminInvite)
export default class RevokeInviteAction extends Component {
  state = {
    invite: this.props.invite,
    revoking: false,
  };

  static getDerivedStateFromProps(nextProps) {
    return { invite: nextProps.invite };
  }

  error = (response) => {
    this.setState({ revoking: false });
    const message =
      'Sorry there was an error revoking this invite. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    this.props.onHide(response, message);
  };

  hide = (response) => {
    this.setState({ revoking: false });
    this.props.onHide(response);
  };

  revoke = () => {
    const { refetchQueries, mutate } = this.props;
    this.setState({ revoking: true });
    mutate({
      variables: { inviteIds: [this.state.invite._id] },
      refetchQueries,
    })
      .then(this.hide)
      .catch(this.error);
  };

  render() {
    return (
      <Card>
        <h3>Revoke Invite</h3>
        <p>Are you sure you want to revoke this invite? This action cannot be undone.</p>
        <div className={this.props.classes.button__container}>
          <Button type="button" link onClick={this.hide} disabled={this.state.revoking}>
            Cancel
          </Button>
          <Button type="button" primary onClick={this.revoke} loading={this.state.revoking} loadingText="Wait...">
            Revoke
          </Button>
        </div>
      </Card>
    );
  }
}

RevokeInviteAction.propTypes = {
  classes: PropTypes.object,
  invite: PropTypes.object,
  onHide: PropTypes.func,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};

RevokeInviteAction.defaultProps = {
  onHide: () => {},
};
