import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  sorter__container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    right: '6px',
    height: '18px',
    marginTop: '-9px',
    left: '12px',
  },
  sorter__uparrow: {
    height: '0px',
    width: '0px',
    border: '5.5px solid transparent',
    borderTopWidth: 0,
    borderBottomColor: '#bfc1c3',
    borderBottomWidth: '9px',
    marginBottom: '4px',
    cursor: 'pointer',
    '&:hover': {
      borderBottomColor: theme.sorterActiveColor,
    },
  },
  'sorter__uparrow--disabled': {
    borderBottomColor: '#f2f2f3',
    '&:hover': {
      borderBottomColor: '#f2f2f3',
    },
    cursor: 'not-allowed',
  },
  sorter__downarrow: {
    height: '0px',
    width: '0px',
    border: '5.5px solid transparent',
    borderBottomWidth: 0,
    borderTopColor: '#bfc1c3',
    borderTopWidth: '9px',
    cursor: 'pointer',
    '&:hover': {
      borderTopColor: theme.sorterActiveColor,
    },
  },
  'sorter__downarrow--disabled': {
    borderTopColor: '#f2f2f3',
    '&:hover': {
      borderTopColor: '#f2f2f3',
    },
    cursor: 'not-allowed',
  },
};

const RowOrderer = ({ classes, onUp, onDown, position, first, last }) => {
  const upArrowClass = `${classes.sorter__uparrow}${first ? ` ${classes['sorter__uparrow--disabled']}` : ''}`;
  const downArrowClass = `${classes.sorter__downarrow}${last ? ` ${classes['sorter__downarrow--disabled']}` : ''}`;

  return (
    <span className={classes.sorter__container} data-element="sorter-container">
      <span data-testid="sorter-up" className={upArrowClass} data-element="sorter-up" onClick={first ? undefined : () => onUp(position)}></span>
      <span
        data-testid="sorter-down"
        className={downArrowClass}
        data-element="sorter-down"
        onClick={last ? undefined : () => onDown(position)}
      ></span>
    </span>
  );
};

RowOrderer.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  classes: PropTypes.object,
  position: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
};

export default injectSheet(styles)(RowOrderer);
