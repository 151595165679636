import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import { Button, AlertService, compose } from '@spoiler-alert/ui-library';
import { startTransactionCycle, endTransactionCycle, togglePauseTransactionCycle } from '../../graphql/mutations';

const styles = {
  cycle_wrap: {
    display: 'flex',
    alignItems: 'center',
    margin: [-8, 0, -12, 0],
  },
  cycle_description: {
    margin: [0, 10],
  },
  confirm_button: {
    marginRight: 10,
  },
};

@injectSheet(styles)
@compose(
  graphql(startTransactionCycle, { name: 'startTransactionCycle' }),
  graphql(endTransactionCycle, { name: 'endTransactionCycle' }),
  graphql(togglePauseTransactionCycle, { name: 'togglePauseTransactionCycle' })
)
export default class TransactionCycle extends Component {
  static propTypes = {
    site: PropTypes.object,
    classes: PropTypes.object,
    endTransactionCycle: PropTypes.func,
    startTransactionCycle: PropTypes.func,
    togglePauseTransactionCycle: PropTypes.func,
  };

  state = {
    showConfirm: false,
  };

  confirm = () => {
    const { site } = this.props;
    let mutation = this.props.endTransactionCycle;
    let start = false;
    if (!site.transactionCycle) {
      mutation = this.props.startTransactionCycle;
      start = true;
    }
    mutation({ variables: { siteId: site._id } })
      .then(() => {
        AlertService.alert({
          type: 'success',
          message: `Transaction cycle has been successfully ${start ? 'started' : 'ended'}!`,
          autoDismiss: true,
          dismissDelay: 5000,
        });
        this.setState({ showConfirm: false });
      })
      .catch(() =>
        AlertService.alert({
          type: 'warning',
          message: `There was a problem ${start ? 'staring' : 'ending'} the transaction cycle for ${site.siteName}.`,
        })
      );
  };

  togglePauseCycle = (site) => {
    this.props
      .togglePauseTransactionCycle({ variables: { siteId: site._id } })
      .then(() => {
        AlertService.alert({
          type: 'success',
          message: `Transaction cycle has been successfully ${site.transactionCycle.status === 'ACTIVE' ? 'paused' : 'resumed'}!`,
          autoDismiss: true,
          dismissDelay: 5000,
        });
        this.setState({ showConfirm: false });
      })
      .catch(() =>
        AlertService.alert({
          type: 'warning',
          message: `There was a problem ${site.transactionCycle.status === 'ACTIVE' ? 'paused' : 'resumed'} the transaction cycle for ${
            site.siteName
          }.`,
        })
      );
  };

  showConfirmationState = () => this.setState({ showConfirm: true });

  cancel = () => this.setState({ showConfirm: false });

  render() {
    const { site, classes } = this.props;
    const { showConfirm } = this.state;
    return (
      <div className={classes.cycle_wrap}>
        {showConfirm ? (
          <Fragment>
            <span className={classes.cycle_description}>Are you sure?</span>
            <Button inline className={classes.confirm_button} onClick={this.confirm}>
              Yes, I&rsquo;m Sure
            </Button>
            <Button onClick={this.cancel} inline secondary>
              Never mind
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            {site.transactionCycle ? (
              <Fragment>
                <Button inline onClick={this.showConfirmationState} className={classes.confirm_button}>
                  End Cycle
                </Button>
                <Button inline secondary onClick={this.togglePauseCycle.bind(this, site)} className={classes.confirm_button}>
                  {site.transactionCycle.status === 'ACTIVE' ? 'Pause' : 'Resume'}
                </Button>
                <span className={classes.cycle_description}>
                  Cycle started: {moment(site.transactionCycle.startedAt).format('MMM D, Y')}
                  {site.transactionCycle.status === 'PAUSED' && ' (Paused)'}
                </span>
              </Fragment>
            ) : (
              <Fragment>
                <Button inline onClick={this.showConfirmationState}>
                  Start Cycle
                </Button>
                <span className={classes.cycle_description}>No active cycle</span>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
