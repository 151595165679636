import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme as theme } from '@spoiler-alert/ui-library';

const useStyles = createUseStyles(
  {
    sorterContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '18px',
      alignSelf: 'center',
      marginLeft: 'auto',
      marginRight: 8,
    },
    sorterUpArrow: {
      height: '0px',
      width: '0px',
      border: '4px solid transparent',
      borderTopWidth: 0,
      borderBottomColor: theme.sorterColor,
      borderBottomWidth: '6.7px',
      marginBottom: '4px',
    },
    sorterUpArrow__active: {
      extend: 'sorterUpArrow',
      borderBottomColor: theme.sorterActiveColor,
    },
    sorterDownArrow: {
      height: '0px',
      width: '0px',
      border: '4px solid transparent',
      borderBottomWidth: 0,
      borderTopColor: theme.sorterColor,
      borderTopWidth: '6.7px',
    },
    sorterDownArrow__active: {
      extend: 'sorterDownArrow',
      borderTopColor: theme.sorterActiveColor,
    },
  },
  { name: 'VSorter' }
);

const VSorter = ({ direction, sorted }) => {
  const classes = useStyles();
  const upArrowClass = direction.toLowerCase() === 'asc' ? classes.sorterUpArrow__active : classes.sorterUpArrow;
  const downArrowClass = direction.toLowerCase() === 'desc' ? classes.sorterDownArrow__active : classes.sorterDownArrow;
  if (!sorted) return '';
  return (
    <span className={classes.sorterContainer} data-element="sorter-container" data-testid="sorter-container">
      <span className={upArrowClass} data-element="sorter-up"></span>
      <span className={downArrowClass} data-element="sorter-down"></span>
    </span>
  );
};

VSorter.propTypes = {
  sorted: PropTypes.bool,
  direction: PropTypes.string,
  classes: PropTypes.object,
};

VSorter.defaultProps = {
  direction: 'asc',
};

export default VSorter;
