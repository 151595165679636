import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import passwordService from '../../services/password-service';
import { TitleService } from '../../services';

const styles = {
  login__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '1170px',
    margin: '0 auto',
  },
  login__title: {
    fontSize: '1.3rem',
    marginBottom: '40px',
    textAlign: 'center',
  },
  form__row: {
    width: '360px',
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  form__container: {
    marginBottom: '2.8rem',
  },
  form__button: {
    width: '100%',
    textTransform: 'uppercase',
  },
  message__container: {
    width: '360px',
    borer: '1px solid #d6e9c6',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    height: '36px',
    marginBottom: '15px',
  },
  'message__container--error': {
    borer: '1px solid #ebccd1',
    backgroundColor: '#f2dede',
    color: '#a94442',
  },
  login__link: {
    color: '#5d9bd1',
    cursor: 'pointer',
  },
  login__input: {
    backgroundColor: 'transparent',
  },
};

@injectSheet(styles)
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Change Password');
    this.state = {
      originalPassword: '',
      password: '',
      confirmPassword: '',
      messageType: 'none',
      message: '',
    };

    this.updateOriginalPassword = this.updateOriginalPassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateConfirmPassword = this.updateConfirmPassword.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);

    // Todo: fix seo
    // SEO.set({
    //   title: setTitle('Change Password'),
    //   description: 'Change Password',
    // });
  }

  updateOriginalPassword(originalPassword) {
    this.setState({ originalPassword: originalPassword.trim() });
  }

  updatePassword(password) {
    this.setState({ password: password.trim() });
  }

  updateConfirmPassword(confirmPassword) {
    this.setState({ confirmPassword: confirmPassword.trim() });
  }

  validatePassword() {
    const { password, confirmPassword, originalPassword } = this.state;
    return password.trim().length > 0 && password.length >= 6 && password === confirmPassword && password !== originalPassword;
  }

  showError(error) {
    this.setState({
      messageType: 'error',
      message: error.reason || 'We were unable to reset your password',
      loading: false,
    });
  }

  passwordChangeSuccess = () => {
    this.setState({
      messageType: 'success',
      loading: false,
    });
    setTimeout(() => this.props.history.push('/dashboard'), 1500);
  };

  submitChangePassword(event) {
    event.preventDefault();
    const { password, originalPassword } = this.state;

    if (this.validatePassword()) {
      this.setState({ loading: true });
      passwordService
        .changePassword(originalPassword, password)
        .then((res) => (res.ok ? this.passwordChangeSuccess() : this.showError(res)))
        .catch((err) => this.showError(err));
    } else {
      const errorMessage = password === originalPassword ? 'Please choose a new password' : 'Your passwords do not match';
      this.setState({
        messageType: 'error',
        message: errorMessage,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { messageType, loading, password, confirmPassword, message, originalPassword } = this.state;
    const messageClassName = `${classes.message__container} ${messageType === 'error' ? classes['message__container--error'] : ''}`;
    const messageText = messageType === 'success' ? <p>Your password was changed successfully. Redirecting...</p> : message;

    return (
      <div className={classes.login__container} data-element="change-password">
        <h4 className={classes.login__title}>Your password has expired. Please change your password using the form below</h4>
        {messageType !== 'none' && (
          <div className={messageClassName} data-element={`message-block-${messageType}`}>
            {messageText}
          </div>
        )}
        <form onSubmit={this.submitChangePassword} className={classes.form__container}>
          <div className={classes.form__row} data-element="original-password">
            <TextInput
              inputClassName={classes.login__input}
              onChange={this.updateOriginalPassword}
              required
              id="original-password"
              type="password"
              labelText="Original Password"
              value={originalPassword}
              pattern=".{6,}"
              title="Your Password must be at least 6 characters"
            />
          </div>

          <div className={classes.form__row} data-element="password">
            <TextInput
              inputClassName={classes.login__input}
              onChange={this.updatePassword}
              required
              id="new-password"
              type="password"
              labelText="Password"
              value={password}
              pattern=".{6,}"
              title="Your Password must be at least 6 characters"
            />
          </div>

          <div className={classes.form__row} data-element="confirm-password">
            <TextInput
              inputClassName={classes.login__input}
              onChange={this.updateConfirmPassword}
              required
              id="confirm-password"
              type="password"
              labelText="Confirm New Password"
              value={confirmPassword}
              pattern=".{6,}"
              title="Your Password must be at least 6 characters"
            />
          </div>

          <div className={classes.form__row}>
            <Button className={classes.form__button} id="set-password-button" type="submit" loading={loading} loadingText="Loading...">
              Set Password
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
};
