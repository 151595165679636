import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, MenuHeader, MenuSeparator, ChevronIcon, Avatar, AlertService } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import routePaths from '../route-paths';

const styles = {
  'user-menu__container': {
    position: 'relative',
  },
  chevron__icon: {
    marginLeft: 8,
    stroke: '#292e34',
    height: 9,
    width: 13,
  },
  'user-menu__target': {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text__capitalize: {
    textTransform: 'capitalize',
  },
  '@media (max-width: 840px)': {
    'user-menu__container': {
      display: 'none',
    },
  },
};

@injectSheet(styles)
export default class UserMenu extends React.Component {
  state = {
    show: false,
  };

  error(message) {
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  }

  showMenu = () => {
    this.setState({ show: true });
  };

  hideMenu = () => {
    this.setState({ show: false });
  };

  menu = React.createRef();

  render() {
    const { user, classes } = this.props;
    const menuClass = user.isImpersonating ? classes['menu__container--impersonate'] : undefined;
    return (
      <div className={classes['user-menu__container']}>
        <div className={classes['user-menu__target']} onClick={this.showMenu} ref={this.menu}>
          <Avatar image={user.photo} firstName={user.firstName} lastName={user.lastName} size="small" impersonating={user.isImpersonating} />
          <ChevronIcon className={classes.chevron__icon} />
        </div>
        <Menu show={this.state.show} position="topright" parent={this.menu} className={menuClass} onHide={this.hideMenu}>
          {user.isImpersonating && <MenuHeader className={classes['menu__header--impersonate']}>{user.site.siteName}</MenuHeader>}
          <MenuHeader>
            <Avatar image={user.photo} firstName={user.firstName} lastName={user.lastName} impersonating={user.isImpersonating} />
          </MenuHeader>
          <MenuHeader className={classes.text__capitalize}>
            {user.firstName} {user.lastName}
          </MenuHeader>
          <MenuItem route={routePaths.userManagement} icon="usergroup">
            User Management
          </MenuItem>
          <MenuSeparator />
          <MenuItem route={routePaths.logout} icon="logout">
            Log Out
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  client: PropTypes.object,
  mutate: PropTypes.func,
};
