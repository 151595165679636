import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import passwordService from '../../services/password-service';
import { TitleService } from '../../services';

const styles = {
  login__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '1170px',
    margin: '0 auto',
  },
  login__title: {
    fontSize: '1.3rem',
    marginBottom: '40px',
    textAlign: 'center',
  },
  form__row: {
    width: '360px',
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  form__container: {
    marginBottom: '2.8rem',
  },
  form__button: {
    width: '100%',
    textTransform: 'uppercase',
  },
  message__container: {
    width: '360px',
    borer: '1px solid #d6e9c6',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    height: '36px',
    marginBottom: '15px',
  },
  'message__container--error': {
    borer: '1px solid #ebccd1',
    backgroundColor: '#f2dede',
    color: '#a94442',
  },
  login__input: {
    backgroundColor: 'transparent',
  },
};

@injectSheet(styles)
export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Forgot Password');
    this.state = {
      email: '',
      loading: false,
      message: '',
      messageType: 'none',
    };
  }

  setEmail = (email) => {
    this.setState({
      email: email.trim(),
      messageType: 'none',
    });
  };

  passwordResult = (message, res) => {
    this.setState({
      message,
      messageType: res.ok ? 'success' : 'error',
      loading: false,
    });
  };

  submitForgotPassword = (event) => {
    event.preventDefault();
    const { email } = this.state;
    this.setState({ loading: true });
    const message = 'Please check your email for a link to reset your password.';

    passwordService
      .forgotPassword(email.trim().toLowerCase())
      .then((res) => this.passwordResult(message, res))
      .catch((error) => this.passwordResult(error.reason || 'Sorry, but we had trouble with that address.', error));
  };

  render() {
    const { classes } = this.props;
    const { messageType, message, loading, email } = this.state;
    const messageClassName = `${classes.message__container} ${messageType === 'error' ? classes['message__container--error'] : ''}`;
    return (
      <div className={classes.login__container} data-element="forgot-password">
        <h4 className={classes.login__title}>Reset your Password</h4>
        {messageType !== 'none' && (
          <div className={messageClassName} data-element={`message-block-${messageType}`}>
            {message}
          </div>
        )}
        <form onSubmit={this.submitForgotPassword} className={classes.form__container}>
          <div className={classes.form__row}>
            <TextInput
              inputClassName={classes.login__input}
              onChange={this.setEmail}
              id="email"
              required
              type="email"
              labelText="Email"
              value={email}
            />
          </div>

          <div className={classes.form__row}>
            <Button className={classes.form__button} id="reset-password-button" type="submit" loading={loading} loadingText="Loading...">
              Send Password Reset Link
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object,
};
