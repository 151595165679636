import { gql } from '@apollo/client';
import { InventoryDetailFragment } from './inventory-manager-fragments';

export const AdminInventoryByCurrentCycleSiteQuery = gql`
  ${InventoryDetailFragment}
  query adminInventoryByCurrentCycleSiteQuery($siteId: String!) {
    adminInventoryByCurrentCycleSiteQuery(siteId: $siteId) {
      ...InventoryDetails
    }
  }
`;
