import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  Select,
  TextInput,
  SelectOption,
  Pill,
  TypeAhead,
  Checkbox,
  SimpleTextArea,
} from '@spoiler-alert/ui-library';
import { BsrNameTypeAheadQuery } from '../../graphql/queries';

const styles = {
  modalTitle: {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
  inputRow: {
    padding: '6px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputRowFull: {
    padding: '6px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  formContainer: {
    width: '100%',
  },
  inputContainer: {
    padding: '0 12px 0 0',
    flexGrow: 1,
  },
  inputField: {
    width: '100%',
    '&:first-child': {
      marginRight: 24,
    },
  },
  inputFieldHalf: {
    width: '50%',
    '&:first-child': {
      marginRight: 24,
    },
  },
  checkboxField: {
    width: '50%',
    '&:first-child': {
      marginRight: 24,
    },
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    display: 'block',
    marginLeft: 10,
    cursor: 'pointer',
  },
  bsrPillContainer: {
    maxHeight: '200px',
    overflowY: 'scroll',
  },
};

// This isn't an ideal solution but as of now we only support these two options.
// We need to sort how we want to handle destinationTypes in a large scale and at this point change this logic
const distributionListTypes = ['Discounted Sales', 'Liquidation', 'Donation'];

@injectSheet(styles)
export default class DistributionListModal extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onHide: PropTypes.func,
    open: PropTypes.bool,
    listName: PropTypes.string,
    listType: PropTypes.string,
    bsrs: PropTypes.array,
    listId: PropTypes.string,
    saving: PropTypes.bool,
    onRemoveBsrs: PropTypes.func,
    onSetListType: PropTypes.func,
    onAddBsr: PropTypes.func,
    onSave: PropTypes.func,
    onSetListName: PropTypes.func,
    sellerSiteId: PropTypes.string,
    onSetEmailTemplateId: PropTypes.func,
    offerEmailTemplateId: PropTypes.string,
    archived: PropTypes.bool,
    onSetArchived: PropTypes.func,
    onSetOfferEmailCustomContent: PropTypes.func,
    offerEmailCustomContent: PropTypes.string,
  };

  cancel = () => {
    this.props.onHide(false);
  };

  get bsrNameTypeAhead() {
    const additionalQueryParams = { sellerSiteId: this.props.sellerSiteId };
    return (
      <TypeAhead
        query={BsrNameTypeAheadQuery}
        queryName="bsrNameTypeAheadQuery"
        labelText={'Buyer Seller Relationship Name'}
        onChange={this.props.onAddBsr}
        onSelect={this.onSelect}
        itemLimit={10}
        value={''}
        queryVariables={additionalQueryParams}
        resetOnSelection
        autoWidth
      />
    );
  }

  get generateDistributionListTypes() {
    return distributionListTypes.map((list) => (
      <SelectOption value={list} key={list}>
        {list}
      </SelectOption>
    ));
  }

  get generateBsrPills() {
    const { bsrs, onRemoveBsrs } = this.props;
    return bsrs.map((b) => <Pill key={`.pill-${b.id}`} fileName={b.name} onCancel={onRemoveBsrs} />);
  }

  render() {
    const {
      classes,
      listName,
      listType,
      open,
      listId,
      saving,
      onSetListType,
      bsrs,
      onSetListName,
      offerEmailTemplateId,
      onSetEmailTemplateId,
      archived,
      onSetArchived,
      offerEmailCustomContent,
      onSetOfferEmailCustomContent,
    } = this.props;
    const selectedItem = { value: listType, text: listType };
    const modalText = listId ? 'Edit Distribution List' : 'Add Distribution List';
    return (
      <Modal onHide={this.props.onHide} open={open} closeOnEsc closeOnOutsideClick>
        <form onSubmit={this.props.onSave} className={classes.formContainer}>
          <ModalContent>
            <h2 className={classes.modalTitle}>{modalText}</h2>
            <div className={classes.inputRow}>
              <div className={classes.inputContainer}>
                <TextInput
                  className={classes.inputField}
                  onChange={onSetListName}
                  type="text"
                  labelText="Distribution List Name *"
                  value={listName}
                  required
                />
              </div>
              <div className={classes.inputContainer}>
                <Select label="Distribution List Type" onChange={onSetListType} required selectedItem={selectedItem}>
                  {this.generateDistributionListTypes}
                </Select>
              </div>
            </div>
            <div className={classes.inputRow}>
              <div className={classes.inputFieldHalf}>
                <TextInput
                  className={classes.inputField}
                  onChange={onSetEmailTemplateId}
                  type="text"
                  labelText="Custom Offer Email Template"
                  value={offerEmailTemplateId}
                />
              </div>
              <div className={classes.checkboxField}>
                <Checkbox value="archived" checked={archived} onChecked={({ checked }) => onSetArchived(checked)} />
                <span className={classes.checkboxLabel} onClick={() => onSetArchived(!archived)}>
                  Archive List
                </span>
              </div>
            </div>
            <div className={classes.inputRowFull}>
              <label htmlFor="OfferEmailCustomContent">Offer Email Custom Content</label>
              <SimpleTextArea
                className={classes.inputField}
                onChange={onSetOfferEmailCustomContent}
                type="text"
                value={offerEmailCustomContent}
                id="OfferEmailCustomContent"
              />
            </div>
            <div className={classes.inputRow}>
              <div className={classes.inputFieldHalf}> {this.bsrNameTypeAhead} </div>
            </div>
            <div className={classes.bsrPillContainer}>{this.generateBsrPills}</div>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={this.cancel} link disabled={saving}>
              Cancel
            </Button>
            <Button type="submit" disabled={!listName || !listType || bsrs.length <= 0 || saving} primary loading={saving} loadingText="Wait...">
              Save Distribution List
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
