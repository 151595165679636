import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Table, Row, Col, LoadingLine } from '@spoiler-alert/ui-library';
import ETLMapping from './etl-mapping';
import NewButton from './new-button';

const styles = {
  tableContainer: {
    position: 'relative',
  },
  newCol: {},
  table: {
    '& tr>td': {
      padding: 8,
      '&$newCol': {
        padding: [15, 8, 17, 24],
      },
    },
  },
};

const ETLMappingsTable = ({ classes, mappings, newMapping, loadings, updatedMapping, ...rest }) => {
  const createNewMapping = () => newMapping({});
  return (
    <div className={classes.tableContainer}>
      <LoadingLine loading={loadings.creatingMapping || loadings.savingMapping || loadings.deletingMapping} />
      <Table className={classes.table}>
        {[
          <Row key="header" header>
            <Col header>External Name</Col>
            <Col header>Internal Name</Col>
            <Col header>Data&nbsp;Type</Col>
            <Col header>Validator(s)</Col>
            <Col header>Pad&nbsp;Start</Col>
            <Col header>Operation</Col>
            <Col header></Col>
          </Row>,
          ...mappings.map((m) => (
            <ETLMapping required key={m._id} mapping={m} justSaved={m._id === updatedMapping?.updateImportMapping._id} {...rest} />
          )),
          <Row key="Mapping">
            <Col className={classes.newCol} colspan={8}>
              <NewButton onClick={createNewMapping}>New</NewButton>
            </Col>
          </Row>,
        ]}
      </Table>
    </div>
  );
};

ETLMappingsTable.propTypes = {
  classes: PropTypes.object,
  mappings: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ),
  newMapping: PropTypes.func,
  loadings: PropTypes.object,
  updatedMapping: PropTypes.object,
};

export default injectSheet(styles)(ETLMappingsTable);
