import { gql } from '@apollo/client';

export const MarketplaceStorefrontsQuery = gql`
  query marketplaceStorefrontsQuery {
    adminMarketplaceQuery {
      _id
      siteName
      updatedAt
      tagline
      description
      longDescription
      logo
      splashLogo
      emailBanner
      landingPageThumbnail
      website
      retailPresence
      categories
      facilities {
        _id
        siteName
      }
      hasPackout
    }
  }
`;

export const MarketplaceSKUQuery = gql`
  query marketplaceSKUQuery($facilityIds: [String]!) {
    adminMarketplaceSKUQuery(facilityIds: $facilityIds) {
      _id
      itemName
      description
      truckType
      casesPerPallet
      galleryImages
      longDescription
      thumbnailImage
      palletWeight
      palletLength
      palletHeight
      isPackout
      leadTime
      tags
    }
  }
`;
