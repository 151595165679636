import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Refresher from '../components/refresher';

const styles = {
  site__container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'rgba(200,0,0,0.2)',
  },
  text__bg: {
    opacity: 0.1,
    display: 'inline-block',
    position: 'relative',
    userSelect: 'none',
    '&:before': {
      fontFamily: 'Roboto',
      fontSize: '2em',
      fontWeight: 'bold',
      content: '"ADMINISTRATION PANEL"',
      transform: 'rotateZ(-45deg) translate(55px, 230px)',
      height: '10em',
      display: 'block',
    },
  },
  tiles: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'block',
    zIndex: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  tiles__container: {
    display: 'block',
    height: '100%',
    width: '150%',
    margin: '-300px',
  },
};

@injectSheet(styles)
class Layout extends React.Component {
  tiles() {
    const tiles = [];
    for (let i = 0; i < 100; i++) {
      tiles.push(<div key={`tile-${i}`} className={this.props.classes.text__bg}></div>);
    }
    return tiles;
  }

  render() {
    const { classes, children } = this.props;
    return (
      <div className={classes.site__container}>
        <div className={classes.tiles}>
          <div className={classes.tiles__container}>{this.tiles()}</div>
        </div>
        {children}
        <Refresher />
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object,
  showLink: PropTypes.bool,
  children: PropTypes.node,
};

export default Layout;
