import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = {
  down__page: {
    width: '100%',
  },
  text__center: {
    textAlign: 'center',
  },
};

@injectSheet(styles)
export default class DownPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.down__page}>
        <div>
          <h2 className={classes.text__center}>We&apos;re sorry!</h2>
          <p className={classes.text__center}>
            We&apos;re currently down for maintenance. We expect to be back up shortly. Thank you for your patience.
          </p>
        </div>
      </div>
    );
  }
}

DownPage.propTypes = {
  classes: PropTypes.object,
};
