import React from 'react';
import PropTypes from 'prop-types';
import { TextArea, TextInput, Radio, Theme, Select, SelectOption, Checkbox } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import IMPORT_TYPES from './enums/etl-import-types';

const fileTypes = [
  { value: 'EXCEL', text: 'Excel' },
  { value: 'CSV', text: 'CSV' },
];

const styles = {
  notice: {
    backgroundColor: Theme.yellow10,
    border: `1px ${Theme.yellow} solid`,
    padding: 10,
    textAlign: 'center',
    marginBottom: 24,
    fontSize: '14px',
    borderRadius: 4,
  },
  delimiter: {
    width: 69,
  },
  fileProperties: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomRow: {
    display: 'flex',
  },
  dateSelect: {
    width: '50%',
  },
  rootCheckbox: {
    display: 'flex',
    marginLeft: 24,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  checkboxLabel: {
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
  },
};

const dateFormats = ['YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY', 'M/D/YY', 'D-MMM-YY', 'M/D/YYYY', 'YYYYMMDD', 'DD/MM/YYYY', 'DD/MM/YY'];

const ETLProfileForm = ({ classes, importProfile, onChange }) => {
  const updateField = (field, value) => {
    const newImportProfile = {
      ...importProfile,
      [field]: value,
    };
    onChange(newImportProfile);
  };

  const updateFileType = (item) => {
    updateField('fileType', item.value);
  };

  const toggleRootSite = () => {
    updateField('useRootSiteForItems', !importProfile.useRootSiteForItems);
  };

  const toggleBypassItemValidation = () => {
    updateField('bypassItemValidation', !importProfile.bypassItemValidation);
  };

  const formatDateFormatOptions = () =>
    dateFormats.map((df) => (
      <SelectOption key={df} value={df}>
        {df}
      </SelectOption>
    ));

  const getSelectedDates = () => importProfile.dateFormats?.map((df) => ({ value: df, text: df }));

  const setDates = (selected) =>
    updateField(
      'dateFormats',
      selected.map((s) => s.value)
    );

  return (
    <div>
      <div className={classes.notice}>To reduce errors, please copy &amp; paste column headers directly from the Excel or CSV file.</div>
      <form>
        <div className={classes.fileProperties}>
          <Radio
            labelText="File type"
            options={fileTypes}
            selectedItem={fileTypes.find((t) => t.value === importProfile.fileType)}
            onChange={updateFileType}
          />
          {importProfile.fileType === 'CSV' && (
            <div className={classes.delimiter}>
              <TextInput type="text" labelText="Delimiter" onChange={updateField.bind(this, 'delimiter')} value={importProfile.delimiter || ''} />
            </div>
          )}
        </div>
        <TextArea
          required
          labelText="Column names (tab deliminated)"
          onChange={updateField.bind(this, 'headerNames')}
          value={importProfile.headerNames}
        />
        {importProfile.importType === IMPORT_TYPES.INVENTORY.value && !importProfile._id && (
          <TextInput type="text" labelText="Import name" onChange={updateField.bind(this, 'importName')} value={importProfile.importName} />
        )}
        <div className={classes.bottomRow}>
          <div className={classes.dateSelect}>
            <Select label="Date format(s)" multiple selectedItems={getSelectedDates()} onChange={setDates}>
              {formatDateFormatOptions()}
            </Select>
          </div>
          {importProfile.importType === IMPORT_TYPES.INVENTORY.value && (
            <div className={classes.rootCheckbox}>
              <Checkbox value="useRootSiteForItems" checked={importProfile.useRootSiteForItems} onChecked={toggleRootSite} />{' '}
              <div className={classes.checkboxLabel} onClick={toggleRootSite}>
                Use root sites for items
              </div>
              <Checkbox value="bypassItemValidation" checked={importProfile.bypassItemValidation} onChecked={toggleBypassItemValidation} />{' '}
              <div className={classes.checkboxLabel} onClick={toggleBypassItemValidation}>
                Bypass item validation
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

ETLProfileForm.propTypes = {
  importProfile: PropTypes.object,
  onChange: PropTypes.func,
  classes: PropTypes.object,
};

export default injectSheet(styles)(ETLProfileForm);
