import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, StatusBadge } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';

const styles = {
  name__container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
    fontSize: '0.875rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  status__badge: {
    marginTop: 6,
  },
};

@injectSheet(styles)
export default class NameColumn extends Component {
  get status() {
    const { expired } = this.props.row;
    return expired ? 'EXPIRED' : 'INVITED';
  }

  render() {
    const { row, classes } = this.props;
    const { firstName, lastName, photo, expired } = row;
    return (
      <div className={classes.name__container}>
        <Avatar image={photo} firstName={firstName} lastName={lastName} size="small" />
        <div className={classes.name}>
          <span>{`${firstName} ${lastName}`}</span>
          {expired !== undefined && (
            <span className={classes.status__badge}>
              <StatusBadge status={this.status} />
            </span>
          )}
        </div>
      </div>
    );
  }
}

NameColumn.propTypes = {
  row: PropTypes.object,
  classes: PropTypes.object,
};
