import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button } from '@spoiler-alert/ui-library';
import ETLProfileForm from './etl-profile-form';
import IMPORT_TYPES from './enums/etl-import-types';

const styles = {
  wrap: {
    height: 'calc(100vh - 116px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  heading: {
    textAlign: 'center',
  },
};

const ETLNewProfile = ({ classes, importType, site, create }) => {
  const createBlankProfile = () => ({
    importType: importType.value,
    useRootSiteForItems: importType.value === IMPORT_TYPES.INVENTORY.value ? true : undefined,
    importName: importType.value === IMPORT_TYPES.INVENTORY.value ? site.importProfileName : undefined,
  });

  const [importProfile, setImportProfile] = useState(() => createBlankProfile());

  useEffect(() => {
    setImportProfile(createBlankProfile());
  }, [importType.value, site]);

  const disableCreate = () => {
    if (!importProfile.fileType) return true;
    if (importProfile.fileType === 'CSV' && !importProfile.delimiter) return true;
    if (!importProfile.headerNames) return true;
    if (importProfile.importType === IMPORT_TYPES.INVENTORY.value && !importProfile.importName) return true;
    if (!importProfile.dateFormats || importProfile.dateFormats.length === 0) return true;
    return false;
  };

  const createProfile = () => {
    create({
      variables: {
        importProfile: {
          delimiter: importProfile.delimiter,
          headerNames: importProfile.headerNames.toLowerCase().split(importProfile.delimiter || '\t'),
          importName: importProfile.importName,
          dateFormats: importProfile.dateFormats,
          useRootSiteForItems: importProfile.useRootSiteForItems,
          siteId: site._id,
          importType: importType.value,
        },
      },
    });
  };

  return (
    <div className={classes.wrap}>
      <h2 className={classes.heading}>Create a new {importType.singular.toLowerCase()} import</h2>
      <ETLProfileForm importProfile={importProfile} onChange={setImportProfile} />
      <Button primary onClick={createProfile} disabled={disableCreate()}>
        Create New
      </Button>
    </div>
  );
};

ETLNewProfile.propTypes = {
  importType: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  create: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export default injectSheet(styles)(ETLNewProfile);
