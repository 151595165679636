import React from 'react';
import { PropTypes } from 'prop-types';
import injectSheet from 'react-jss';
import { Select, SelectOption, Checkbox } from '@spoiler-alert/ui-library';

const styles = {
  formContainer: {
    marginTop: '20px',
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  leftSelectWrap: {
    width: '100%',
    marginRight: '10px',
  },
  rightSelectWrap: {
    width: '100%',
    marginLeft: '10px',
  },
  splitSheetsCheckbox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  checkboxLabel: {
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: '14px',
  },
};

const dateFormats = ['YYYY-MM-DD', 'MM/DD/YYYY', 'M/D/YY', 'D-MMM-YY'];

const ExportProfileForm = ({ classes, exportProfile, possibleSheetWriters, setEditingExportProfile }) => {
  const formatDateFormatOptions = () =>
    dateFormats.map((df) => (
      <SelectOption key={df} value={df}>
        {df}
      </SelectOption>
    ));

  const formatSheetWriterOptions = () =>
    possibleSheetWriters.map((sw) => (
      <SelectOption key={sw} value={sw}>
        {sw}
      </SelectOption>
    ));

  const updateField = (field, value) => {
    const newExportProfile = {
      ...exportProfile,
      [field]: value,
    };
    setEditingExportProfile(newExportProfile);
  };

  const getSelectedSheetWriter = () => {
    if (exportProfile?.sheetWriterClass) {
      return { value: exportProfile?.sheetWriterClass, text: exportProfile?.sheetWriterClass };
    }
    return undefined;
  };
  const setSheetWriter = (selected) => {
    updateField('sheetWriterClass', selected[0].value);
  };

  const getSelectedDateFormat = () => {
    if (exportProfile?.dateFormat) {
      return { value: exportProfile?.dateFormat, text: exportProfile?.dateFormat };
    }
    return undefined;
  };

  const setDate = (selected) => {
    updateField('dateFormat', selected[0].value);
  };

  const toggleSplitSheets = () => {
    updateField('splitSheetsBySupplierSite', !exportProfile.splitSheetsBySupplierSite);
  };

  return (
    <div className={classes.formContainer}>
      <form>
        <div className={classes.firstRow}>
          <div className={classes.leftSelectWrap}>
            <Select label="Sheet writer" search selectedItem={getSelectedSheetWriter()} onChange={setSheetWriter}>
              {formatSheetWriterOptions()}
            </Select>
          </div>
          <div className={classes.rightSelectWrap}>
            <Select label="Date format" selectedItem={getSelectedDateFormat()} onChange={setDate}>
              {formatDateFormatOptions()}
            </Select>
          </div>
        </div>
        {exportProfile.exportType === 'AWARD SHEET' && (
          <div className={classes.splitSheetsCheckbox}>
            <Checkbox onChecked={toggleSplitSheets} checked={exportProfile?.splitSheetsBySupplierSite}></Checkbox>
            <div className={classes.checkboxLabel} onClick={toggleSplitSheets}>
              Split sheets by Supplier Site
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

ExportProfileForm.propTypes = {
  classes: PropTypes.object,
  exportProfile: PropTypes.object,
  possibleSheetWriters: PropTypes.array,
  setEditingExportProfile: PropTypes.func,
};

export default injectSheet(styles)(ExportProfileForm);
