import { gql } from '@apollo/client';

// Fragments
const MAPPING_FIELDS = gql`
  fragment MappingFields on ImportMapping {
    _id
    importProfileId
    externalName
    saName
    validators
    padStart
    dataType
    order
    importErrors
    operation {
      operator
      params
      staticParams
    }
  }
`;

const DEFAULT_FIELDS = gql`
  fragment DefaultFields on ImportDefault {
    _id
    importProfileId
    saName
    externalName
    importErrors
    defaultValues {
      defaultType
      defaultValue
    }
  }
`;

// Queries
export const CUSTOMERS_AND_IMPORT_PROFILES = gql`
  query getSitesAndImportProfiles {
    importManagerQuery {
      _id
      allSellerOrgs {
        _id
        siteName
        importProfileName
        importProfiles {
          _id
          importType
          importName
          delimiter
          useRootSiteForItems
          headerNames
          dateFormats
          validated
          bypassItemValidation
        }
      }
    }
  }
`;

export const IMPORT_PROFILE_FIELDS_QUERY = gql`
  ${MAPPING_FIELDS}
  ${DEFAULT_FIELDS}
  query getFieldsByImportProfileId($importProfileId: String) {
    importManagerQuery {
      _id
      importProfile(importProfileId: $importProfileId) {
        _id
        mappings {
          ...MappingFields
        }
        defaults {
          ...DefaultFields
        }
        possibleInternalFields {
          name
          type
        }
        importErrors
        headerNames
        possibleValidators
        possibleOperators
        itemFields {
          name
        }
      }
    }
  }
`;

// Mutations
export const VALIDATE_IMPORT_PROFILE_MUTATION = gql`
  ${MAPPING_FIELDS}
  ${DEFAULT_FIELDS}
  mutation validateImportProfile($importProfileId: String) {
    validateImportProfile(importProfileId: $importProfileId) {
      _id
      validated
      importErrors
      mappings {
        ...MappingFields
      }
      defaults {
        ...DefaultFields
      }
    }
  }
`;

export const CREATE_IMPORT_PROFILE_MUTATION = gql`
  mutation createNewImportProfile($importProfile: ImportProfileInputType) {
    createNewImportProfile(importProfile: $importProfile) {
      _id
      siteId
      importType
      importName
      delimiter
      useRootSiteForItems
      headerNames
      dateFormats
    }
  }
`;

export const CREATE_IMPORT_MAPPING_MUTATION = gql`
  ${MAPPING_FIELDS}
  mutation createImportMapping($importMapping: ImportMappingInputType) {
    createImportMapping(importMapping: $importMapping) {
      _id
      mappings {
        ...MappingFields
      }
    }
  }
`;

export const CREATE_IMPORT_DEFAULT_MUTATION = gql`
  ${DEFAULT_FIELDS}
  mutation createImportDefault($importProfileId: String) {
    createImportDefault(importProfileId: $importProfileId) {
      _id
      defaults {
        ...DefaultFields
      }
    }
  }
`;

export const UPDATE_IMPORT_PROFILE_MUTATION = gql`
  mutation updateImportProfile($importProfile: ImportProfileInputType) {
    updateImportProfile(importProfile: $importProfile) {
      _id
      siteId
      importType
      importName
      delimiter
      headerNames
      useRootSiteForItems
      dateFormats
    }
  }
`;

export const UPDATE_IMPORT_MAPPING_MUTATION = gql`
  ${MAPPING_FIELDS}
  mutation updateImportMapping($importMapping: ImportMappingInputType) {
    updateImportMapping(importMapping: $importMapping) {
      ...MappingFields
    }
  }
`;

export const UPDATE_IMPORT_DEFAULT_MUTATION = gql`
  ${DEFAULT_FIELDS}
  mutation updateImportDefault($importDefault: ImportDefaultInputType) {
    updateImportDefault(importDefault: $importDefault) {
      ...DefaultFields
    }
  }
`;

export const DELETE_IMPORT_MAPPING_MUTATION = gql`
  mutation deleteImportMapping($importMapping: ImportMappingInputType) {
    deleteImportMapping(importMapping: $importMapping) {
      _id
      mappings {
        _id
      }
    }
  }
`;

export const DELETE_IMPORT_DEFAULT_MUTATION = gql`
  mutation deleteImportDefault($importDefault: ImportDefaultInputType) {
    deleteImportDefault(importDefault: $importDefault) {
      _id
      defaults {
        _id
      }
    }
  }
`;
