import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Button, MiniTooltip } from '@spoiler-alert/ui-library';

const useStyles = createUseStyles(
  {
    rowAction: {
      marginRight: 8,
    },
  },
  { name: 'VRowAction' }
);

const VRowAction = ({
  icon,
  label,
  row,
  rows,
  headerRowOnClick,
  onClick,
  disabledRows,
  disabled,
  tooltipText,
  loadingTooltipText,
  loadingRows,
  warning,
  secondary,
  header,
  className,
  cypressTag,
}) => {
  const classes = useStyles();

  const isDisabled = useMemo(() => {
    if (disabled) return true;
    return row ? disabledRows.indexOf(row._id) !== -1 : disabledRows.indexOf(rows[0].sellerSiteName) !== -1;
  }, [disabled, row, disabledRows]);

  const isLoading = useMemo(() => {
    return row ? loadingRows.indexOf(row._id) !== -1 : loadingRows.indexOf(rows[0].sellerSiteName) !== -1;
  }, []);

  const handleClick = useCallback(
    (_row, event) => {
      event.stopPropagation();
      onClick(_row)(event);
    },
    [onClick]
  );

  const button = useMemo(() => {
    const clickHandler = header ? headerRowOnClick(rows) : handleClick.bind(this, row);
    return (
      <Button
        className={className}
        onClick={clickHandler}
        icon={icon}
        secondary={secondary}
        warning={warning}
        loading={isLoading}
        disabled={isDisabled}
        cypressTag={cypressTag}
      >
        {label}
      </Button>
    );
  }, [header, headerRowOnClick, handleClick, onClick, icon, secondary, warning, isLoading, isDisabled, cypressTag, label, className]);

  const tooltipWrapper = useMemo(() => {
    const tooltipLabel = isLoading ? loadingTooltipText : tooltipText;
    return <MiniTooltip text={tooltipLabel}>{button}</MiniTooltip>;
  }, [isLoading, button, tooltipText, loadingTooltipText]);

  return <div className={classes.rowAction}>{tooltipText ? tooltipWrapper : button}</div>;
};

VRowAction.defaultProps = {
  onClick: () => {},
  headerRowOnClick: () => {},
  loadingRows: [],
  disabledRows: [],
};

VRowAction.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  footer: PropTypes.bool,
  onClick: PropTypes.func,
  headerRowOnClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.func,
  tooltipText: PropTypes.string,
  loadingTooltipText: PropTypes.string,
  secondary: PropTypes.bool,
  warning: PropTypes.bool,
  row: PropTypes.object,
  rows: PropTypes.array,
  header: PropTypes.bool,
  disabledRows: PropTypes.array,
  loadingRows: PropTypes.array,
  cypressTag: PropTypes.string,
  disabled: PropTypes.bool,
};

export default VRowAction;
