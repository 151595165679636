import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Layout, InternalLayout } from './layouts';
import routePaths from './route-paths';

const UnAuthedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
);

UnAuthedRoute.propTypes = {
  component: PropTypes.any,
};

class AuthedRoute extends React.Component {
  render() {
    const { component, guard, redirectPath, user, ...rest } = this.props;
    const Component = component;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!localStorage.getItem('token')) return <Redirect to={`${routePaths.signIn}?redirect=${props.location.pathname}`} />;
          const guardCheck = guard(user);
          if (!guardCheck.isValid) return <Redirect to={redirectPath || guardCheck.redirect || routePaths.home} />;
          return (
            <InternalLayout user={user} {...props} {...rest}>
              <Component user={user} {...props} {...rest} />
            </InternalLayout>
          );
        }}
      />
    );
  }
}

const RedirectRoute = ({ ...rest }) => <Redirect to={rest.redirectPath} />;

AuthedRoute.propTypes = {
  user: PropTypes.object,
  component: PropTypes.any,
  guard: PropTypes.func,
  redirectPath: PropTypes.string,
};

AuthedRoute.defaultProps = {
  guard: () => ({ isValid: true }),
};

export { UnAuthedRoute, AuthedRoute, RedirectRoute };
