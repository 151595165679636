import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Column, DataTableNaked, AlertService, Button } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import DistributionListModal from './distribution-list-modal';
import { DistributionListsBySiteQuery } from '../../graphql/queries';
import { UpdateDistributionList } from '../../graphql/mutations';

const styles = {
  confirm_buttons: {
    display: 'flex',
    marginTop: 10,
  },
  table__grid: {
    border: '1px #D1D6DA solid',
    display: 'flex',
    margin: '25px 0 0',
  },
};

const columns = [
  new Column({
    field: 'name',
    displayName: 'Distribution List Name',
    sortable: false,
    defaultSort: true,
    formatter: (value, row) => (row.error ? `List ${value} has an error in it` : value),
  }),
  new Column({ field: 'destinationType', displayName: 'Distribution List Type' }),
  new Column({ field: 'sites', displayName: 'Number of Sites', formatter: (value) => value.length }),
  new Column({ field: 'archived', displayName: 'Archived', formatter: (value) => (value ? 'Yes' : 'No') }),
];

const DistributionLists = ({ classes, match }) => {
  const parentSiteId = match.params.siteId;
  const { data: distributionLists, loading } = useQuery(DistributionListsBySiteQuery, { variables: { siteId: match.params.siteId } });
  const [updateList, { loading: loadingUpdate }] = useMutation(UpdateDistributionList);

  const [showModal, setShowModal] = useState(false);
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    TitleService.setTitles('Distribution List Administration');
  }, []);

  const hideModal = () => {
    setShowModal(false);
    setSelectedList(null);
  };

  const addNew = () => {
    setSelectedList({
      _id: '',
      buyerSellerRelationships: [],
    });
    setShowModal(true);
  };

  const handleRowClick = (row) => {
    setShowModal(true);
    setSelectedList({ ...row });
  };

  const removeBsr = (bsrName) => {
    const buyerSellerRelationships = selectedList.buyerSellerRelationships.filter((bsr) => bsr.name !== bsrName);
    setSelectedList({ ...selectedList, buyerSellerRelationships });
  };

  const setDistributionListType = (listType) => {
    setSelectedList({ ...selectedList, destinationType: listType[0].value });
  };

  const setListName = (listName) => {
    setSelectedList({ ...selectedList, name: listName.trim() });
  };

  const setOfferEmailTemplateId = (offerEmailTemplateId) => {
    setSelectedList({ ...selectedList, offerEmailTemplate: offerEmailTemplateId.trim() });
  };

  const setArchived = (archived) => {
    setSelectedList({ ...selectedList, archived });
  };

  const setOfferEmailCustomContent = (offerEmailCustomContent) => {
    setSelectedList({ ...selectedList, offerEmailCustomContent });
  };

  const addBsr = (selectedBsr) => {
    if (selectedBsr.result.sellerSiteId !== parentSiteId) {
      AlertService.alert({
        type: 'warning',
        message: `BuyerSellerRelationship ${selectedBsr.displayText}'s sellerId ${selectedBsr.result.sellerSiteId} does not match parentSiteId of ${parentSiteId}`,
      });
    } else {
      const copiedBsrs = [...selectedList.buyerSellerRelationships];
      const formattedBsr = { name: selectedBsr.displayText, id: selectedBsr.result.id };
      copiedBsrs.push(formattedBsr);
      setSelectedList({ ...selectedList, buyerSellerRelationships: copiedBsrs });
    }
  };

  const saveDistributionList = (ev) => {
    ev.preventDefault();
    const bsrIds = selectedList.buyerSellerRelationships.map((b) => b.id);
    updateList({
      variables: {
        siteId: match.params.siteId,
        listName: selectedList.name,
        listId: selectedList._id,
        listType: selectedList.destinationType,
        bsrIds,
        offerEmailTemplateId: selectedList.offerEmailTemplate,
        archived: selectedList.archived,
        offerEmailCustomContent: selectedList.offerEmailCustomContent?.trim(),
      },
      refetchQueries: [
        {
          query: DistributionListsBySiteQuery,
          variables: { siteId: parentSiteId },
        },
      ],
    })
      .then(() => {
        AlertService.alert({
          type: 'success',
          message: 'Distribution list successfully saved!',
          autoDismiss: true,
          dismissDelay: 5000,
        });
        hideModal();
      })
      .catch((err) => {
        AlertService.alert({
          type: 'warning',
          message: err.message,
        });
      });
  };

  return (
    <div>
      <Button type="button" primary onClick={addNew}>
        Add New Distribution List
      </Button>
      <div className={classes.table__grid}>
        <DataTableNaked
          loading={loading}
          data={distributionLists?.distributionListsBySiteQuery || []}
          columns={columns}
          onRowClick={handleRowClick}
        />
      </div>
      <DistributionListModal
        listName={selectedList?.name}
        listType={selectedList?.destinationType}
        bsrs={selectedList?.buyerSellerRelationships || []}
        listId={selectedList?._id}
        archived={selectedList?.archived}
        offerEmailTemplateId={selectedList?.offerEmailTemplate || ''}
        offerEmailCustomContent={selectedList?.offerEmailCustomContent || ''}
        open={showModal}
        onHide={hideModal}
        onRemoveBsrs={removeBsr}
        onSetListType={setDistributionListType}
        onSetListName={setListName}
        onAddBsr={addBsr}
        onSetArchived={setArchived}
        onSave={saveDistributionList}
        saving={loadingUpdate}
        sellerSiteId={parentSiteId}
        onSetEmailTemplateId={setOfferEmailTemplateId}
        onSetOfferEmailCustomContent={setOfferEmailCustomContent}
      />
    </div>
  );
};

DistributionLists.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  match: PropTypes.object,
};

export default injectSheet(styles)(DistributionLists);
