const IMPORT_TYPES = {
  ITEM: {
    label: 'Items',
    singular: 'Item',
    value: 'ITEM',
  },
  INVENTORY: {
    label: 'Inventory',
    singular: 'Inventory',
    value: 'INVENTORY',
  },
  OFFERS: {
    label: 'Offers',
    singular: 'Offer',
    value: 'OFFER',
  },
  ACTIVITY: {
    label: 'Activity',
    singular: 'Activity',
    value: 'ACTIVITY',
  },
};

export default IMPORT_TYPES;
