import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  check__container: {
    display: 'block',
    position: 'relative',
    width: 10,
    height: 20,
    transform: 'rotateZ(45deg)',
  },
  bottom__line: {
    height: 4,
    width: 0,
    backgroundColor: theme.primaryColor,
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderRadius: 2,
  },
  'bottom__line--checked': {
    animation: 'check-line-bottom 200ms ease-in 1400ms 1 forwards',
  },
  right__line: {
    height: 0,
    width: 4,
    backgroundColor: theme.primaryColor,
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: 2,
  },
  'right__line--checked': {
    animation: 'check-line-right 400ms ease-out 1500ms 1 forwards',
  },
  '@keyframes check-line-bottom': {
    '0%': {
      width: '0',
    },
    '100%': {
      width: '100%',
    },
  },
  '@keyframes check-line-right': {
    '0%': {
      height: '0',
    },
    '100%': {
      height: '100%',
    },
  },
};

@injectSheet(styles)
export default class Check extends React.Component {
  render() {
    const { classes, className, checked } = this.props;
    const containerClass = `${classes.check__container}${className ? ` ${className}` : ''}`;
    const bottomLineClass = `${classes.bottom__line}${checked ? ` ${classes['bottom__line--checked']}` : ''}`;
    const rightLineClass = `${classes.right__line}${checked ? ` ${classes['right__line--checked']}` : ''}`;
    return (
      <div className={containerClass}>
        <div className={bottomLineClass}></div>
        <div className={rightLineClass}></div>
      </div>
    );
  }
}

Check.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  checked: PropTypes.bool,
};
