import isTokenExpired from './token-validation';
import AppSettings from '../app-settings';

class PasswordService {
  constructor() {
    this.apiUrl = `${AppSettings.GRAPHQL_SERVER_URL}password`;
    this.contentType = 'application/json';
  }

  getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('jwt token required');
    if (isTokenExpired(token)) throw new Error('token expired');
    return token;
  };

  get headersWithToken() {
    return {
      authorization: `Bearer ${this.getToken()}`,
      'Content-Type': this.contentType,
    };
  }

  makeCall(url, method, body, headers) {
    return fetch(url, {
      body,
      method,
      headers,
    });
  }

  get headers() {
    return {
      'Content-Type': this.contentType,
    };
  }

  forgotPassword(email) {
    const body = JSON.stringify({ email });
    return this.makeCall(`${this.apiUrl}/forgot`, 'POST', body, this.headers);
  }

  resetPassword(token, password) {
    const body = JSON.stringify({ token, password });
    return this.makeCall(`${this.apiUrl}/reset`, 'POST', body, this.headers);
  }

  changePassword(originalPassword, password) {
    const body = JSON.stringify({ originalPassword, password });
    return this.makeCall(`${this.apiUrl}/change`, 'POST', body, this.headersWithToken);
  }
}

const passwordService = new PasswordService();
export default passwordService;
