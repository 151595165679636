import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = {
  notfound__page: {
    width: '100%',
  },
  text__center: {
    textAlign: 'center',
  },
};

@injectSheet(styles)
export default class NotFoundLayout extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.notfound__page}>
        <div>
          <h2 className={classes.text__center}>404 Alert! Page not found!</h2>
          <p className={classes.text__center}>Sorry for spoiling it for you.</p>
        </div>
      </div>
    );
  }
}

NotFoundLayout.propTypes = {
  classes: PropTypes.object,
};
