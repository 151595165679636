import React from 'react';
import { Column } from '@spoiler-alert/ui-library';
import NameColumn from './name-column';

const pendingUserColumns = [
  new Column({
    field: 'lastName',
    displayName: 'Name',
    sortable: true,
    defaultSort: true,
    formatter(_, row) {
      return <NameColumn row={row} />;
    },
  }),
  new Column({ field: 'email', displayName: 'Email', sortable: true }),
];

export default pendingUserColumns;
export { pendingUserColumns };
