import React, { useEffect, useMemo, useState } from 'react';
import {
  Column,
  DataTableNaked,
  Theme,
  Button,
  Select,
  SelectOption,
  RowAction,
  PencilIcon,
  ColorBadge,
  AlertService,
  Radio,
} from '@spoiler-alert/ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import { FeatureFlagsQuery } from '../../graphql/queries';
import { SaveFeatureFlagMutation } from '../../graphql/mutations';

const useStyles = createUseStyles({
  pageGrid: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  tableWrap: {
    border: `1px solid ${Theme.tableBorderColor}`,
    flex: '1 1 50%',
  },
  editorArea: {
    flex: '1 1 50%',
    padding: 16,
    border: `1px solid ${Theme.tableBorderColor}`,
    borderLeft: 'none',
  },
  editingHeading: {
    textTransform: 'capitalize',
  },
  field: {
    marginBottom: 16,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '&>button': {
      marginLeft: 10,
    },
  },
});

const FeatureFlagManager = () => {
  const classes = useStyles();

  const [editingFlag, setEditingFlag] = useState(null);

  const { data, loading } = useQuery(FeatureFlagsQuery);
  const [saveFeatureFlag, { loading: saving }] = useMutation(SaveFeatureFlagMutation);
  const flagsList = data?.getFeatureFlagsQuery.flags || [];
  const sitesList = data?.getFeatureFlagsQuery.sellerSites || [];
  const featureNames = data?.getFeatureFlagsQuery.featureNames || [];

  useEffect(() => {
    TitleService.setTitles('Feature Flag Manager');
    Breadcrumbs.set([
      {
        url: routePaths.featureFlagManager,
        title: 'Feature Flag Manager',
      },
    ]);
  }, []);

  const handleSaveFeatureFlag = () => {
    const { _id, featureName, features, sites, enabled } = editingFlag;
    const variables = {
      _id,
      featureName: features ? features[0].value : featureName,
      siteIds: sites.map((option) => option.value),
      enabled: enabled.value,
    };
    saveFeatureFlag({ variables, refetchQueries: _id ? undefined : ['getFeatureFlagsQuery'] })
      .then((response) => {
        if (response.data.saveFeatureFlag.errors?.length > 0) {
          throw new Error(response.data.saveFeatureFlag.errors[0].message);
        }
        setEditingFlag(null);
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
  };

  const siteIdToNameMap = useMemo(() => {
    return sitesList.reduce((map, site) => {
      map.set(site.id, site.name);
      return map;
    }, new Map());
  }, [sitesList]);

  const columns = useMemo(() => {
    return [
      new Column({ field: 'featureName', displayName: 'Name', sortable: true, defaultSort: true, defaultSortDirection: 'asc' }),
      new Column({
        field: 'enabled',
        displayName: 'Enabled',
        sortable: true,
        formatter: (enabled) =>
          enabled ? (
            <ColorBadge theme={Theme.badgeColors.green}>ENABLED</ColorBadge>
          ) : (
            <ColorBadge theme={Theme.badgeColors.red}>DISABLED</ColorBadge>
          ),
      }),
      new Column({
        field: 'siteIds',
        displayName: 'Sites',
        sortable: true,
        formatter: (values) => values.map((siteId) => siteIdToNameMap.get(siteId)).join(', '),
      }),
    ];
  }, [siteIdToNameMap]);

  const sitesOptions = useMemo(() => {
    return sitesList.map((option) => (
      <SelectOption key={option.id} value={option.id}>
        {option.name}
      </SelectOption>
    ));
  }, [sitesList]);

  const unusedFeatureNames = useMemo(() => {
    const features = new Set(featureNames);
    flagsList.forEach((flag) => features.delete(flag.featureName));
    return [...features.values()];
  }, [featureNames, flagsList]);

  const updateValue = (field, value) => {
    const _flag = { ...editingFlag, touched: true };
    _flag[field] = value;
    setEditingFlag(_flag);
  };

  const updateFeatureName = (options) => {
    const _flag = { ...editingFlag, touched: true };
    _flag.feature = options;
    _flag.featureName = options.length > 0 ? options[0].text : 'New';
    setEditingFlag(_flag);
  };

  const handleEdit = (flag) => {
    const { _id, featureName, enabled, siteIds } = flag;
    const _editingFlag = {
      _id,
      featureName,
      enabled: { value: enabled, text: enabled ? 'Enabled' : 'Disabled' },
      sites: siteIds.map((value) => ({ text: siteIdToNameMap.get(value), value })),
      touched: false,
    };
    setEditingFlag(_editingFlag);
  };

  const handleCreateNew = () => {
    setEditingFlag({
      featureName: 'New',
      feature: [],
      enabled: { value: false, text: 'Disabled' },
      sites: [],
      touched: false,
    });
  };

  const canSave = useMemo(() => {
    return editingFlag && editingFlag.touched && (editingFlag._id || editingFlag.feature.length > 0);
  }, [editingFlag]);

  return (
    <div className={classes.pageGrid}>
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={flagsList}
          columns={columns}
          loading={loading}
          rowActions={[<RowAction key={0} tooltipText="Edit" icon={PencilIcon} onClick={(row) => handleEdit.bind(this, row)} secondary />]}
        />
      </div>
      <div className={classes.editorArea}>
        {editingFlag === null ? (
          <h2>
            (Click edit or <a onClick={handleCreateNew}>create new</a>)
          </h2>
        ) : (
          <div>
            <h2 className={classes.editingHeading}>Feature: {editingFlag.featureName.toLowerCase()}</h2>
            {!editingFlag._id && (
              <div className={classes.field}>
                <Select selectedItems={editingFlag.feature} onChange={updateFeatureName} search label={'Feature Name'}>
                  {unusedFeatureNames.map((feature) => (
                    <SelectOption key={feature} value={feature}>
                      {feature}
                    </SelectOption>
                  ))}
                </Select>
              </div>
            )}
            <div className={classes.field}>
              <Radio
                selectedItem={editingFlag.enabled}
                onChange={updateValue.bind(this, 'enabled')}
                options={[
                  { text: 'Enabled', value: true },
                  { text: 'Disabled', value: false },
                ]}
              />
            </div>
            <div className={classes.field}>
              <Select
                selectedItems={editingFlag.sites}
                search
                multiple
                onChange={updateValue.bind(this, 'sites')}
                label={editingFlag.sites.length === 0 ? 'Enabled for all sites' : 'Sites Enabled'}
              >
                {sitesOptions}
              </Select>
            </div>
            <div className={classes.buttons}>
              <Button primary disabled={!canSave} onClick={handleSaveFeatureFlag}>
                Save Settings
              </Button>
              <Button secondary onClick={() => setEditingFlag(null)}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureFlagManager;
