import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { useQuery } from '@apollo/client';
import { Button, SelectOption, ButtonBar, Theme as theme } from '@spoiler-alert/ui-library';
import IMPORT_TYPES from './enums/etl-import-types';
import { CUSTOMERS_AND_IMPORT_PROFILES } from './etl-import-queries';
import StatusDot from './status-dot';
import NewButton from './new-button';
import MemorySelect from '../../components/forms/memory-select';

const styles = {
  wrap: {},
  controls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 25,
  },
  siteSelectWrap: {
    borderRadius: '4px',
    border: `1px ${theme.grey30} solid`,
    marginRight: 8,
    width: 260,
    paddingRight: 8,
    boxSizing: 'content-box',
  },
  siteListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  siteSelectOption: {
    paddingRight: '10px !important',
  },
};

const ETLNavigation = ({ classes, importType, setImportType, site, setSite, importProfile, setImportProfile, createNewInventoryImport }) => {
  const { data, error } = useQuery(CUSTOMERS_AND_IMPORT_PROFILES);
  const sites = data?.importManagerQuery?.allSellerOrgs;
  const [siteSelection, setSiteSelection] = useState(null);
  const currentSite = sites && sites.find((s) => s._id === site._id);

  const changeSite = (option) => {
    setSiteSelection(option);
    const selectedSite = sites.find((s) => s._id === option[0].value);
    setSite({ _id: selectedSite._id, siteName: selectedSite.siteName, importProfileName: selectedSite.importProfileName });
  };

  useEffect(() => {
    if (currentSite?.importProfiles?.length > 0) {
      const profile = currentSite.importProfiles.find((ip) => ip.importType === importType.value);
      if (profile) {
        setImportProfile(profile);
      } else {
        setImportProfile(null);
      }
    } else {
      setImportProfile(null);
    }
  }, [currentSite?._id]);

  const changeImportProfile = (it, inventoryProfile) => {
    setImportType(it);
    if (inventoryProfile) {
      setImportProfile(inventoryProfile);
    } else {
      const profile = currentSite?.importProfiles.find((ip) => ip.importType === it.value);
      if (profile) {
        setImportProfile(profile);
      } else {
        setImportProfile(null);
      }
    }
  };

  const isImportProfileValid = (it, importName) => {
    const profile = currentSite?.importProfiles.find((ip) => ip.importType === it.value && (!importName || importName === ip.importName));
    if (profile && profile.validated) return true;
    return false;
  };

  const isSiteValid = (siteId) => {
    const siteToValidate = sites.find((s) => s._id === siteId);
    const validProfiles = siteToValidate.importProfiles.filter((ip) => ip.validated === true);
    if (validProfiles.length === siteToValidate.importProfiles.length && validProfiles.length >= 3) return true;
    return false;
  };

  const inventoryImports = currentSite ? currentSite.importProfiles.filter((ip) => ip.importType === IMPORT_TYPES.INVENTORY.value) : [];
  return (
    <div className={classes.wrap}>
      <div className={classes.controls}>
        <div className={classes.siteSelectWrap}>
          <MemorySelect
            cacheId="ManagerSiteSelect"
            minimal
            search
            placeholderText="Select a Site"
            onChange={changeSite}
            selectedItems={siteSelection}
            style={styles.siteSelector}
          >
            {sites &&
              sites.map((s) => (
                <SelectOption key={s._id} value={s._id} className={classes.siteSelectOption} searchText={s.siteName}>
                  <div className={classes.siteListItem}>
                    {s.siteName} <StatusDot active={isSiteValid(s._id)} />
                  </div>
                </SelectOption>
              ))}
          </MemorySelect>
        </div>
        <div className={classes.buttonRow}>
          {currentSite && (
            <ButtonBar>
              <Button onClick={changeImportProfile.bind(this, IMPORT_TYPES.ITEM, null)} toggle active={importType === IMPORT_TYPES.ITEM} key="items">
                Items <StatusDot active={isImportProfileValid(IMPORT_TYPES.ITEM)} />
              </Button>
              {inventoryImports.length > 0 ? (
                inventoryImports.map((ii) => (
                  <Button
                    onClick={changeImportProfile.bind(this, IMPORT_TYPES.INVENTORY, ii)}
                    toggle
                    active={importProfile && importProfile._id === ii._id}
                    key={ii._id}
                  >
                    {ii.importName} <StatusDot active={isImportProfileValid(IMPORT_TYPES.INVENTORY, ii.importName)} />
                  </Button>
                ))
              ) : (
                <Button
                  onClick={changeImportProfile.bind(this, IMPORT_TYPES.INVENTORY, null)}
                  toggle
                  active={importType === IMPORT_TYPES.INVENTORY}
                  key="inventory"
                >
                  Inventory <StatusDot active={isImportProfileValid(IMPORT_TYPES.INVENTORY)} />
                </Button>
              )}
              <Button
                onClick={changeImportProfile.bind(this, IMPORT_TYPES.OFFERS, null)}
                toggle
                active={importType === IMPORT_TYPES.OFFERS}
                key="offers"
              >
                Offers <StatusDot active={isImportProfileValid(IMPORT_TYPES.OFFERS)} />
              </Button>
            </ButtonBar>
          )}
        </div>
        {inventoryImports.length > 0 && <NewButton onClick={createNewInventoryImport}>Inventory import</NewButton>}
      </div>
      {error && error.message}
    </div>
  );
};

ETLNavigation.propTypes = {
  classes: PropTypes.object,
  importType: PropTypes.object,
  setImportType: PropTypes.func,
  site: PropTypes.object,
  setSite: PropTypes.func,
  importProfile: PropTypes.object,
  setImportProfile: PropTypes.func,
  createNewInventoryImport: PropTypes.func,
};

export default injectSheet(styles)(ETLNavigation);
