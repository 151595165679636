import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Theme as theme } from '@spoiler-alert/ui-library';
import EnvelopeBottom from './bottom';
import EnvelopeUnfolded from './unfolded';
import EnvelopeFolded from './folded';

const styles = {
  envelope__container: {
    fill: theme.primaryColor,
    position: 'relative',
    perspective: 1000,
  },
  envelope__bottom: {
    zIndex: 1,
    position: 'relative',
  },
  envelope__unfolded: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    backfaceVisibility: 'hidden',
    transformOrigin: '0 40%',
  },
  envelope__folded: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    backfaceVisibility: 'hidden',
    transformOrigin: '0 38%',
    transform: 'rotateX(180deg)',
  },
  'envelope__folded--fold': {
    animation: 'fold 400ms ease-out 1400ms 1 forwards',
  },
  'envelope__unfolded--fold': {
    animation: 'unfold 400ms ease-out 1400ms 1 forwards',
  },
  '@keyframes fold': {
    '0%': {
      transform: 'rotateX(180deg)',
    },
    '100%': {
      transform: 'rotateX(0deg)',
    },
  },
  '@keyframes unfold': {
    '0%': {
      transform: 'rotateX(0deg)',
    },
    '100%': {
      transform: 'rotateX(-180deg)',
    },
  },
};

@injectSheet(styles)
export default class Envelope extends React.Component {
  render() {
    const { className, classes, folded } = this.props;
    const containerClass = `${className} ${classes.envelope__container}`;
    const foldedClass = `${classes.envelope__folded}${folded ? ` ${classes['envelope__folded--fold']}` : ''}`;
    const unfoldedClass = `${classes.envelope__unfolded}${folded ? ` ${classes['envelope__unfolded--fold']}` : ''}`;
    return (
      <div className={containerClass}>
        <EnvelopeUnfolded className={unfoldedClass} />
        <EnvelopeFolded className={foldedClass} />
        <EnvelopeBottom className={classes.envelope__bottom} />
      </div>
    );
  }
}

Envelope.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  folded: PropTypes.bool,
};
