import AppSettings from '../app-settings';

class RefreshService {
  async checkServerVersion() {
    const response = await fetch(AppSettings.REFRESH_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      },
    });
    return response.json();
  }
}

const refreshService = new RefreshService();
export default refreshService;
