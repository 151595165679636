import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = {
  sa__actions: {
    marginTop: 'auto',
    padding: '24px 17px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
};

const Actions = ({ children, classes }) => <div className={classes.sa__actions}>{children}</div>;

Actions.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
};

export default injectSheet(styles)(Actions);
