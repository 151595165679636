import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { Column, Button, AlertService, TextInput, DataSource, DataTableNaked } from '@spoiler-alert/ui-library';
import { GetDataExportQuery } from '../../graphql/queries';
import { TitleService } from '../../services';
import { RetryExportWithDataExportIds } from '../../graphql/mutations';
import { Breadcrumbs } from '../../store';

const styles = {
  exportRunContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  dataExportsContainer: {
    width: '80%',
  },
  dataExportImportField: {
    width: '20%',
  },
};

@injectSheet(styles)
@graphql(RetryExportWithDataExportIds, { name: 'retryExportWithDataExportIds' })
export default class DataExport extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitles('Exports');
    Breadcrumbs.set([
      {
        url: '/exports',
        title: 'Exports',
      },
    ]);
  }

  state = {
    exportRunId: '',
    fetchDataExports: false,
    dataExportData: [],
    totalFailures: [],
    hasBeenSearched: false,
  };

  static propTypes = {
    user: PropTypes.object,
    classes: PropTypes.object,
    history: PropTypes.object,
  };

  onSetExportRunId = (exportRunId) => {
    this.setState({ exportRunId: exportRunId.trim() });
  };

  triggerDataExports = () => {
    this.setState({ fetchDataExports: true, dataExportData: [] });
  };

  dataReceived = (data) => {
    this.setState({
      fetchDataExports: false,
      dataExportData: data.getDataExportsQuery,
      totalFailures: data.getDataExportsQuery.filter((x) => x.exportStatus === 'FAILED' && x.hasBeenTried !== true),
      hasBeenSearched: true,
    });
  };

  getDataExportColumns = () => [
    new Column({ field: '_id', displayName: 'Data Export ID', sortable: true }),
    new Column({ field: 'exportedAt', displayName: 'Exported At', sortable: true }),
    new Column({ field: 'updatedAt', displayName: 'Updated At', sortable: true }),
    new Column({ field: 'sellerSiteName', displayName: 'Seller Site', sortable: true }),
    new Column({ field: 'buyerSiteName', displayName: 'Buyer Site', sortable: true }),
    new Column({ field: 'bsrName', displayName: 'BSR Name', sortable: true }),
    new Column({ field: 'distributionChannelName', displayName: 'Distribution Channel Name', sortable: true }),
    new Column({ field: 'exportStatus', displayName: 'Export Status', defaultSort: true, sortable: true }),
    new Column({ field: 'exportType', displayName: 'Export Type', sortable: true }),
    new Column({ field: 'hasSnapshot', displayName: 'Has Snapshot', formatter: (_, row) => (row.hasSnapshot ? 'Yes' : 'No') }),
    new Column({
      field: 'action',
      displayName: 'Action',
      formatter: (_, row) =>
        row.exportStatus === 'FAILED' ? (
          <Button onClick={this.retryExportWithDataExportId.bind(this, row)} disabled={!row.hasSnapshot || row.hasBeenTried} secondary>
            {'Retry'}
          </Button>
        ) : (
          ''
        ),
    }),
  ];

  retryExportWithDataExportId = async (dataExport) => {
    await this.updateTouchedStatusById([dataExport._id]);
    await this.sendDataExportRetryRequest([dataExport._id]);
  };

  updateTouchedStatusById = async (dataExportIds) => {
    const { dataExportData } = this.state;
    for (let index = 0; index < dataExportIds.length; index++) {
      const dataExportId = dataExportIds[index];
      const foundExport = dataExportData.find((x) => x._id === dataExportId);
      foundExport.hasBeenTried = true;
    }
    this.setState({
      dataExportData,
      totalFailures: dataExportData.filter((x) => x.exportStatus === 'FAILED' && x.hasBeenTried !== true),
    });
  };

  retryExportWithDataExportIds = async () => {
    const { totalFailures } = this.state;
    const dataExportIds = totalFailures.map((x) => x._id);
    await this.updateTouchedStatusById(dataExportIds);
    await this.sendDataExportRetryRequest(dataExportIds);
  };

  sendDataExportRetryRequest = async (dataExportIds) => {
    const { exportRunId } = this.state;
    const result = await this.props.retryExportWithDataExportIds({
      variables: { dataExportIds, exportRunId },
      // refetchQueries: [  // this is temporally disabled until DataSource is refactored out
      //   {
      //     query: GetDataExportQuery,
      //     variables: { exportRunId },
      //   },
      // ],
    });
    if (result.data.retryExportWithDataExportIds.errors.length) {
      this.dataExportError(result.data.retryExportWithDataExportIds.errors);
    } else {
      this.dataExportSuccess();
    }
  };

  dataExportError = (errors) => AlertService.alert({ type: 'warning', message: errors });

  dataExportSuccess = () => AlertService.alert({ type: 'success', message: 'Data export retry queued.' });

  render() {
    const { classes } = this.props;
    const { exportRunId, fetchDataExports, dataExportData, totalFailures, hasBeenSearched } = this.state;
    return (
      <div>
        <h4>Exports</h4>
        <div>Search by exportRunId</div>
        <div className={classes.exportRunContainer}>
          <TextInput
            className={classes.dataExportImportField}
            onChange={this.onSetExportRunId}
            type="text"
            labelText="Export Run ID *"
            value={exportRunId}
            required
          />
          <Button onClick={this.triggerDataExports} disabled={exportRunId.length < 20} secondary loading={fetchDataExports} loadingText="Searching">
            Search
          </Button>
          {fetchDataExports && <DataSource query={GetDataExportQuery} variables={{ exportRunId }} onFetch={this.dataReceived} />}
          {totalFailures.length > 0 && dataExportData.length && (
            <Button onClick={this.retryExportWithDataExportIds} disabled={!dataExportData.length} primary>
              Retry All {totalFailures.length} Failed
            </Button>
          )}
        </div>
        {dataExportData.length > 0 && (
          <div className={classes.dataExportsContainer}>
            <DataTableNaked data={dataExportData} filterable="none" filterParameters={{}} filters={[]} columns={this.getDataExportColumns()} />
          </div>
        )}
        {dataExportData.length === 0 && hasBeenSearched && (
          <div className={classes.dataExportsContainer}>There are no exports with that exportRunId</div>
        )}
      </div>
    );
  }
}

DataExport.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  mutate: PropTypes.func,
  retryExportWithDataExportIds: PropTypes.func,
};
