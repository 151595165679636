import React from 'react';
import PropTypes from 'prop-types';

export default class EnvelopeUnfolded extends React.Component {
  render() {
    return (
      <svg className={this.props.className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
        <path
          id="Unfolded"
          d="M22,9.9c0-1.2-2-2.1-2-2.1l-7.5-4.7C12.4,3.1,12.2,3,12,3s-0.4,0.1-0.5,0.2L4,7.8 c0,0-2,0.9-2,2.1v1.5h2l0-1.2l8-5l8,5l0,1.2l2,0V9.9z M12,5.2"
        />
      </svg>
    );
  }
}

EnvelopeUnfolded.propTypes = {
  className: PropTypes.string,
};
