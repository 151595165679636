import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Select, Row, Col, BigCheckIcon, TrashIcon, Theme, TextBox, Button } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { listToSelected, listToOptions, cleanModel, SAVE_DELAY } from '../etl-helpers';
import ETLImportErrors from './etl-import-errors';

const styles = {
  checkIcon: {
    width: 24,
    height: 24,
    '& svg': {
      fill: Theme.green,
    },
  },
  operationsColumn: {
    color: Theme.green,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  textBoxWrap: {
    '&>div': {
      height: 32,
    },
  },
  trashColumn: {
    width: 75,
  },
  trashButton: {
    margin: [-7, 0, -10, 0],
  },
  fieldSelect: {},
  selectColumn: {
    '&>span': {
      display: 'flex',
      alignItems: 'center',
      '&>$fieldSelect': {
        flexGrow: 1,
        '&>div': {
          width: '100%',
          '&>div>span': {
            paddingLeft: '0 !important',
          },
        },
      },
    },
  },
  justSaved: {
    boxShadow: `inset 4px 0 0 ${Theme.green30}`,
  },
  rowError: {
    background: Theme.pink10,
  },
};

const ETLMapping = ({
  classes,
  mapping,
  possibleInternalFields,
  possibleValidators,
  externalNames,
  editOperation,
  updateMapping,
  deleteMapping,
  justSaved,
  setSaveInProgress,
}) => {
  const [editingMapping, setEditingMapping] = useState(() => cleanModel(mapping));

  const timerRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    setEditingMapping(cleanModel(mapping));
  }, [mapping]);

  const restartTimer = (recordToSave) => {
    clearTimeout(timerRef.current);
    setSaveInProgress(true);
    timerRef.current = setTimeout(() => {
      updateMapping(recordToSave);
    }, SAVE_DELAY);
  };

  useEffect(() => {
    if (editingMapping?.dataType && editingMapping.dataType !== 'string' && editingMapping.padStart !== null) {
      const newMapping = { ...editingMapping, padStart: null };
      setEditingMapping(newMapping);
      restartTimer(newMapping);
    }
  }, [editingMapping?.dataType]);

  const setFieldValue = (field, value) => {
    const newMapping = { ...editingMapping };
    newMapping[field] = value;
    setEditingMapping(newMapping);
    restartTimer(newMapping);
  };

  const setDropdownValue = (field, value, multiple = false, allowedValues = null) => {
    if (multiple) {
      const filteredValues = allowedValues ? value.filter((v) => allowedValues.includes(v.value)) : value;
      setFieldValue(
        field,
        filteredValues.map((v) => v.value)
      );
    } else {
      setFieldValue(field, value.length > 0 ? value[0].value : null);
    }
  };

  const internalFields = possibleInternalFields.map((f) => f.name);
  const setSaName = (editingMappingList) => {
    if (Array.isArray(editingMappingList.saName) && editingMappingList.saName.includes('meta')) {
      return [`${editingMappingList.saName[0]}.${editingMappingList.operation.staticParams[0]}`];
    }
    return editingMappingList.saName;
  };

  return (
    <Row className={`${justSaved ? classes.justSaved : ''} ${mapping.importErrors.length > 0 ? classes.rowError : ''}`}>
      <Col className={classes.selectColumn}>
        <ETLImportErrors errors={mapping.importErrors} />
        <Select
          minimal
          search
          containerClassName={classes.fieldSelect}
          selectedItems={listToSelected([editingMapping.externalName])}
          onChange={(values) => setDropdownValue('externalName', values)}
        >
          {listToOptions(externalNames)}
        </Select>
      </Col>
      <Col className={classes.selectColumn}>
        <Select
          minimal
          search
          multiple
          selectedItems={listToSelected(setSaName(editingMapping))}
          onChange={(values) => setDropdownValue('saName', values, true, internalFields)}
        >
          {listToOptions(internalFields, true)}
        </Select>
      </Col>
      <Col className={classes.selectColumn}>
        <Select minimal selectedItems={listToSelected([editingMapping.dataType])} onChange={(values) => setDropdownValue('dataType', values)}>
          {listToOptions(['number', 'date', 'weight', 'currency', 'string'])}
        </Select>
      </Col>
      <Col className={classes.selectColumn}>
        <Select
          minimal
          search
          multiple
          disabled={!!editingMapping.operation}
          selectedItems={listToSelected(editingMapping.validators)}
          onChange={(values) => setDropdownValue('validators', values, true, possibleValidators)}
        >
          {listToOptions(possibleValidators, true)}
        </Select>
      </Col>
      <Col>
        <div className={classes.textBoxWrap}>
          <TextBox
            style={{ width: 50, textAlign: 'right', paddingRight: 8 }}
            disabled={![null, 'string'].includes(editingMapping.dataType)}
            type="number"
            onChange={(value) => setFieldValue('padStart', parseInt(value, 10))}
            value={editingMapping.padStart || ''}
          />
        </div>
      </Col>
      <Col>
        <div className={classes.operationsColumn} onClick={() => editOperation(editingMapping._id)}>
          {editingMapping.operation ? (
            <div className={classes.checkIcon}>
              <BigCheckIcon />
            </div>
          ) : (
            ''
          )}
          <span>{editingMapping.order && editingMapping.order > 0 ? editingMapping.order : ''}</span>
        </div>
      </Col>
      <Col className={classes.trashColumn}>
        <Button
          warning
          resting={true}
          icon={TrashIcon}
          className={classes.trashButton}
          onClick={(e) => {
            e.preventDefault();
            deleteMapping(editingMapping);
          }}
        />
      </Col>
    </Row>
  );
};

ETLMapping.propTypes = {
  classes: PropTypes.object,
  mapping: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    externalName: PropTypes.string,
    saName: PropTypes.array,
    validators: PropTypes.array,
    padStart: PropTypes.number,
    operation: PropTypes.object,
    dataType: PropTypes.string,
    importErrors: PropTypes.arrayOf(PropTypes.string),
  }),
  possibleInternalFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  externalNames: PropTypes.arrayOf(PropTypes.string),
  possibleValidators: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  editOperation: PropTypes.func,
  updateMapping: PropTypes.func,
  deleteMapping: PropTypes.func,
  justSaved: PropTypes.bool,
  setSaveInProgress: PropTypes.func,
};

export default injectSheet(styles)(ETLMapping);
