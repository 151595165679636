import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Card, Button, TextInput } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { EditAdminUser } from '../../../graphql/mutations';

const styles = {
  button__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  input__row: {
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
  },
  input__field: {
    width: '100%',
    lineHeight: '1.6rem',
    '&:first-child': {
      marginRight: 24,
    },
  },
  edit__form: {
    width: '100%',
  },
};

@injectSheet(styles)
@graphql(EditAdminUser)
export default class EditUserAction extends Component {
  state = {
    flyoutUser: this.props.flyoutUser,
    saving: false,
    firstName: this.props.flyoutUser.firstName,
    lastName: this.props.flyoutUser.lastName,
    email: this.props.flyoutUser.email,
  };

  static getDerivedStateFromProps(nextProps) {
    return { flyoutUser: nextProps.flyoutUser };
  }

  error = (response) => {
    this.setState({ saving: false });
    const message = 'Sorry, editing this user failed. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    this.props.onHide(response, message);
  };

  hide = (response) => {
    this.setState({ saving: false });
    if (response.data) {
      const updatedFlyoutUser = response.data.editAdminUser;
      this.setState({
        flyoutUser: updatedFlyoutUser,
        firstName: updatedFlyoutUser.firstName,
        lastName: updatedFlyoutUser.lastName,
        email: updatedFlyoutUser.email,
      });
    }
    this.props.onHide(response);
  };

  setFirstName = (firstName) => {
    this.setState({ firstName });
  };

  setLastName = (lastName) => {
    this.setState({ lastName });
  };

  setEmail = (email) => {
    this.setState({ email });
  };

  save = (ev) => {
    ev.preventDefault();
    const { refetchQueries, mutate } = this.props;
    const { firstName, lastName, title, email, privClass, org, flyoutUser } = this.state;
    this.setState({ saving: true });
    mutate({
      variables: { id: flyoutUser._id, firstName, lastName, title, email, privClass, siteId: org },
      refetchQueries,
    })
      .then(this.hide)
      .catch(this.error);
  };

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <form onSubmit={this.save} className={classes.edit__form}>
          <div className={classes.edit__container}>
            <div className={classes.input__row}>
              <TextInput
                className={classes.input__field}
                onChange={this.setFirstName}
                type="text"
                labelText="First Name *"
                value={this.state.firstName}
                required
              />
            </div>
            <div className={classes.input__row}>
              <TextInput
                className={classes.input__field}
                onChange={this.setLastName}
                type="text"
                labelText="Last Name *"
                value={this.state.lastName}
                required
              />
            </div>
            <div className={classes.input__row}>
              <TextInput className={classes.input__field} onChange={this.setEmail} type="email" labelText="Email *" value={this.state.email} />
            </div>
            <div className={this.props.classes.button__container}>
              <Button type="button" link onClick={this.props.onHide} disabled={this.state.saving}>
                Cancel
              </Button>
              <Button type="submit" primary disabled={this.state.saving} loading={this.state.saving} loadingText="Wait...">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Card>
    );
  }
}

EditUserAction.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  flyoutUser: PropTypes.object,
  onHide: PropTypes.func,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
  viewingSelf: PropTypes.bool,
};

EditUserAction.defaultProps = {
  onHide: () => {},
};
