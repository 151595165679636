import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { createUseStyles } from 'react-jss';
import { AlertService, Select, SelectOption, NoImageIcon, DragDrop, Button, Loading, CloseIcon, MiniTooltip, Theme } from '@spoiler-alert/ui-library';
import appSettings from '../../app-settings';
import { fetchWithToken } from '../../services/fetch-with-token';
import { Refresh } from '../../icons';

const useStyles = createUseStyles(
  {
    label: {
      fontWeight: 'normal',
      margin: 0,
      color: 'rgba(0, 0, 0, 0.3)',
      fontSize: 16,
      lineHeight: '16px',
    },
    uploadInstruction: {
      color: 'rgba(0, 0, 0, 0.3)',
    },
    inputArea: {
      display: 'flex',
    },
    selectBox: {
      width: '70%',
      flexShrink: 1,
      marginRight: 20,
    },
    uploadBox: {
      width: '30%',
      flexGrow: 0,
    },
    gallery: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    noImage: {
      height: 100,
      width: 100,
      borderRadius: 4,
      border: `solid 1px ${Theme.grey30}`,
      backgroundColor: Theme.grey5,
      fill: Theme.grey50,
    },
    dropArea: {
      border: `3px ${Theme.grey30} dashed`,
      padding: 15,
    },
    dropAreaActive: {
      extend: 'dropArea',
      borderColor: Theme.green,
      background: Theme.green10,
    },
    actionContainer: {
      aspectRatio: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    uploadButton: {},
    removeImage: {
      position: 'absolute',
      background: Theme.white,
      width: 25,
      height: 25,
      top: -4,
      right: -4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
      padding: 5,
      boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
      opacity: 0,
    },
    imageWrap: {
      width: 150,
      maxWidth: '25%',
      aspectRatio: 1,
      border: `2px ${Theme.green} dashed`,
      padding: 5,
      margin: [0, 10, 10, 0],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'inset 0 0 20px rgba(0,0,0,0.2)',
      position: 'relative',
      '&:hover $removeImage': {
        opacity: 1,
      },
      '&>img': {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    optionImage: {
      display: 'inline-block',
      maxHeight: 30,
      maxWidth: 40,
      border: `1px ${Theme.green} dashed`,
      marginRight: 10,
    },
    inputWrap: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      gap: 10,
    },
    fileSelect: {
      flexGrow: 1,
    },
  },
  { name: 'UploadImages' }
);

const validateImageURL = (url) => {
  return URL.canParse(url);
};

const marketplaceFilesEndpoint = `${appSettings.GRAPHQL_SERVER_URL.split('admin/')[0]}ftp/marketplace-files`;

const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

const serverReducer = (state, action) => {
  if (action.type === 'initRequest') return { loading: true, uploading: false, files: state.files };
  if (action.type === 'initUpload') return { loading: false, uploading: true, files: state.files };
  if (action.type === 'completeRequest') return { loading: false, uploading: false, files: action.files };
  throw new Error('unknown action type');
};

const UploadImages = ({ values, folder: rawFolder, label, max, onChange }) => {
  const classes = useStyles();

  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [{ files: serverFiles, loading, uploading: uploadingImages }, serverUpdate] = useReducer(serverReducer, {
    files: null,
    loading: false,
    uploading: false,
  });

  const folder = useMemo(() => {
    return rawFolder.map((path) => slugify(path, { remove: /\.|\/|\\/, replacement: ' ' })).join('/');
  }, [rawFolder]);

  const filesURI = `${marketplaceFilesEndpoint}?folder=${encodeURIComponent(folder)}`;

  const fetchServerFiles = async () => {
    serverUpdate({ type: 'initRequest' });
    const resp = await fetchWithToken(filesURI, {});
    const data = await resp.json();
    serverUpdate({ type: 'completeRequest', files: data.files });
  };

  useEffect(() => {
    if (folder && serverFiles === null) {
      fetchServerFiles();
    }
  }, [folder]);

  const imagePreviewMap = useMemo(() => {
    if (serverFiles?.length > 0) {
      return serverFiles.reduce((previews, file) => {
        previews.set(file.url, file.preview?.download_uri);
        return previews;
      }, new Map());
    }
    return new Map();
  }, [serverFiles]);

  const { validatedValues, selectedItems } = useMemo(() => {
    if (!values) return { validatedValues: [], selectedItems: [] };
    const _validatedValues = values.filter((i) => validateImageURL(i));
    return {
      validatedValues: _validatedValues,
      selectedItems: _validatedValues.map((filePath) => {
        const parts = filePath.split('/');
        return { text: decodeURI(parts[parts.length - 1]), value: filePath };
      }),
    };
  }, [values]);

  const options = useMemo(() => {
    const _serverFiles = serverFiles || [];
    const selectableOptions = [
      ...selectedItems,
      ..._serverFiles.filter((file) => !validatedValues.includes(file.url)).map((file) => ({ text: file.displayName, value: file.url })),
    ];
    return selectableOptions.map((file) => {
      const preview = imagePreviewMap.get(file.value);
      return (
        <SelectOption checkbox key={file.value} value={file.value}>
          <img className={classes.optionImage} src={preview || file.value} />
          {file.text}
        </SelectOption>
      );
    });
  }, [serverFiles, selectedItems, validatedValues]);

  const handleDropError = (error) => AlertService.alert({ type: 'warning', message: error });

  const handleRefresh = () => fetchServerFiles();

  const uploadFiles = async (files) => {
    try {
      const body = new FormData();
      files.forEach((f) => body.append('images[]', f));
      const response = await fetchWithToken(filesURI, {
        body,
        method: 'POST',
      });
      if (response.status === 200) {
        return { success: true, data: await response.json() };
      }
      throw new Error(await response.text());
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const handleFileChange = (ev) => {
    setDragActive(false);
    const fileList = ev.target.files || ev.dataTransfer.files;
    const files = Array.from(fileList);
    if (files.length === 0) return false;
    if (!files.every((file) => allowedFileTypes.includes(file.type))) {
      handleDropError('Invalid file type');
      return false;
    }
    serverUpdate({ type: 'initUpload' });
    uploadFiles(files).then((r) => {
      if (r.success) {
        serverUpdate({ type: 'completeRequest', files: [...r.data.files] });
        const filesToAdd = r.data.files.filter((downFile) => files.some((upFile) => upFile.name === downFile.displayName)).map((f) => f.url);
        let newList = [...filesToAdd, ...validatedValues];
        if (newList.length > max) newList = newList.slice(0, max);
        onChange(newList);
      } else {
        handleDropError(r.error);
      }
    });
    return true;
  };

  const handleRemove = (url) => {
    const _validatedValues = [...validatedValues].filter((vv) => vv !== url);
    onChange(_validatedValues);
  };

  const uploadClick = () => {
    fileInputRef.current.click();
  };

  const handleOnChange = (chosen) => onChange(chosen.map((c) => c.value));

  return (
    <div className={classes.wrap}>
      <h4 className={classes.label}>{label}</h4>
      <div className={classes.inputArea}>
        <div className={classes.selectBox}>
          <div className={classes.inputWrap}>
            <Select
              label="Add images from folder"
              containerClassName={classes.fileSelect}
              disabled={options.length === 0}
              multiple={max > 1}
              selectAll={max > 1 && options.length <= max}
              onChange={handleOnChange}
              selectedItems={selectedItems}
            >
              {options}
            </Select>
            <MiniTooltip text={loading ? 'Loading files from folder...' : 'Refresh Folder'}>
              <Button link icon={Refresh} loading={loading} onClick={handleRefresh} />
            </MiniTooltip>
          </div>
          <div className={classes.gallery}>
            {validatedValues.length === 0 && (
              <div className={classes.imageWrap}>
                <NoImageIcon className={classes.noImage} />
              </div>
            )}
            {validatedValues.map((i, index) => (
              <div className={classes.imageWrap} key={index}>
                <img src={i} />
                <a className={classes.removeImage} onClick={() => handleRemove(i)}>
                  <CloseIcon />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.uploadBox}>
          <DragDrop
            className={dragActive ? classes.dropAreaActive : classes.dropArea}
            accepts={allowedFileTypes}
            multiple
            onDrop={handleFileChange}
            onError={handleDropError}
            onDragOver={() => setDragActive(true)}
            onDragLeave={() => setDragActive(false)}
          >
            <div className={classes.actionContainer}>
              {!uploadingImages ? (
                <>
                  <Button onClick={uploadClick} secondary className={classes.uploadButton}>
                    Browse for File
                  </Button>
                  <span className={classes.uploadInstruction}>or drag & drop your images here.</span>
                </>
              ) : (
                <Loading size="small" loading={true} />
              )}
            </div>
          </DragDrop>
          <input
            type="file"
            id="image-upload"
            multiple
            key="upload"
            onChange={handleFileChange}
            accept={allowedFileTypes.join(',')}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
        </div>
      </div>
    </div>
  );
};

UploadImages.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  folder: PropTypes.arrayOf(PropTypes.string).isRequired,
  max: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

UploadImages.defaultProps = {
  max: 20,
};

export default UploadImages;
