import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { TypeAhead } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { SiteNameTypeAheadQuery } from '../../graphql/queries';
import EditDetails from './edit-details';
import EditRelationship from './buyer-relationship';
import { Breadcrumbs } from '../../store';
import { listOfStates, pricingStrategyAnchor } from '../../utilities';

const styles = {
  adminContainerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '1em',
  },
  editSiteWrapper: {
    width: '75%',
  },
};

const EditSite = ({ classes }) => {
  useEffect(() => {
    TitleService.setTitles('Site Editation');
    Breadcrumbs.set([
      {
        url: '/edit-site',
        title: 'Site Editation',
      },
    ]);
  }, []);

  const [siteName, setSiteName] = useState();
  const [fiscalYearStart, setFiscalYearStart] = useState(0);
  const [taxId, setTaxId] = useState();
  const [siteId, setSiteId] = useState();
  const [defaultPricingPercentage, setDefaultPricingPercentage] = useState(0);
  const [defaultPricingStrategy, setDefaultPricingStrategy] = useState();
  const [editedSiteName, setEditedSiteName] = useState();
  const [tags, setTags] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [stateName, setStateName] = useState();
  const [zip, setZip] = useState();
  const [countryCode, setCountryCode] = useState();
  const [itemImportEmailRecipients, setItemImportEmailRecipients] = useState();
  const [inventoryImportEmailRecipients, setInventoryImportEmailRecipients] = useState();
  const [siteRole, setSiteRole] = useState();
  const [charities, setCharities] = useState();
  const [awardEmailTemplate, setAwardEmailTemplate] = useState();

  const updateTypeAheadItem = (value) => {
    const stateNameValue = { value: '', text: '' };
    listOfStates.forEach((region) => {
      if (region.id === value?.result.physicalAddress.stateCode) {
        stateNameValue.value = region.id;
        stateNameValue.text = region.text;
      }
    });
    setSiteName(value?.result.siteName);
    setFiscalYearStart(value?.result.fiscalYearStart);
    setTaxId(value?.result.taxId);
    setSiteId(value?.result._id);
    setDefaultPricingPercentage(value?.result.defaultPricingPercentage ? value.result.defaultPricingPercentage : 0);
    setDefaultPricingStrategy({ value: value?.result.defaultPricingStrategy, text: pricingStrategyAnchor.get(value?.result.defaultPricingStrategy) });
    setEditedSiteName(value?.result.editedSiteName);
    setTags(
      value?.result.tags?.map((tag) => {
        return { value: tag, text: tag };
      }) || []
    );
    setStreet(value?.result.physicalAddress.streetAddress || '');
    setCity(value?.result.physicalAddress.city || '');
    setZip(value?.result.physicalAddress.zipCode || '');
    setCountryCode(value?.result.physicalAddress.countryCode || '');
    setStateName(stateNameValue);
    setItemImportEmailRecipients(value?.result.itemImportEmailRecipients || []);
    setInventoryImportEmailRecipients(value?.result.inventoryImportEmailRecipients || []);
    setSiteRole(value?.result.siteRole[0] || '');
    setCharities(value?.result.charities || []);
    setAwardEmailTemplate(value?.result.awardSheetEmailTemplate || '');
  };

  return (
    <div id="edit-sites">
      <div className={classes.adminContainerWrapper}>
        <div className={classes.editSiteWrapper}>
          <TypeAhead
            query={SiteNameTypeAheadQuery}
            queryName="siteNameTypeAheadQuery"
            dataFormat={[{ displayText: 'editSite' }]}
            labelText={'Edit Site'}
            onChange={updateTypeAheadItem}
            itemLimit={200}
            autoWidth
          />
          {siteName && (
            <EditDetails
              siteId={siteId}
              siteName={siteName}
              taxId={taxId}
              fiscalYearStart={fiscalYearStart}
              selectedAnchor={defaultPricingStrategy}
              defaultPricingPercentage={defaultPricingPercentage}
              editedSiteName={editedSiteName}
              tags={tags}
              stateName={stateName}
              street={street}
              city={city}
              zip={zip}
              countryCode={countryCode}
              itemImportEmailRecipients={itemImportEmailRecipients}
              inventoryImportEmailRecipients={inventoryImportEmailRecipients}
              siteRole={siteRole}
              charities={charities}
              awardEmailTemplate={awardEmailTemplate}
              setFiscalYearStart={setFiscalYearStart}
              setDefaultPricingPercentage={setDefaultPricingPercentage}
              setDefaultPricingStrategy={setDefaultPricingStrategy}
              setEditedSiteName={setEditedSiteName}
              setSiteName={setSiteName}
              setTags={setTags}
              setStreet={setStreet}
              setCity={setCity}
              setStateName={setStateName}
              setZip={setZip}
              setCountryCode={setCountryCode}
              setItemImportEmailRecipients={setItemImportEmailRecipients}
              setInventoryImportEmailRecipients={setInventoryImportEmailRecipients}
              setCharities={setCharities}
              setAwardEmailTemplate={setAwardEmailTemplate}
            />
          )}
        </div>
      </div>
      <div>
        <EditRelationship />
      </div>
    </div>
  );
};

EditSite.propTypes = {
  classes: PropTypes.object,
};

export default injectSheet(styles)(EditSite);
