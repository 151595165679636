import isTokenExpired from './token-validation';

const getToken = () => {
  const token = localStorage.getItem('token');
  if (!token) throw new Error('jwt token required');
  if (isTokenExpired(token)) throw new Error('token expired');
  return token;
};

const fetchWithToken = (url, options) => {
  const authHeaders = {
    authorization: `Bearer ${getToken()}`,
  };
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      ...authHeaders,
    },
  });
};

const fetchUnauthed = (url, options) =>
  fetch(url, {
    ...options,
    headers: {
      ...options.headers,
    },
  });

export { fetchWithToken, fetchUnauthed };
