import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from '@spoiler-alert/ui-library';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import queryString from 'query-string';
import { LoginService, TitleService } from '../../services';
import client from '../../apollo/client';
import routePaths from '../../route-paths';

const styles = {
  login__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '1170px',
    margin: '0 auto',
  },
  login__title: {
    fontSize: '1.3rem',
    marginBottom: '40px',
    textAlign: 'center',
    marginTop: 0,
    fontWeight: 500,
  },
  form__row: {
    width: '360px',
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  form__container: {
    marginBottom: '2.8rem',
  },
  form__button: {
    width: '100%',
    textTransform: 'uppercase',
  },
  login__text: {
    color: '#969696',
  },
  login__link: {
    color: '#5d9bd1',
    cursor: 'pointer',
  },
  footer_container: {
    textAlign: 'center',
  },
  error__container: {
    width: '360px',
    border: '1px solid #ebccd1',
    backgroundColor: '#f2dede',
    color: '#a94442',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    height: '36px',
    marginBottom: '15px',
  },
  login__input: {
    backgroundColor: 'transparent',
  },
};
@injectSheet(styles)
export default class SignIn extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitleTag('Sign In');
    this.state = {
      email: '',
      password: '',
      loading: false,
      errorMsg: '',
      error: false,
    };

    if (this.isAuthenticated) props.history.push(routePaths.home);
  }

  get isAuthenticated() {
    return !!localStorage.getItem('token');
  }

  get redirect() {
    const values = queryString.parse(location.search);
    const redirectParam = values.redirect;
    return redirectParam ? decodeURIComponent(redirectParam) : routePaths.home;
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.match.params.errorMessage) {
      const errorMsg = nextProps.match.params.errorMessage;
      return {
        error: true,
        errorMsg,
      };
    }
    return null;
  }

  setEmail = (email) => {
    this.setState({
      email,
      error: false,
    });
  };

  setPassword = (password) => {
    this.setState({
      password,
      error: false,
    });
  };

  refetch = async () => {
    await client.resetStore();
    this.props.history.push(this.redirect);
  };

  loginError = (err) => {
    this.setState({
      errorMsg: err || 'Sorry, your work email or password is incorrect.',
      error: true,
      loading: false,
    });
  };

  submitLogin = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    this.setState({ loading: true });
    LoginService.login(email.trim().toLowerCase(), password)
      .then(this.refetch)
      .catch((err) => this.loginError(err));
  };

  render() {
    const { classes } = this.props;
    const { error, errorMsg, loading, email, password } = this.state;
    return (
      <div className={classes.login__container}>
        <h4 className={classes.login__title}>Administration Panel Sign In</h4>
        {error && (
          <div className={classes.error__container} data-element="error-message">
            {errorMsg}
          </div>
        )}
        <form onSubmit={this.submitLogin} className={classes.form__container}>
          <div className={classes.form__row}>
            <TextInput
              inputClassName={classes.login__input}
              onChange={this.setEmail}
              id="email"
              required
              type="email"
              labelText="Email"
              value={email}
              autoComplete="username"
            />
          </div>

          <div className={classes.form__row}>
            <TextInput
              inputClassName={classes.login__input}
              onChange={this.setPassword}
              id="password"
              required
              type="password"
              labelText="Password"
              value={password}
              autoComplete="current-password"
            />
          </div>

          <div className={classes.form__row}>
            <Button className={classes.form__button} id="sign-in" type="submit" loading={loading} loadingText="Loading...">
              Sign In
            </Button>
          </div>
        </form>
        <div className={classes.footer_container}>
          <p className={classes.login__text}>
            <Link className={classes.login__link} id="forgot-password-link" to="/forgot-password">
              Forgot Password?
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};
